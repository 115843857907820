import { ProductDoc } from '@gooduncles/gu-app-schema';
import { useEffect, useMemo, useState } from 'react';
import useSWR, { mutate } from 'swr';

import { FirebaseManager } from '../lib/2/firebase-manager';

const firebaseManager = FirebaseManager.getInstance();
const productPath = 'product';

const getProrducts = async (joinedIds: string) => {
  const ids = joinedIds.replace('products', '').split('.');
  const promises = ids.map((id) => {
    return firebaseManager.getDoc<ProductDoc>(`${productPath}/${id}`);
  });
  const data = await Promise.all(promises);
  return data?.filter((product) => product !== undefined);
};

const useProductsByIds = (ids?: string[]) => {
  const [initialDataFetched, setInitialDataFetched] = useState(false);

  const joinedIds = ids?.join('.');
  const memoizedIds = useMemo(() => joinedIds, [joinedIds]);
  const {
    data,
    isValidating: productsLoading,
    error,
  } = useSWR(ids && ids.length > 0 ? 'products' + memoizedIds : null, getProrducts, {
    revalidateOnMount: false,
    errorRetryCount: 2,
  });
  const products = useMemo(
    () => (data ? (data as ProductDoc[]).filter((product) => product !== undefined) : []),
    [data]
  );

  useEffect(() => {
    if (!error && !data && memoizedIds && memoizedIds && !initialDataFetched) {
      mutate('products' + memoizedIds);
      setInitialDataFetched(true);
    }
  }, [data, error, initialDataFetched, memoizedIds, productsLoading]);

  return {
    products,
    productsLoading,
    productsError: error,
  };
};

export default useProductsByIds;
