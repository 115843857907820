// import { Badge } from 'antd-mobile';
import { Button } from 'antd';
import { FC, ReactNode } from 'react';

import { selectIsLoading } from 'src/redux/slices/auth';
import { useAppSelector } from 'src/redux/store';

import classes from './FooterButton.module.scss';

interface FooterButtonProps {
  text: string;
  onClick: () => void;
  disabled?: boolean;
  width?: string;
  bottom?: string;
  child?: ReactNode;
}

const FooterButton: FC<FooterButtonProps> = ({ text, onClick, disabled, width, bottom, child }) => {
  const isAuthLoading = useAppSelector(selectIsLoading);
  return (
    <Button
      className={classes.footerButton}
      onClick={onClick}
      disabled={disabled}
      style={{ width, bottom }}
      loading={isAuthLoading}>
      {child}
      {text}
    </Button>
  );
};

FooterButton.defaultProps = {
  text: '확인',
};

export default FooterButton;
