import { FC, PropsWithChildren } from 'react';

import classes from './Title.module.scss';

const Title: FC<PropsWithChildren<React.HTMLAttributes<HTMLHeadingElement>>> = ({ children, ...props }) => {
  return (
    <h1 className={classes.title} {...props}>
      {children}
    </h1>
  );
};

export default Title;
