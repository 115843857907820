import { format } from 'date-fns';
import { ko } from 'date-fns/locale';
import { FC, useMemo, useState } from 'react';

import { NotificationAction } from 'src/lib/3/schema-notification';

import useAuth from 'src/redux/hooks/useAuth';
import { selectUser } from 'src/redux/slices/auth';
import { useAppSelector } from 'src/redux/store';

import useNotificationForUserId from 'src/hooks/useNotificationForUserId';

import PostDrawer from '../Common/PostDrawer/PostDrawer';
import TopNavigationBar from '../Common/TopNavigationBar/TopNavigationBar';
import classes from './Notification.module.scss';

const Notification: FC = () => {
  const user = useAppSelector(selectUser, (prev, next) => prev?._id === next?._id);
  const { onSetAuthLoading } = useAuth();
  const userId = useMemo(() => user?._id, [user]);
  const { notificationList, mutateNotificationList } = useNotificationForUserId(userId);
  const [selectedPost, setSelectedPost] = useState<string | null>(null);

  const onNotificationClick = async (id: string, action: NotificationAction) => {
    onSetAuthLoading(true);
    try {
      if (action.type === 'notion') {
        setSelectedPost(id);
      }
    } catch (error) {
      console.error(error);
    }
    onSetAuthLoading(false);
  };

  const closePost = () => {
    // close되는 경우 읽음 처리를 확인하기 위해 mutate를 진행한다.
    setSelectedPost(null);
    mutateNotificationList();
  };

  return (
    <div>
      <PostDrawer postId={selectedPost} onClose={closePost} />
      <TopNavigationBar title='알림' leftButton />
      <section className={classes.notiList}>
        {notificationList?.list.map((noti) => {
          const notiDate = format(new Date(noti.publishDate), 'L월 d일', {
            locale: ko,
          });
          return (
            <div
              key={noti._id}
              className={`${classes.notiItem} ${noti.unread ? classes.unread : ''}`}
              onClick={() => onNotificationClick(noti._id, noti.action)}>
              <div className={classes.notiIcon}>
                <img src={noti.publisher.avatarImage} alt='notification' />
              </div>
              <div className={classes.notiContent}>
                <p>{noti.title}</p>
                <div className={classes.notiInfo}>{noti.body}</div>
                <div className={classes.notiDate}>{notiDate}</div>
              </div>
            </div>
          );
        })}
      </section>
    </div>
  );
};

export default Notification;
