import { OrderDoc } from '@gooduncles/gu-app-schema';
import { FC } from 'react';
import { Navigate, useParams } from 'react-router-dom';
import useSWR from 'swr';

import { FirebaseManager } from 'src/lib/2/firebase-manager';
import { LogDoc, LogOrder } from 'src/lib/3/schema-log';

import { useTitle } from '../hooks/useTitle';

import Loading from 'src/components/Layout/Loading';
import OrderDetail from 'src/components/OrderDetail/OrderDetail';

const firebaseManager = FirebaseManager.getInstance();
const orderPath = 'order';
const logOrderPath = 'logOrder';

const getOrder = async (path: string) => {
  const orderDoc = await firebaseManager.getDoc<OrderDoc>(path);
  return orderDoc;
};

const getLogsForOrder = async (args: [string, string]) => {
  const [collectionPath, orderId] = args;
  return firebaseManager.getDocsArrayWithWhere<LogDoc<LogOrder>>(collectionPath, [['body.orderId', '==', orderId]], {
    sortKey: '_timeCreate',
    orderBy: 'desc',
  });
};

const OrderDetailPage: FC = () => {
  useTitle('GU | 주문 상세');
  const { orderId } = useParams();
  const { data, isValidating } = useSWR(orderId ? `${orderPath}/${orderId}` : null, getOrder, {
    revalidateOnFocus: false,
    errorRetryCount: 2,
  });
  const { data: logs } = useSWR(orderId ? [logOrderPath, orderId] : null, getLogsForOrder, {
    revalidateOnFocus: false,
    errorRetryCount: 2,
  });

  if (!orderId) {
    return <Navigate to='/' replace />;
  }

  if (isValidating) {
    return <Loading />;
  }

  return <OrderDetail order={data} logs={logs} />;
};

export default OrderDetailPage;
