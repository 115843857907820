import { FC, useEffect, useState } from 'react';

import { selectUnhiddenProducts } from 'src/redux/slices/auth';
import { useAppSelector } from 'src/redux/store';

import useOnScreen from 'src/hooks/useOnScreen';
import useProductsByIds from 'src/hooks/useProductsByIds';

import Title from 'src/components/atoms/Title/Title';
import HorizontalProductList from 'src/components/organisms/ProductList/HorizontalProductList/HorizontalProductList';

interface ProductListForMainTagProps {
  mainTag: string;
  productIds: string[];
}

const ProductListForMainTag: FC<ProductListForMainTagProps> = ({ mainTag, productIds }) => {
  const [ref, setRef] = useState<HTMLDivElement | null>(null);
  const isVisible = useOnScreen(ref);
  const [isLoaded, setIsLoaded] = useState(false);
  const { products, productsLoading } = useProductsByIds(isLoaded ? productIds : undefined);
  // 숨김 처리된 상품은 노출하지 않는다.
  const unhiddenProducts = useAppSelector(selectUnhiddenProducts);
  const showProducts = products.filter((p) => !p.hidden || unhiddenProducts.includes(p.productId));

  useEffect(() => {
    if (isVisible && !isLoaded) {
      setIsLoaded(true);
    }
  }, [isLoaded, isVisible]);

  return (
    <div ref={setRef}>
      <div className='titlePadding'>
        <Title>{mainTag}</Title>
      </div>
      <HorizontalProductList
        products={showProducts}
        loading={!isLoaded || productsLoading}
      />
    </div>
  );
};

export default ProductListForMainTag;
