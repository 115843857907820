/* eslint-disable @typescript-eslint/no-unused-vars */
import {
  CommerceConf,
  OrderProduct,
  OrderStatusCode,
  ProductDoc,
  ProductStateCode,
  StoreDoc,
} from '@gooduncles/gu-app-schema';
import { message } from 'antd';
import { groupBy } from 'lodash';

import { formatDate } from '../1/date-util';
import { errorObjectToString } from '../1/util';
import { getOrderedOrderCountForStore, getProductDoc } from '../3/firebase-short-cut';
import { CommerceLogger } from './logger';

const logger = CommerceLogger.getInstance();

/**
 * 장바구니에 상품을 최초 담는 과정에서 수량을 조정하는 기능을 제공하지 않고있기 때문에
 * 기본 수량을 1로 둔다.
 */
export const productToOrderProduct = (product: ProductDoc, volume: number): OrderProduct => {
  const {
    name,
    imageUrl,
    thumbnail,
    imageDescription,
    badge,
    memo,
    dailyNotification,
    costUpdatedAt,
    salesAmount,
    _timeCreate,
    _timeDelete,
    _timeUpdate,
    ...productBase
  } = product;

  return {
    ...productBase,
    volume,
    price: product.price,
    snapshotId: null,
    snapshotPrice: null,
    priceDiff: null,
  };
};

const splitOrderableProducts = (products: (ProductDoc | undefined)[]) => {
  const groupByState = groupBy(products, 'state');
  const stockProducts = (groupByState[ProductStateCode.STOCK] ?? []).filter((product) => product) as ProductDoc[];
  const outOfStockProducts = groupByState[ProductStateCode.OUTOFSTOCK] ?? [];
  const discontinuedProducts = groupByState[ProductStateCode.DISCONTINUED] ?? [];
  return {
    orderable: stockProducts,
    unorderable: [...outOfStockProducts, ...discontinuedProducts],
  };
};

/**
 * 불러오는 이전 주문 상품들이 유효한지 확인합니다.
 */
export const loadPrevOrderProducts = async (products: OrderProduct[]) => {
  const currentProducts = await Promise.all(products.map((product) => getProductDoc(product.productId)));
  const filteredList = splitOrderableProducts(currentProducts);

  if (filteredList.orderable.length === 0) {
    message.warning('더 이상 판매하지 않는 상품들입니다.');
    return false;
  }

  if (filteredList.orderable.length !== products.length) {
    message.warning(`구매할 수 없는 상품(${filteredList.unorderable.length}개)을 제외하고 장바구니에 담았습니다.`);
  }
  return filteredList.orderable
    .map((product) => {
      const orderProduct = products.find((orderProduct) => orderProduct.productId === product.productId);
      if (!orderProduct) {
        return undefined;
      }
      const volume = orderProduct.volume < 1 ? 1 : Math.floor(orderProduct.volume);
      const newOrderProduct = productToOrderProduct(product, volume);
      return newOrderProduct;
    })
    .filter((product) => product) as OrderProduct[];
};

/**
 * 배송비를 계산합니다.
 */
export const getDeliveryFee = (store: StoreDoc | null, conf: CommerceConf, totalAmount: number) => {
  if (store?.chargeDeliveryFee && totalAmount < conf.minAmountForFreeDelivery) {
    return conf.deliveryFee;
  }
  return 0;
};

/**
 * 최초 주문 여부를 판별한다.
 * 주문을 방해하지 않기 위해서 비동기로 처리한다.
 */
export const checkIsFirstOrder = async (store: StoreDoc & { timeCreate: string }) => {
  try {
    if (!store?._id) {
      return;
    }
    const count = await getOrderedOrderCountForStore(store._id);
    if (count === 0) {
      logger.logCommerce(
        `🎉 첫 주문 🎉\n매장명: ${store.storeNickname}\n코드: ${store.storeCode}\n가입날짜: ${store.timeCreate}`,
        {
          channel: '4-앱-알림',
        }
      );
    }
  } catch (error) {
    console.error(error);
    const msg = errorObjectToString(error);
    logger.logCommerce(`첫 주문 확인 과정에서 오류가 발생했습니다.\nerror: ${msg}`, {
      level: 'error',
    });
  }
};

export const calcTotalAmount = (products: OrderProduct[]) =>
  products.reduce((acc, product) => {
    return acc + product.volume * product.price;
  }, 0);

/**
 * 배송완료된 주문인데 배송완료 시간이 없는 경우 있다면 로그를 남긴다.
 */
export const getDeliveredAtKrFormat = (deliveredAt: string | null, orderStatus: OrderStatusCode, orderId: string) => {
  if (orderStatus === OrderStatusCode.DELIVERED && deliveredAt === null) {
    logger.logCommerce(`배송완료 상태인데 배송완료 시간이 없습니다.\norderId: ${orderId}`, {
      level: 'error',
    });
  }
  return deliveredAt ? formatDate(deliveredAt, 'L월 d일 (EEEEEE)') : null;
};

/** 단가 */
export const getValueOfSupply = (price: number) => Math.round(price / 1.1);
/** 부가세 */
export const getValueAddedTax = (price: number) => price - Math.round(price / 1.1);

/**
 * 필요한 세금 항목을 계산한다.
 * 세금은 총 금액에 대한 10%로 계산한다.
 *
 * @param price
 * @param volume
 * @param taxFree
 * @returns {
 *  supplyPriceForUnit: 상품의 개별 공급가,
 *  volume: 수량,
 *  totalPrice: 세금과 수량을 계산한 총 금액,
 *  tax: 총 부가세
 * }
 */
export const calcTaxForPrice = (price: number, volume: number, taxFree: boolean) => {
  // 면세항목인 경우 세금계산을 별도로 하지 않는다.
  if (taxFree) {
    return {
      supplyPrice: price,
      volume,
      totalPrice: price * volume,
      tax: 0,
    };
  }
  const supplyPrice = getValueOfSupply(price);
  const totalPrice = price * volume;
  const tax = getValueAddedTax(totalPrice);

  return {
    supplyPrice,
    volume,
    totalPrice,
    tax,
  };
};
