import { InvoiceDetailDoc } from '@gooduncles/gu-app-schema';
import { message } from 'antd';
import { FC, useCallback, useEffect, useState } from 'react';
import { Navigate, useParams } from 'react-router-dom';

import { decodeBase64, decryptAES } from 'src/lib/1/util';
import { getInvoiceDetail } from 'src/lib/3/firebase-short-cut';

import { useTitle } from 'src/hooks/useTitle';

import Loading from 'src/components/Layout/Loading';
import Invoice from 'src/components/templates/Invoice/Invoice';

const InvoicePage: FC = () => {
  useTitle('GU | 청구 및 정산');
  const [loading, setLoading] = useState(true);
  const { invoiceId } = useParams();
  const decodedInvoiceId = invoiceId ? decryptAES(decodeBase64(decodeURIComponent(invoiceId))) : undefined;
  const [invoiceDetail, setInvoiceDetail] = useState<InvoiceDetailDoc | null>(null);

  const initInvoice = useCallback(async (id: string) => {
    setLoading(true);
    try {
      const doc = await getInvoiceDetail(id);
      if (!doc) {
        throw new Error('정산서를 불러오는데 실패했습니다.');
      }
      if (doc.deletedAt !== null) {
        throw new Error('삭제된 정산서입니다.');
      }
      setInvoiceDetail(doc);
    } catch (error: any) {
      console.error(error);
      message.error(error.message);
    }
    setLoading(false);
  }, []);

  useEffect(() => {
    if (decodedInvoiceId) {
      initInvoice(decodedInvoiceId);
    }
  }, [decodedInvoiceId, initInvoice]);

  return loading ? (
    <Loading />
  ) : invoiceDetail ? (
    <Invoice data={invoiceDetail} />
  ) : (
    <Navigate to='/not-found' replace />
  );
};

export default InvoicePage;
