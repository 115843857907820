import { FC } from 'react';

import { useTitle } from '../hooks/useTitle';
import Signin from '../components/Signin/Signin';

const SigninPage: FC = () => {
  useTitle('GU | 로그인');
  return <Signin />;
};

export default SigninPage;
