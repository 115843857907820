import { ProductDoc } from '@gooduncles/gu-app-schema';
import { ButtonHTMLAttributes, FC } from 'react';

import ProductSquareItem from '../../ProductItem/ProductSquareItem';
import SkeletonSquareItem from '../../ProductItem/SkeletonSquareItem/SkeletonSquareItem';
import classes from './GridProductList.module.scss';

interface GridProductListProps extends ButtonHTMLAttributes<HTMLDivElement> {
  products: ProductDoc[];
  loading: boolean;
  showBadge?: boolean;
}

const GridProductList: FC<GridProductListProps> = ({ products, loading, showBadge, ...props }) => {
  return (
    <div {...props} className={classes.gridProductList}>
      {loading
        ? [...Array(3)].map((_, index) => <SkeletonSquareItem key={index} />)
        : products.map((product) => <ProductSquareItem key={product._id} product={product} showBadge={showBadge} />)}
    </div>
  );
};

export default GridProductList;
