import { throttle } from 'lodash-es';
import { FC, useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import SearchButton from 'src/components/atoms/Button/SearchButton';

import ProductCategoryListModal from '../ProductCategoryListModal/ProductCategoryListModal';
import StoreCategoryListModal from '../StoreCategoryListModal/StoreCategoryListModal';
import classes from './HomeToolbar.module.scss';

interface HomeToolbarProps {
  storeCategory: string;
}

const HomeToolbar: FC<HomeToolbarProps> = ({ storeCategory }) => {
  const navigate = useNavigate();
  const [showSmallToolbar, setShowSmallToolbar] = useState(false);
  const toolbarRef = useRef<HTMLDivElement>(null);

  const goToSearchPage = () => {
    navigate('/search', {
      state: {
        autoFocus: true,
      },
    });
  };

  useEffect(() => {
    const handleScroll = throttle(() => {
      const navigationElement = toolbarRef.current;
      if (navigationElement) {
        const { top } = navigationElement.getBoundingClientRect();
        const showSmallOne = top <= -80;
        setShowSmallToolbar(showSmallOne);
      }
    }, 100); // 200ms의 쓰로틀 간격

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <>
      <div ref={toolbarRef} className={classes.relativeBox}>
        <SearchButton iconOnly={false} onClick={goToSearchPage} />
        <div className={classes.top}>
          <StoreCategoryListModal storeCategory={storeCategory} size='large' />
          <ProductCategoryListModal />
        </div>
      </div>
      <div className={`${classes.fixedBox} ${showSmallToolbar ? classes.show : ''}`}>
        <div className={classes.full}>
          <StoreCategoryListModal storeCategory={storeCategory} size='small' />
        </div>
        <SearchButton iconOnly onClick={goToSearchPage} />
        <ProductCategoryListModal />
      </div>
    </>
  );
};

export default HomeToolbar;
