import { FC } from 'react';
import { Navigate, useParams } from 'react-router-dom';

import { ProductCategory, productCategories } from 'src/lib/1/string-map';

import ProductsForCategory from 'src/components/ProductsForCategory/ProductsForCategory';

const ProductsForCategoryPage: FC = () => {
  const { category } = useParams();
  const title = category === 'custom' ? '맞춤상품' : productCategories[category as ProductCategory];

  return category && title ? <ProductsForCategory category={category} title={title} /> : <Navigate to='/' replace />;
};

export default ProductsForCategoryPage;
