import { configureStore } from '@reduxjs/toolkit';
import { useDispatch, useSelector } from 'react-redux';
import type { TypedUseSelectorHook } from 'react-redux';
// import logger from 'redux-logger';

/** {@link https://redux-toolkit.js.org/usage/usage-guide#use-with-redux-persist} */
import { FLUSH, PAUSE, PERSIST, PURGE, REGISTER, REHYDRATE, persistReducer, persistStore } from 'redux-persist';
import storage from 'redux-persist/es/storage';

import { isProduction } from 'src/lib/1/constant';

import rootReducer from './slices';

/**
 * localStorage에 저장할 state를 정의한다.
 */
const persistConfig = {
  key: 'root',
  storage,
  blacklist: ['auth', 'order'],
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

const middleware = isProduction ? [] : [];

export const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) => [
    ...getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER, 'order/setOrders'],
        ignoredActionPaths: ['payload._timeUpdate', 'payload._timeCreate'],
        /** https://redux-toolkit.js.org/usage/usage-guide#use-with-react-redux-firebase */
        ignoredPaths: [
          'auth.user._timeUpdate',
          'auth.user._timeCreate',
          'auth.store._timeCreate',
          'auth.store._timeUpdate',
          'order',
        ],
      },
    }),
    ...middleware,
  ],
  devTools: process.env.NODE_ENV !== 'production',
});
export const persistor = persistStore(store);

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

export const useAppDispatch: () => AppDispatch = useDispatch;
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;
