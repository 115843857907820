import { OrderDoc } from '@gooduncles/gu-app-schema';
import { Popconfirm } from 'antd';
import { format } from 'date-fns';
import { ko } from 'date-fns/locale';
import { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { GaEventType } from 'src/schema/schema-ga-event';

import { getOrderDeliveryDate } from 'src/lib/1/util';
import { sendGaEvent } from 'src/lib/3/firebase-short-cut';
import { onCancelOrder } from 'src/lib/5/order-api';

import useCart from 'src/redux/hooks/useCart';
import useOrder from 'src/redux/hooks/useOrder';
import { selectStore } from 'src/redux/slices/auth';
import { useAppSelector } from 'src/redux/store';

import { useApp } from 'src/stores/app-context';

import OrderInfo from '../Common/OrderInfo/OrderInfo';
import TopNavigationBar from '../Common/TopNavigationBar/TopNavigationBar';
import OrderList from '../OrderList/OrderList';
import classes from './Order.module.scss';

/**
 * 주문목록 페이지는 오로지 주문완료(SUBMITTED) 상태의 주문만 표시한다.
 */
const Order: FC<{ order: OrderDoc }> = ({ order }) => {
  const { commerceConf } = useApp();
  const store = useAppSelector(selectStore);
  const [isOpen, setIsOpen] = useState(false);
  const { onSetProducts } = useCart();
  const { onSetOnEditOrder, resetCartWithOrder } = useOrder();

  const deliveryDate = useMemo(
    () => getOrderDeliveryDate(commerceConf.deliveryDateRules),
    [commerceConf.deliveryDateRules]
  );

  /**
   * 주문을 취소하고 상품을 장바구니에 담는다.
   */
  const cancelOrder = useCallback(() => {
    if (order._id) {
      resetCartWithOrder(order.products);
      onCancelOrder(order, store?.storeNickname ?? store?.storeName ?? '알 수 없음');
    }
  }, [order, resetCartWithOrder, store]);

  /**
   * 주문수정
   */
  const editOrder = useCallback(() => {
    onSetOnEditOrder(true);
    onSetProducts(order.products || []);
    sendGaEvent({
      name: GaEventType.ORDER_EDIT,
      eventParams: {
        orderId: order._id,
      },
    });
  }, [onSetOnEditOrder, onSetProducts, order._id, order.products]);

  useEffect(() => {
    setIsOpen((prev) => (prev !== commerceConf.open ? commerceConf.open : prev));
  }, [commerceConf.open]);

  return (
    <>
      <TopNavigationBar
        leftButton={
          isOpen ? (
            <Popconfirm
              placement='topLeft'
              title='정말로 취소하시겠습니까?'
              onConfirm={cancelOrder}
              okText='Yes'
              cancelText='No'>
              <button className={classes.cancelOrder}>주문취소</button>
            </Popconfirm>
          ) : undefined
        }
        title={isOpen ? undefined : '주문 접수 완료'}
        rightButton={
          isOpen ? (
            <button className={classes.editOrder} onClick={editOrder}>
              주문수정
            </button>
          ) : undefined
        }
      />
      <main>
        <section className={classes.deliveryInfo}>
          <h2>
            {format(new Date(deliveryDate), 'L월 d일(EEEEEE)', { locale: ko })}
            <span>까지</span>
          </h2>
          <span>안전하게 배송해드릴게요. 🚚</span>
        </section>
        <div className={classes.gutter} />
        <OrderInfo
          order={order}
          paidAmount={order.paidAmount}
          minAmountForFreeDelivery={commerceConf.minAmountForFreeDelivery}
        />
        <OrderList products={order.products} />
      </main>
    </>
  );
};

export default Order;
