import { ButtonHTMLAttributes, FC, PropsWithChildren } from 'react';

import classes from './Button.module.scss';

interface TextButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  label: string;
  color: 'orange' | 'gray';
}
const TextButton: FC<PropsWithChildren<TextButtonProps>> = ({ label, color, children, ...props }) => {
  return (
    <button {...props} className={`${classes.textButton} ${classes[color]}`}>
      <span>{label}</span>
      {children}
    </button>
  );
};

export default TextButton;
