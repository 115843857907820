import { debounce } from 'lodash';
import { FC, useCallback, useEffect, useMemo, useRef, useState } from 'react';

import { hangulJamoAndSpecialChar } from 'src/lib/1/constant';
import { removeCharsFromString } from 'src/lib/1/util';

import IconErase from 'src/assets/icons/erase.svg';
import IconSearchBlack from 'src/assets/icons/search-black.svg';

import classes from './SearchInput.module.scss';

interface SearchInputProps {
  searchFor: string;
  onSubmit: (query: string) => void;
  setKeywordForAutocomplete: (keyword: string) => void;
  setOnFocus: (onFocus: boolean) => void;
  placeholder: string;
  defaultValue: string;
  autoFocus: boolean;
}

const SearchInput: FC<SearchInputProps> = ({
  searchFor,
  onSubmit,
  setKeywordForAutocomplete,
  setOnFocus,
  placeholder,
  defaultValue = '',
  autoFocus,
}) => {
  const inputRef = useRef<HTMLInputElement>(null);
  const [inputValue, setInputValue] = useState<string>(defaultValue);

  const handleChangeWithDebounce = useMemo(
    () =>
      debounce((e) => {
        const value = e.target.value;
        // 길이가 1이고, 한글 자모음 또는 미사용 특수문자에 해당하는 경우 입력중으로 간주하여 검색을 실행하지 않는다.
        if (value.length === 1 && hangulJamoAndSpecialChar.includes(value)) {
          return;
        }
        const validKeyword = removeCharsFromString(value, hangulJamoAndSpecialChar);
        setKeywordForAutocomplete(validKeyword);
      }, 250),
    [setKeywordForAutocomplete]
  );

  const handleChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      setInputValue(e.target.value);
      handleChangeWithDebounce(e);
    },
    [handleChangeWithDebounce]
  );

  const clearInput = useCallback(() => {
    setInputValue('');
    setKeywordForAutocomplete('');
  }, [setKeywordForAutocomplete]);

  const handleSubmit = (e?: React.FormEvent<HTMLFormElement>) => {
    e?.preventDefault();
    onSubmit(inputValue);
    inputRef.current?.blur();
  };

  // 최초 렌더링 시 input을 활성화시킨다.
  useEffect(() => {
    if (autoFocus) {
      inputRef.current?.focus();
    }
  }, [autoFocus, inputRef]);

  return (
    <form role='search' onSubmit={handleSubmit} className={classes.searchForm}>
      <label htmlFor={searchFor} className={classes.searchLabel}>
        <div className={classes.searchIconBox} onClick={() => handleSubmit()}>
          <img src={IconSearchBlack} alt='search-icon' />
        </div>
        <input
          id={searchFor}
          ref={inputRef}
          name='query'
          type='text'
          placeholder={placeholder}
          enterKeyHint='search'
          value={inputValue}
          onChange={handleChange}
          onFocus={() => setOnFocus(true)}
          onBlur={() => setOnFocus(false)}
          required
        />
        {inputValue.length > 0 && (
          <button onClick={clearInput} type='button' className={classes.clearInputButton}>
            <img src={IconErase} alt='clear-input' />
          </button>
        )}
      </label>
    </form>
  );
};

export default SearchInput;
