import { FC } from 'react';

import { selectUnhiddenProducts } from 'src/redux/slices/auth';
import { useAppSelector } from 'src/redux/store';

import useImmutableProductById from 'src/hooks/useImmutableProductById';

import ProductHorizontalItem from '../ProductHorizontalItem/ProductHorizontalItem';
import ProductSquareItem from '../ProductSquareItem';
import SkeletonHorizontalItem from '../SkeletonHorizontalItem/SkeletonHorizontalItem';
import SkeletonSquareItem from '../SkeletonSquareItem/SkeletonSquareItem';

interface ProductItemWithFetchProps {
  productId: string;
  type?: 'square' | 'list';
  disabled?: boolean;
  /**
   * GA에서 프로모션 이벤트를 별도로 수집하기 위해 상품 데이터에 추가한다.
   * - redux에서 주문 상품 데이터에 포함되지 않도록 분리하고있다.(사용자 로컬에서만 존재하도록 신경쓰자)
   */
  promotion?: string;
}

const ProductItemWithFetch: FC<ProductItemWithFetchProps> = ({ productId, type = 'square', disabled, promotion }) => {
  const unhiddenProducts = useAppSelector(selectUnhiddenProducts);
  const { product, productLoading } = useImmutableProductById(productId);
  const showProduct = !product?.hidden || unhiddenProducts.includes(productId);
  if (productLoading) return type === 'square' ? <SkeletonSquareItem /> : <SkeletonHorizontalItem />;
  return product && showProduct ? (
    type === 'square' ? (
      <ProductSquareItem product={{ ...product, promotion }} showBadge />
    ) : (
      <ProductHorizontalItem product={{ ...product, promotion }} disabled={disabled} />
    )
  ) : null;
};

export default ProductItemWithFetch;
