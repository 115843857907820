import { ArrowRightOutlined, SearchOutlined } from '@ant-design/icons';
import { StoreBase } from '@gooduncles/gu-app-schema';
import { Button } from 'antd';
import { FC, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';

import { DEFAULT_STORE_NAME, storeBaseInit } from 'src/lib/1/constant';

import classes from './SignupIntro.module.scss';

interface SignupIntroProps {
  showDaumPostcodeModal: () => void;
  setStoreBase: React.Dispatch<React.SetStateAction<StoreBase | undefined>>;
}

const SignupIntro: FC<SignupIntroProps> = ({ showDaumPostcodeModal, setStoreBase }) => {
  const navigate = useNavigate();

  const skipStoreRegister = useCallback(() => {
    const storeName = DEFAULT_STORE_NAME;
    setStoreBase({
      ...storeBaseInit,
      storeName,
      storeNickname: storeName,
      addressDetail: '',
      categories: ['etc'],
      address: '',
      roadAddress: '',
      jibunAddress: '',
      sido: '',
      sigungu: '',
      bname: '',
      hname: '',
      roadname: '',
    });
    navigate('/signup/user');
  }, [navigate, setStoreBase]);

  return (
    <section>
      <h1 className={classes.searchAddressTitle}>
        배송받을 가게 주소가
        <br />
        있으신가요?
      </h1>
      <Button className={classes.searchAddressButton} onClick={showDaumPostcodeModal}>
        <SearchOutlined />
        주소검색
      </Button>
      {/* <button className={classes.searchStoreIdButton}>
				등록된 매장 id로 찾기 <ArrowRightOutlined />
			</button> */}
      <button className={classes.skipAddressButton} onClick={skipStoreRegister}>
        다음에 입력하기 <ArrowRightOutlined />
      </button>
    </section>
  );
};

export default SignupIntro;
