import { FC } from 'react';

import ProductItemWithFetch from '../../ProductItem/ProductItemWithFetch/ProductItemWithFetch';
import classes from './GridProductList.module.scss';

interface GridProductListWithFetchProps {
  productIds: string[];
  promotion?: string;
}

const GridProductListWithFetch: FC<GridProductListWithFetchProps> = ({ productIds, promotion }) => {
  return (
    <div className={classes.gridProductList}>
      {productIds.map((id) => (
        <ProductItemWithFetch key={id} productId={id} promotion={promotion} />
      ))}
    </div>
  );
};

export default GridProductListWithFetch;
