import { ProductDoc, ProductStateCode } from '@gooduncles/gu-app-schema';
import { FC, useCallback, useEffect, useState } from 'react';

import { formatNumber } from 'src/lib/1/util';

import { selectUnhiddenProducts, selectUserId } from 'src/redux/slices/auth';
import { selectProductVolume } from 'src/redux/slices/cart';
import { selectOnEditOrder, selectOrderProductVolume } from 'src/redux/slices/order';
import { useAppSelector } from 'src/redux/store';

import useProductById from 'src/hooks/useProductById';
import { useTitle } from 'src/hooks/useTitle';

import IconBox from 'src/assets/icons/box.svg';
import IconIced from 'src/assets/icons/iced.svg';
import IconThermometer from 'src/assets/icons/thermometer.svg';

import EmptyList from '../Common/EmptyList/EmptyList';
import FavoriteButton from '../Common/FavoriteButton/FavoriteButton';
import ProductImage from '../Common/ProductImg/ProductImg';
import TopNavigationBar from '../Common/TopNavigationBar/TopNavigationBar';
import Loading from '../Layout/Loading';
import ProductVolumeController from '../ProductItem/ProductVolumeController/ProductVolumeController';
import TagProductList from '../TagProductList/TagProductList';
import classes from './ProductDetail.module.scss';

const VolumeController: FC<{ product: ProductDoc }> = ({ product }) => {
  const onEditOrder = useAppSelector(selectOnEditOrder);
  const orderProductVolume = useAppSelector((state) => selectOrderProductVolume(state, product.productId));
  const cartProductVolume = useAppSelector((state) => selectProductVolume(state, product.productId));
  const volume = onEditOrder === false ? orderProductVolume : cartProductVolume;

  return <ProductVolumeController product={product} volume={volume} isCartItem={onEditOrder === false} />;
};

type ProductDetailProps = {
  productId: string;
};

const storagesToShow = ['refrigerator', 'freezer'];
const packingsToShow = ['box'];

const ProductDetail: FC<ProductDetailProps> = ({ productId }) => {
  const userId = useAppSelector(selectUserId);
  const { product, productLoading } = useProductById(productId);
  useTitle(`GU | ${product?.fullName}`);
  const unhiddenProducts = useAppSelector(selectUnhiddenProducts);
  const [selectedTag, setSelectedTag] = useState<string | null>(product?.mainTag ?? product?.tags?.[0] ?? null);
  const unitPrice = product?.unitPrice
    ? `${product.unitPrice.amount}${product.unitPrice.unit}당 ${formatNumber(product.unitPrice.price)}원`
    : null;
  const mainTag = product?.mainTag ?? null;

  const onSelectTag = useCallback(
    (tag: string) => {
      setSelectedTag(tag);
    },
    [setSelectedTag]
  );

  useEffect(() => {
    if (selectedTag === null) {
      setSelectedTag(product?.mainTag ?? product?.tags?.[0] ?? null);
    }
  }, [product, selectedTag]);

  return (
    <>
      {productLoading ? (
        <Loading />
      ) : product ? (
        <div>
          <TopNavigationBar leftButton rightButton={<FavoriteButton productId={productId} size='large' />} />
          <div className={classes.imgBox}>
            <ProductImage product={product} />
            {(storagesToShow.includes(product.storage) || packingsToShow.includes(product.packing)) && (
              <div className={classes.extraInfo}>
                {storagesToShow.includes(product.storage) &&
                  (product.storage === 'freezer' ? (
                    <img className={classes.icedIcon} src={IconIced} alt='iced' />
                  ) : (
                    <img className={classes.refrigeratorIcon} src={IconThermometer} alt='thermometer' />
                  ))}
                {packingsToShow.includes(product.packing) && (
                  <img className={classes.boxIcon} src={IconBox} alt='box' />
                )}
              </div>
            )}
            {product.state === ProductStateCode.OUTOFSTOCK && (
              <div className={classes.outOfStock}>
                <span>품절</span>
              </div>
            )}
          </div>

          <div className={classes.productInfo}>
            <h1 className={classes.productName}>{product.fullName}</h1>
            <div className={classes.productContent}>
              <div className={classes.productPrice}>
                <span className={classes.price}>{formatNumber(product.price)}원</span>
                {unitPrice && <span className={classes.unitPrice}>{unitPrice}</span>}
              </div>
              {userId && <VolumeController product={product} />}
            </div>
          </div>

          {(!!mainTag || product.tags) && (
            <div className={classes.tagBox}>
              <h3>연관 태그</h3>
              <div className={classes.tags}>
                {mainTag && (
                  <span
                    className={`${classes.tag} ${selectedTag === mainTag ? classes.selectedTag : ''}`}
                    onClick={() => onSelectTag(mainTag)}>
                    <b>#</b>
                    {mainTag}
                  </span>
                )}
                {product.tags &&
                  product.tags.map((tag, idx) => (
                    <span
                      className={`${classes.tag} ${selectedTag === tag ? classes.selectedTag : ''}`}
                      key={tag + idx}
                      onClick={() => onSelectTag(tag)}>
                      <b>#</b>
                      {tag}
                    </span>
                  ))}
              </div>
            </div>
          )}
          {selectedTag && (
            <TagProductList tag={selectedTag} unhiddenProducts={unhiddenProducts} curProductId={productId} />
          )}
        </div>
      ) : (
        <EmptyList message='상품을 찾을 수 없습니다.' />
      )}
    </>
  );
};

export default ProductDetail;
