import { Button, Modal, Popover } from 'antd';
import { FC, useCallback, useEffect, useState } from 'react';

import TextArea from 'antd/lib/input/TextArea';
import { MobileAlert } from 'src/lib/1/util';
import { CommerceLogger } from 'src/lib/4/logger';

const logger = CommerceLogger.getInstance();

interface ProductRequestModalProps {
  keyword: string;
  onRequest: () => void;
  disabled: boolean;
  storeName?: string;
}

const ProductRequestModal: FC<ProductRequestModalProps> = ({ keyword, onRequest, disabled, storeName }) => {
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [requestProductInfo, setRequestProductInfo] = useState<string>('');

  const onOpen = useCallback(() => {
    setOpen(true);
  }, []);

  const onCancel = useCallback(() => {
    setOpen(false);
  }, []);

  const onOk = useCallback(async () => {
    if (requestProductInfo.length === 0) {
      MobileAlert('상품명을 입력해주세요.');
      return;
    }
    setLoading(true);
    await logger.logCommerce(`\n※ 상품 입고 요청 - ${storeName}\n •  ${requestProductInfo}`, {
      channel: '4-앱-고객요청',
    });
    setLoading(false);
    setOpen(false);
    onRequest();
  }, [onRequest, requestProductInfo, storeName]);

  const onVolumeChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    const value = e.target.value;
    setRequestProductInfo(value);
  };

  useEffect(() => {
    setRequestProductInfo(keyword);
  }, [keyword]);

  return (
    <>
      {disabled ? (
        <Popover content='입고 요청은 회원만 가능합니다.'>
          <Button type='primary' onClick={onOpen} disabled={disabled}>
            입고 요청
          </Button>
        </Popover>
      ) : (
        <Button type='primary' onClick={onOpen} disabled={disabled} style={{ fontWeight: 600 }}>
          입고 요청
        </Button>
      )}
      <Modal
        open={open}
        onOk={onOk}
        onCancel={onCancel}
        okButtonProps={{ disabled: requestProductInfo.length === 0, loading }}
        okText='요청'
        cancelText='닫기'>
        <div>
          <h3>입고 요청</h3>
          <p style={{ margin: '10px 0 4px' }}>필요하신 상품을 알려주세요.</p>
          <TextArea id='volume' rows={4} minLength={1} value={requestProductInfo} onChange={onVolumeChange} />
        </div>
      </Modal>
    </>
  );
};

export default ProductRequestModal;
