import useSWR from 'swr';

import { FirebaseManager, WHERE } from 'src/lib/2/firebase-manager';
import { NotificationDoc } from 'src/lib/3/schema-notification';

const firebaseManager = FirebaseManager.getInstance();
const notificationPath = 'notification';

type NotificationList = {
  unreadCount: number;
  list: NotificationDoc[];
};

const getNotificationFor = async (args: [string, string]) => {
  const [path, userId] = args;
  const where: WHERE[] = [
    ['userId', '==', userId],
    ['isDeleted', '==', false],
  ];
  const response = await firebaseManager.getDocsArrayWithWhere<NotificationDoc>(path, where, {
    sortKey: 'publishDate',
    orderBy: 'desc',
  });
  const notificationList = {
    unreadCount: response.filter((noti) => noti.unread).length,
    list: response,
  };
  return notificationList;
};

/**
 * 추후 App Noti를 활성화하면 deviceId를 이용한 조회도 추가해야한다.
 */
const useNotificationForUserId = (userId?: string) => {
  const {
    data: notificationList,
    isValidating: notificationLoading,
    mutate,
  } = useSWR<NotificationList>(userId ? [notificationPath, userId] : null, getNotificationFor, {
    revalidateOnFocus: false,
    errorRetryCount: 2,
  });

  return {
    notificationList,
    notificationLoading,
    mutateNotificationList: mutate,
  };
};

export default useNotificationForUserId;
