import { FC } from 'react';

import { useTitle } from '../hooks/useTitle';

// import Favorite from '../components/Favorite/Favorite';
import FavoriteList from 'src/components/organisms/FavoriteList/FavoriteList';

const FavoritePage: FC = () => {
  useTitle('GU | 즐겨찾기');
  return <FavoriteList />;
};

export default FavoritePage;
