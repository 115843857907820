import { FC } from 'react';

import BackButton from 'src/components/atoms/Button/BackButton';
import SearchInput from 'src/components/atoms/SearchInput/SearchInput';

import classes from './ToolBar.module.scss';

interface SearchToolBarProps {
  defaultValue?: string;
  onSubmit: (query: string) => void;
  setKeywordForAutocomplete: (keyword: string) => void;
  setOnFocus: (onFocus: boolean) => void;
  autoFocus: boolean;
}

const SearchToolBar: FC<SearchToolBarProps> = ({ defaultValue = '', ...props }) => {
  return (
    <div className={classes.searchToolBar}>
      <BackButton />
      <SearchInput defaultValue={defaultValue} placeholder='상품 검색' searchFor='product' {...props} />
    </div>
  );
};

export default SearchToolBar;
