import { Badge } from 'antd';
import { ButtonHTMLAttributes, FC, useCallback, useEffect, useState } from 'react';

import ExpandCollapseIcon from 'src/components/atoms/ExpandCollapseIcon/ExpandCollapseIcon';

import classes from './Accordion.module.scss';

interface AccordionProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  defaultExpanded: boolean;
  title: string;
  size?: 'large' | 'medium' | 'small';
  pannelLevel?: 'one' | 'two';
  badge?: number;
}

const Accordion: FC<AccordionProps> = ({
  defaultExpanded,
  title,
  size = 'large',
  pannelLevel = 'one',
  badge = 0,
  children,
}) => {
  const [expanded, setExpanded] = useState(defaultExpanded);
  const [pannelRef, setPannelRef] = useState<HTMLDivElement | null>(null);

  const handleOnClick = useCallback(() => {
    setExpanded((prev) => !prev);
  }, []);

  useEffect(() => {
    if (pannelRef) {
      if (expanded) {
        setTimeout(() => {
          pannelRef.style.maxHeight = `${pannelRef.scrollHeight}px`;
          setTimeout(() => {
            pannelRef.style.maxHeight = 'fit-content';
          }, 250);
        }, 0);
      } else {
        setTimeout(() => {
          pannelRef.style.maxHeight = `${pannelRef.scrollHeight}px`;
          setTimeout(() => {
            pannelRef.style.maxHeight = '0px';
          }, 10);
        }, 0);
      }
    }
  }, [expanded, pannelRef]);

  useEffect(() => {
    setExpanded(defaultExpanded);
  }, [defaultExpanded]);

  return (
    <>
      <button className={`${classes.accordion} ${classes[size]}`} onClick={handleOnClick}>
        {badge > 0 ? (
          <Badge count={badge} size='small'>
            <p className={classes.accordionTitle}>{title}</p>
          </Badge>
        ) : (
          <p className={classes.accordionTitle}>{title}</p>
        )}
        <ExpandCollapseIcon expanded={expanded} />
      </button>
      <div
        ref={setPannelRef}
        className={`${classes.pannel} ${classes[pannelLevel]} ${expanded ? classes.expanded : ''}`}>
        {pannelLevel === 'two' ? <div className={classes.levelLine}>{children}</div> : children}
      </div>
    </>
  );
};

export default Accordion;
