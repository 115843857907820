import { useEffect, useMemo, useState } from 'react';

/**
 * Element가 화면에 보이는지 여부를 반환한다.
 * @see https://stackoverflow.com/questions/45514676/react-check-if-element-is-visible-in-dom
 */
const useOnScreen = (ref: HTMLElement | null) => {
  const [isIntersecting, setIntersecting] = useState(false);
  const observer = useMemo(() => new IntersectionObserver(([entry]) => setIntersecting(entry.isIntersecting)), []);

  useEffect(() => {
    if (ref) {
      observer.observe(ref);
      return () => {
        observer.unobserve(ref);
      };
    }
  }, [observer, ref]);

  return isIntersecting;
};

export default useOnScreen;
