import { useEffect, useState } from 'react';

/**
 * 가상 키보드(Virtual keyboard) 유무에 따른 높이 변경을 감지하는 Hook
 */
const useVisualViewport = () => {
  const [height, setHeight] = useState(window.visualViewport?.height ?? 0);

  const handleResize = () => {
    setHeight(window.visualViewport?.height ?? 0);
  };

  useEffect(() => {
    visualViewport?.addEventListener('resize', handleResize);

    return () => visualViewport?.removeEventListener('resize', handleResize);
  }, []);

  return { height };
};

export default useVisualViewport;
