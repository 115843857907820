import { StoreCategory } from '@gooduncles/gu-app-schema';
import { FC } from 'react';

import { selectProductGroupForCategory, selectStoreCategories } from 'src/redux/slices/home';
import { useAppSelector } from 'src/redux/store';

import { useTitle } from 'src/hooks/useTitle';

import Loading from '../Layout/Loading';
import Footer from '../atoms/Footer/Footer';
import ToolBar from '../molecules/ToolBar/ToolBar';
import MainTagListForStoreCategory from '../templates/MainTagListForStoreCategory/MainTagListForStoreCategory';

interface StoreCategoryComponentProps {
  storeCategory: StoreCategory;
}

const StoreCategoryComponent: FC<StoreCategoryComponentProps> = ({ storeCategory }) => {
  const productGroupForCategory = useAppSelector((state) => selectProductGroupForCategory(state, storeCategory));
  const storeCategories = useAppSelector(selectStoreCategories);
  const currentCategory = storeCategories.find((category) => category.key === storeCategory) ?? {
    key: 'all',
    title: '전체',
    emoji: '🍽',
    index: 5,
  };
  useTitle(`GU | ${currentCategory.title}`);

  return (
    <>
      <ToolBar title={currentCategory.title} backButton='left' />
      {productGroupForCategory?.data?.mainTags ? (
        <MainTagListForStoreCategory storeCategory={storeCategory} mainTags={productGroupForCategory.data.mainTags} />
      ) : (
        <Loading />
      )}
      <Footer />
    </>
  );
};

export default StoreCategoryComponent;
