import { Skeleton } from 'antd';
import { FC } from 'react';

import classes from './SkeletonHorizontalItem.module.scss';

const SkeletonHorizontalItem: FC = () => {
  return (
    <div className={classes.skeletonHorizontalItem}>
      <Skeleton.Image active className={classes.squareImg} />
      <Skeleton active paragraph={{ rows: 2 }} />
    </div>
  );
};

export default SkeletonHorizontalItem;
