import { MinusCircleFilled } from '@ant-design/icons';
import { Button, message } from 'antd';
import { isEqual } from 'lodash-es';
import { FC, useCallback, useEffect, useReducer, useState } from 'react';
import { DndProvider } from 'react-dnd';
import { TouchBackend } from 'react-dnd-touch-backend';
import { DragObject } from 'src/schema/schema-drag-and-drop';

import { errorObjectToString } from 'src/lib/1/util';
import { removeFavoriteProduct, updateUser } from 'src/lib/3/firebase-short-cut';
import { CommerceLogger } from 'src/lib/4/logger';

import { selectFavoriteProducts, selectUserId } from 'src/redux/slices/auth';
import { useAppSelector } from 'src/redux/store';

import { createDragAndDropReducer, dragAndDropActions } from 'src/hooks/useDragAndDrop';

import TopNavigationBar from 'src/components/Common/TopNavigationBar/TopNavigationBar';
import Loading from 'src/components/Layout/Loading';
import DragAndDropColumn from 'src/components/atoms/DragAndDrop/DragAndDropColumn';
import DragAndDropItem from 'src/components/atoms/DragAndDrop/DragAndDropItem';

import ProductItemWithFetch from '../ProductItem/ProductItemWithFetch/ProductItemWithFetch';

const logger = CommerceLogger.getInstance();

const columnId = 'favorite';
const initialState = {
  items: [],
};

const FavoriteList: FC = () => {
  const userId = useAppSelector(selectUserId);
  const favoriteProducts = useAppSelector(selectFavoriteProducts, isEqual);
  const [onEdit, setOnEdit] = useState(false);
  const [loading, setLoading] = useState(false);
  const [state, dispatch] = useReducer(createDragAndDropReducer(initialState), initialState);

  const moveItem = (item: DragObject) => {
    dispatch(dragAndDropActions.moveItem(item));
  };

  const didDropCallback = useCallback(async () => {
    if (!userId) return;
    const newFavoriteProducts = state.items.map((item) => item.id);
    setLoading(true);
    try {
      await updateUser(userId, { favoriteProducts: newFavoriteProducts });
    } catch (error) {
      console.error(error);
      const msg = errorObjectToString(error);
      logger.logCommerce(`즐겨찾기 순서 변경 실패: ${msg}`, {
        level: 'error',
      });
      message.error('즐겨찾기 순서 변경에 실패했습니다.');
    }
    setLoading(false);
  }, [state.items, userId]);

  const onRemoveFavoriteProduct = useCallback(
    async (productId: string) => {
      if (!userId) return;
      setLoading(true);
      try {
        await removeFavoriteProduct(userId, productId);
      } catch (error) {
        console.error(error);
        const msg = errorObjectToString(error);
        logger.logCommerce(`즐겨찾기 항목 삭제 실패: ${msg}`, {
          level: 'error',
        });
        message.error('즐겨찾기 항목 삭제에 실패했습니다.');
      }
      setLoading(false);
    },
    [userId]
  );

  useEffect(() => {
    if (favoriteProducts.length < 1) {
      setOnEdit(false);
    }
    const items = favoriteProducts.map((id, index) => ({ id, index, columnId }));
    dispatch(dragAndDropActions.setItems(items));
  }, [favoriteProducts]);

  return (
    <div style={{ paddingBottom: 160 }}>
      {loading && <Loading />}
      <TopNavigationBar
        borderBottom
        rightButton={
          <Button
            type='text'
            style={{ color: 'var(--blue400)', fontWeight: onEdit ? 700 : 400 }}
            onClick={() => setOnEdit((prev) => !prev)}
            disabled={favoriteProducts.length < 1}>
            {onEdit ? '완료' : '편집'}
          </Button>
        }
      />
      {onEdit ? (
        <DndProvider backend={TouchBackend}>
          <DragAndDropColumn didDropCallback={didDropCallback}>
            {state.items.map((item) => {
              return (
                <DragAndDropItem key={item.id} item={item} moveItem={moveItem}>
                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'flex-end',
                      width: 42,
                      paddingRight: 8,
                    }}>
                    <MinusCircleFilled
                      style={{ color: 'var(--orange800)', fontSize: 20 }}
                      onClick={() => onRemoveFavoriteProduct(item.id)}
                    />
                  </div>
                  <ProductItemWithFetch productId={item.id} type='list' disabled />
                </DragAndDropItem>
              );
            })}
          </DragAndDropColumn>
        </DndProvider>
      ) : (
        <div>
          {state.items.map((item) => (
            <ProductItemWithFetch key={item.id} productId={item.id} type='list' />
          ))}
        </div>
      )}
    </div>
  );
};

export default FavoriteList;
