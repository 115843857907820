import { FC } from 'react';

import { selectStoreCategories } from 'src/redux/slices/home';
import { useAppSelector } from 'src/redux/store';

import useModal from 'src/hooks/useModal';

import ExpandMoreButton from 'src/components/atoms/Button/ExpandMoreButton';
import Modal from 'src/components/atoms/Modal/Modal';
import ToolBar from 'src/components/molecules/ToolBar/ToolBar';
import StoreCategorySelector from 'src/components/organisms/StoreCategorySelector/StoreCategorySelector';

interface StoreCategoryListModalProps {
  storeCategory: string;
  size?: 'small' | 'medium' | 'large';
}

const StoreCategoryListModal: FC<StoreCategoryListModalProps> = ({ storeCategory, size }) => {
  const { isOpen, openModal } = useModal('store-category-list');
  const storeCategories = useAppSelector(selectStoreCategories);
  const currentCategory = storeCategories.find((category) => category.key === storeCategory) ?? {
    key: 'all',
    title: '전체',
    emoji: '🍽',
    index: 5,
  };

  return (
    <>
      <ExpandMoreButton onClick={openModal} size={size} label={currentCategory.title} />
      <Modal isOpen={isOpen}>
        <ToolBar title='업종선택' backButton='right' />
        <StoreCategorySelector selectedStoreCategory={storeCategory} />
      </Modal>
    </>
  );
};

export default StoreCategoryListModal;
