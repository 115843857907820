import { FC, HTMLAttributes } from 'react';

import classes from './LabeledItem.module.scss';

type LabeledItemProps = HTMLAttributes<HTMLDivElement> & {
  label: string | JSX.Element;
  text: string | JSX.Element;
  description?: string | JSX.Element;
};

const LabeledItem: FC<LabeledItemProps> = ({ label, text, description, className, ...props }) => {
  return (
    <div className={`${classes.labelItem} ${className}`} {...props}>
      <label>{label}</label>
      <div className={classes.text}>{text}</div>
      {description && <p className={classes.description}>{description}</p>}
    </div>
  );
};

export default LabeledItem;
