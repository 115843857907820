import { FC, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';

import LatestSearchKeywords from '../molecules/LatestSearchKeywords/LatestSearchKeywords';
import SearchWithAutoComplete from '../organisms/SearchWithAutoComplete/SearchWithAutoComplete';
import classes from './Search.module.scss';

const Search: FC = () => {
  const location = useLocation();
  const autoFocus = !!location.state?.autoFocus;
  const [showAutoComplete, setShowAutoComplete] = useState<boolean>(false);

  useEffect(() => {
    const htmlElement = document.documentElement;
    if (showAutoComplete) {
      htmlElement.classList.add('no-scroll');
    } else {
      htmlElement.classList.remove('no-scroll');
    }

    return () => {
      htmlElement.classList.remove('no-scroll');
    };
  }, [showAutoComplete]);

  return (
    <>
      <SearchWithAutoComplete
        showAutoComplete={showAutoComplete}
        setShowAutoComplete={setShowAutoComplete}
        autoFocus={autoFocus}
      />
      <div className={classes.searchContent}>
        <LatestSearchKeywords />
        {/* <div style={{ height: '800px', backgroundColor: 'var(--yellow300)', marginTop: 16 }}>
          광고, 이벤트, 추천 상품등의 기타 컨텐츠
        </div> */}
      </div>
    </>
  );
};

export default Search;
