import { FC, useMemo } from 'react';
import { Navigate, useParams } from 'react-router-dom';

import { selectPromotionSection } from 'src/redux/slices/home';
import { useAppSelector } from 'src/redux/store';

import { useTitle } from 'src/hooks/useTitle';

import Footer from 'src/components/atoms/Footer/Footer';
import ToolBar from 'src/components/molecules/ToolBar/ToolBar';
import GridProductListWithFetch from 'src/components/organisms/ProductList/GridProductList/GridProductListWithFetch';

const ProductsForPromotionPage: FC = () => {
  const { promotionId } = useParams();
  const selectedPromotionSection = useAppSelector((state) => selectPromotionSection(state, promotionId));
  useTitle('GU | ' + selectedPromotionSection?.title ?? '');
  const productIds = useMemo(
    () => (selectedPromotionSection ? selectedPromotionSection.items.map((i) => i.id) : []),
    [selectedPromotionSection]
  );

  return selectedPromotionSection ? (
    <>
      <ToolBar title={selectedPromotionSection.title} backButton='left' />
      <GridProductListWithFetch productIds={productIds} promotion={selectedPromotionSection.title} />
      <Footer />
    </>
  ) : (
    <Navigate to='/' replace />
  );
};

export default ProductsForPromotionPage;
