import { LoadingOutlined } from '@ant-design/icons';
import { FC } from 'react';

import classes from './FlexLoadingBox.module.scss';

const FlexLoadingBox: FC = () => {
  return (
    <div className={classes.loadingBox}>
      <LoadingOutlined className={classes.icon} />
    </div>
  );
};

export default FlexLoadingBox;
