import { InvoiceDetailDoc } from '@gooduncles/gu-app-schema';

import { calcTaxForPrice } from 'src/lib/4/order-util';

export const mergeOrderProductWithStoreIssue = (invoiceDetail: InvoiceDetailDoc) => {
  const { orders, storeIssues } = invoiceDetail;
  const orderProductInvoiceItems = orders.flatMap((order) =>
    order.products.map((p) => {
      const { supplyPrice, volume, tax, totalPrice } = calcTaxForPrice(p.snapshotPrice, p.volume, p.taxFree);
      return {
        date: order.date,
        name: p.fullName,
        price: p.snapshotPrice,
        supplyPrice,
        volume,
        tax,
        totalPrice,
        type: 'order',
      };
    })
  );
  const storeIssueItems = storeIssues.map((issue) => {
    return {
      date: issue.date,
      name: issue.message,
      price: issue.supplyPrice + issue.tax,
      supplyPrice: issue.supplyPrice,
      volume: issue.volume,
      tax: issue.tax,
      totalPrice: (issue.supplyPrice + issue.tax) * issue.volume,
      type: 'storeIssue',
    };
  });

  return [...orderProductInvoiceItems, ...storeIssueItems];
};
