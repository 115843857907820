import { StoreCategory } from '@gooduncles/gu-app-schema';
import { FC, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';

import { selectStore } from 'src/redux/slices/auth';
import {
  selectCustomPromotionSections,
  selectPromotionSections,
  selectSelectedStoreCategory,
} from 'src/redux/slices/home';
import { selectProductGroupForCategory } from 'src/redux/slices/home';
import { useAppSelector } from 'src/redux/store';

import { useApp } from 'src/stores/app-context';

import IconArrowRight from 'src/assets/icons/arrow-right.svg';

import Loading from '../Layout/Loading';
import RectButton from '../atoms/Button/RectButton';
import HomeSectionDivider from '../atoms/Divider/HomeSectionDivider';
import Footer from '../atoms/Footer/Footer';
import HomeToolbar from '../templates/HomeToolbar/HomeToolbar';
import LatestOrderProductList from '../templates/LatestOrderProductList/LatestOrderProductList';
import MainTagListForStoreCategory from '../templates/MainTagListForStoreCategory/MainTagListForStoreCategory';
import ProductListForPromotion from '../templates/ProductListForPromotion/ProductListForPromotion';
import classes from './Home.module.scss';

const Home: FC = () => {
  const navigate = useNavigate();
  const { commerceConf } = useApp();
  const store = useAppSelector(selectStore);
  const selectedStoreCategory = useAppSelector(selectSelectedStoreCategory);
  const selectedPromotionSections = useAppSelector(selectPromotionSections);
  const customPromotionSecitons = useAppSelector(selectCustomPromotionSections);
  const promotionSections = useMemo(
    () => [...selectedPromotionSections, ...customPromotionSecitons].sort((a, b) => a.positionIndex - b.positionIndex),
    [customPromotionSecitons, selectedPromotionSections]
  );
  const storeCategory: string = selectedStoreCategory ?? store?.categories?.[0] ?? 'all';

  const productGroupForCategory = useAppSelector((state) => selectProductGroupForCategory(state, storeCategory));

  return (
    <>
      <HomeToolbar storeCategory={storeCategory} />
      <>
        {commerceConf.notice && <div className={classes.noticeBar}>{commerceConf.notice}</div>}
        <div>
          {/* 최근 주문 상품 */}
          {store && <LatestOrderProductList />}
          {promotionSections.map((section) => (
            <HomeSectionDivider style={{ marginBottom: 30 }} key={section._id}>
              <ProductListForPromotion promotionSection={section} />
            </HomeSectionDivider>
          ))}
          <HomeSectionDivider>
            <h2>
              {productGroupForCategory?.data?.emoji} <b>{productGroupForCategory?.data?.title}</b> 매장에서 많이
              구매하는 상품
            </h2>
          </HomeSectionDivider>
          {productGroupForCategory?.data?.mainTags ? (
            <MainTagListForStoreCategory
              storeCategory={storeCategory as StoreCategory}
              mainTags={productGroupForCategory.data.mainTags.slice(0, productGroupForCategory.data.maxItemsToShow)}
            />
          ) : (
            <Loading />
          )}
          <div className={classes.more}>
            <RectButton
              label={
                <>
                  <b>{productGroupForCategory?.data?.title}</b> 인기 상품 더 보기
                </>
              }
              style={{ marginBottom: 30 }}
              onClick={() => {
                const htmlElement = document.documentElement;
                navigate(`/store-category/${storeCategory}`);
                htmlElement.scrollTo({ top: 0, behavior: 'auto' });
              }}>
              <img src={IconArrowRight} alt='arrow right' />
            </RectButton>
          </div>
        </div>
        <Footer />
      </>
    </>
  );
};

export default Home;
