import { FC, PropsWithChildren } from 'react';
import { useLocation } from 'react-router-dom';

import { isProduction } from 'src/lib/1/constant';

import { selectUserId } from 'src/redux/slices/auth';
import { useAppSelector } from 'src/redux/store';

import { useApp } from 'src/stores/app-context';

import OrderButtons from '../OrderButtons/OrderButtons';
import OrderCard from '../OrderCard/OrderCard';
import Loading from './Loading';
import MainNavigation from './MainNavigation';
import MenuDrawer from './MenuDrawer';

const mainStyle = {
  maxWidth: '540px',
  margin: '0 auto',
  backgroundColor: 'white',
};

type LayoutProps = {
  isLoggedIn: boolean;
  isLoading: boolean;
};

const isShowOrderPannel = (pathname: string) =>
  ['/home', '/favorite', '/shopping-cart', '/product', '/category', '/search/result', '/store-category', '/tag'].some(
    (path) => pathname.startsWith(path)
  );

const isOrderPannelBottom = (pathname: string) =>
  ['/product', '/category', '/search/result', '/store-category', '/tag'].some((path) => pathname.startsWith(path));

const isShowNavigation = (pathname: string) =>
  ['/home', '/favorite', '/shopping-cart'].some((path) => pathname.startsWith(path));

const Layout: FC<PropsWithChildren<LayoutProps>> = ({ children, isLoggedIn, isLoading }) => {
  const userId = useAppSelector(selectUserId);
  const { pathname } = useLocation();
  const { showLoading } = useApp();
  const hideOrderCard = pathname.startsWith('/shopping-cart');
  const showNavigation = isShowNavigation(pathname);
  const showOrderPannel = userId && isShowOrderPannel(pathname);
  const orderPannelBottom = isOrderPannelBottom(pathname);

  return (
    <>
      {isProduction ? null : <div style={{ position: 'fixed', top: 0, left: 0, zIndex: 1000 }}>👾</div>}
      <main id='main-div' style={mainStyle}>
        {children}
      </main>
      {showOrderPannel && (
        <>
          <OrderButtons stickToBottom={orderPannelBottom} />
          {!hideOrderCard && <OrderCard y={orderPannelBottom ? 46 : undefined} />}
        </>
      )}
      {showNavigation && <MainNavigation />}
      <MenuDrawer isLoggedIn={isLoggedIn} />
      {(showLoading || isLoading) && <Loading fontSize={56} />}
    </>
  );
};

export default Layout;
