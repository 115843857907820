import { isEqual } from 'lodash-es';
import { FC } from 'react';
import { Navigate, useParams } from 'react-router-dom';

import { selectUnhiddenProducts } from 'src/redux/slices/auth';
import { useAppSelector } from 'src/redux/store';

import { useTitle } from 'src/hooks/useTitle';

import TagProductList from 'src/components/TagProductList/TagProductList';

const TagPage: FC = () => {
  const { tag } = useParams();
  const unhiddenProducts = useAppSelector(selectUnhiddenProducts, isEqual);
  useTitle(`GU | 태그: ${tag}`);

  return tag ? (
    <div style={{ height: '100%', overflowY: 'scroll' }}>
      <TagProductList tag={tag} unhiddenProducts={unhiddenProducts} />
    </div>
  ) : (
    <Navigate to='/' replace />
  );
};

export default TagPage;
