import type { XYCoord } from 'dnd-core';
import { throttle } from 'lodash-es';
import { FC, useEffect } from 'react';
import { useDragLayer } from 'react-dnd';
import { DragObject } from 'src/schema/schema-drag-and-drop';

import ProductItemWithFetch from 'src/components/organisms/ProductItem/ProductItemWithFetch/ProductItemWithFetch';

import DragHandleBar from './DragHandleBar';

const scrollWindow = throttle((offsetY) => {
  const isTop = offsetY < 0 && window.scrollY === 0;
  const isBottom = offsetY > 0 && window.scrollY + window.innerHeight >= document.documentElement.scrollHeight;

  if (isTop || isBottom) {
    // 최상단 또는 최하단에 있을 때 추가 스크롤 방지
    return;
  }
  window.scrollBy(0, offsetY);
}, 100);

const CustomDragLayer: FC = () => {
  const { item, isDragging, initialOffset, currentOffset } = useDragLayer<{
    item: DragObject;
    initialOffset: XYCoord | null;
    currentOffset: XYCoord | null;
    isDragging: boolean;
  }>((monitor) => ({
    item: monitor.getItem(),
    initialOffset: monitor.getInitialSourceClientOffset(),
    currentOffset: monitor.getSourceClientOffset(),
    isDragging: monitor.isDragging(),
  }));

  // 자동 스크롤을 위한 로직
  useEffect(() => {
    if (!currentOffset) return;

    const { y } = currentOffset;
    const threshold = 50; // 스크롤을 시작할 화면 경계로부터의 거리
    const scrollSpeed = 20; // 스크롤 속도

    if (y < threshold) {
      // 화면 상단 경계에 도달
      scrollWindow(-scrollSpeed);
    } else if (window.innerHeight - y < threshold + 50) {
      // 화면 하단 경계에 도달
      scrollWindow(scrollSpeed);
    }
  }, [currentOffset]);

  if (!isDragging) {
    return null;
  }

  function getItemStyles(initialOffset: XYCoord | null, currentOffset: XYCoord | null) {
    if (!initialOffset || !currentOffset) {
      return {
        display: 'none',
      };
    }

    const { y } = currentOffset;
    const transform = `translate(${0}px, ${y}px)`;

    return {
      transform,
      WebkitTransform: transform,
      display: 'flex',
      backgroundColor: 'white',
      zIndex: 100,
      boxShadow:
        'rgba(0, 0, 33, 0.07) 0px 16px 22.4px 4.8px, rgba(0, 0, 33, 0.05) 0px 3.2px 16px 0px, rgba(0, 0, 33, 0.07) 0px 0px 1px 0px',
    };
  }

  return (
    <div style={{ position: 'fixed', pointerEvents: 'none', zIndex: 100, left: 0, top: 0, width: '100%' }}>
      <div style={getItemStyles(initialOffset, currentOffset)}>
        <ProductItemWithFetch productId={item.id} type='list' disabled />
        <DragHandleBar />
      </div>
    </div>
  );
};

export default CustomDragLayer;
