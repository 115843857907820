import { ProductDoc, ProductStateCode } from '@gooduncles/gu-app-schema';
import { FC, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';

import FlexSpacer from 'src/components/atoms/FlexSpacer';
import ImageWithFallback from 'src/components/atoms/Image/ImageWithFallback';

import classes from './ProductThumbnail.module.scss';

const invalidState = [ProductStateCode.DISCONTINUED, ProductStateCode.OUTOFSTOCK];

interface ProductThumbnailProps {
  product: ProductDoc;
  overlayTop?: JSX.Element;
  overlayBottom?: JSX.Element;
}

const ProductThumbnail: FC<ProductThumbnailProps> = ({ product, overlayTop, overlayBottom }) => {
  const navigate = useNavigate();

  const goToProductDetail = useCallback(() => {
    const htmlElement = document.documentElement;
    navigate(`/product/${product._id}`);
    htmlElement.scrollTo({ top: 0, behavior: 'auto' });
  }, [navigate, product._id]);

  return (
    <div className={classes.productThumbnail} onClick={goToProductDetail}>
      <ImageWithFallback src={product.thumbnail ?? undefined} alt={product.fullName} />
      <div className={classes.overlayBox}>
        <div className={classes.top}>
          {product.imageDescription ? (
            <div className={classes.imageDescription}>{product.imageDescription}</div>
          ) : (
            <FlexSpacer />
          )}
          {overlayTop}
        </div>
        <div className={classes.bottom}>{overlayBottom}</div>
      </div>
      {invalidState.includes(product.state) && (
        <div className={classes.invalid}>
          <span>{product.state === ProductStateCode.OUTOFSTOCK ? '품절' : '판매중지'}</span>
        </div>
      )}
    </div>
  );
};

export default ProductThumbnail;
