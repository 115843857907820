import { FC } from 'react';

import IconExpandMore from 'src/assets/icons/expand-more.svg';

import classes from './Button.module.scss';

interface ExpandMoreButtonProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  label: string;
  size?: 'small' | 'medium' | 'large';
}

const ExpandMoreButton: FC<ExpandMoreButtonProps> = ({ label, size = 'medium', ...props }) => {
  return (
    <button {...props} className={`${classes.expandButton} ${classes[size]}`}>
      <span>{label}</span>
      <img src={IconExpandMore} alt='expand-more' />
    </button>
  );
};

export default ExpandMoreButton;
