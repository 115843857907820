import { GoodunclesService } from '@gooduncles/gu-app-schema';
import { Button, Form, Input, message } from 'antd';
import { FC, useCallback, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { requiredRule } from 'src/lib/1/string-map';
import { MobileAlert, errorObjectToString, normalizeTel } from 'src/lib/1/util';
import { callFindEmail } from 'src/lib/3/firebase-short-cut';
import { CommerceLogger } from 'src/lib/4/logger';

import TopNavigationBar from '../Common/TopNavigationBar/TopNavigationBar';
import classes from './FindEmail.module.scss';

const logger = CommerceLogger.getInstance();
const { Item } = Form;

type FindEmailFormValues = {
  storeName: string;
  userTel: string;
};

const FindEmail: FC = () => {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const onFinish = useCallback(
    async (values: FindEmailFormValues) => {
      const { storeName, userTel } = values;
      setIsLoading(true);
      try {
        const response = await callFindEmail({
          service: GoodunclesService.Commerce,
          name: storeName,
          userTel,
        });
        if (response.data.email.length === 0) {
          MobileAlert('조회 실패', '입력하신 정보로 조회된 이메일이 없습니다.');
        } else {
          MobileAlert('이메일 찾기 성공', response.data.email.join('\n'), {
            text: '로그인하러 가기',
            onClick: () => {
              navigate('/signin', {
                replace: true,
              });
            },
          });
        }
      } catch (error) {
        const errorMessage = errorObjectToString(error);
        logger.logCommerce(`이메일 찾기에서 에러발생\n매장명: ${storeName}, 전화번호: ${userTel}\n${errorMessage}`, {
          level: 'error',
        });
      }
      setIsLoading(false);
    },
    [navigate]
  );

  const onFinishFailed = useCallback((errorInfo: any) => {
    message.error(errorInfo ?? '알 수 없는 에러발생');
  }, []);

  return (
    <>
      <TopNavigationBar title='이메일 찾기' leftButton={true} />
      <div className={classes.findEmailContainer}>
        <p className={classes.inform}>가입시 입력한 매장명과 전화번호를 적어주세요.</p>
        <Form id='findPassword' onFinish={onFinish} onFinishFailed={onFinishFailed} size='large'>
          <Item name='storeName' label='매장명' rules={requiredRule}>
            <Input />
          </Item>
          <Item
            name='userTel'
            label='전화번호'
            rules={[
              ...requiredRule,
              {
                min: 10,
                message: '전화번호 형식이 올바르지 않습니다.',
              },
            ]}
            normalize={normalizeTel}>
            <Input />
          </Item>

          <Item>
            <Button type='primary' className={classes.submitButton} htmlType='submit' block loading={isLoading}>
              이메일 찾기
            </Button>
          </Item>
        </Form>
      </div>
    </>
  );
};

export default FindEmail;
