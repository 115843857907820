import { isEqual, uniq } from 'lodash-es';
import { useMemo } from 'react';

import { selectDelivered } from 'src/redux/slices/order';
import { useAppSelector } from 'src/redux/store';

import useProductsByIds from 'src/hooks/useProductsByIds';
import { useTitle } from 'src/hooks/useTitle';

import EmptyList from 'src/components/Common/EmptyList/EmptyList';
import Footer from 'src/components/atoms/Footer/Footer';
import ToolBar from 'src/components/molecules/ToolBar/ToolBar';
import GridProductListWithFetch from 'src/components/organisms/ProductList/GridProductList/GridProductListWithFetch';

const LatestOrderProductListPage = () => {
  useTitle('GU | 최근 주문 상품');
  const deliveredOrders = useAppSelector(selectDelivered, isEqual);
  const productIds = useMemo(() => {
    const ids = deliveredOrders.flatMap((o) => o.products.flatMap((i) => i.productId));
    return uniq(ids);
  }, [deliveredOrders]);
  const { products, productsLoading } = useProductsByIds(productIds);
  const isEmpty = products.length === 0 && !productsLoading;

  return (
    <>
      <ToolBar title='최근 주문 상품' backButton='left' />
      {isEmpty ? <EmptyList /> : <GridProductListWithFetch productIds={productIds} />}
      <Footer />
    </>
  );
};

export default LatestOrderProductListPage;
