import { LoadingOutlined } from '@ant-design/icons';
import { unionBy } from 'lodash';
import { FC } from 'react';

import useProrductsForMainTag from 'src/hooks/useProrductsForMainTag';
import useProrductsForTag from 'src/hooks/useProrductsForTag';

import EmptyList from '../Common/EmptyList/EmptyList';
import ProductItem from '../ProductItem/ProductItem';
import classes from './TagProductList.module.scss';

type TagProps = {
  tag: string;
  unhiddenProducts: string[];
  // 상품 상세 페이지에서 현재 보고있는 상품의 id, 목록에서 중복되어 나오지 않게 하기 위함
  curProductId?: string;
};

const TagProductList: FC<TagProps> = ({ tag, unhiddenProducts, curProductId }) => {
  const { productsForMainTag, productsForMainTagLoading, productsForMainTagError } = useProrductsForMainTag(
    tag,
    unhiddenProducts
  );
  const { productsForTag, productsForTagLoading, productsForTagError } = useProrductsForTag(tag, unhiddenProducts);
  const products = unionBy([...productsForMainTag, ...productsForTag], '_id').filter((product) => product._id !== curProductId);

  if (productsForMainTagError || productsForTagError) {
    return (
      <div style={{ height: '100%', overflow: 'hidden' }}>
        <EmptyList isError />
      </div>
    );
  }

  return (
    <div className={`${classes.tagProductListContainer} ${products.length > 0 ? 'addPadding' : ''}`}>
      {productsForMainTagLoading || productsForTagLoading ? (
        <div className={classes.loadingBox}>
          <LoadingOutlined />
        </div>
      ) : products.length > 0 ? (
        products.map((product, idx) => (
          <ProductItem index={idx} key={'tag' + product._id} product={product} showDetail />
        ))
      ) : (
        <EmptyList />
      )}
    </div>
  );
};

export default TagProductList;
