import { format, isDate } from 'date-fns';
import { ko } from 'date-fns/locale';

type DateForamt =
  | 'yyyy-MM-dd'
  | "yyyy-MM-dd'T'HH:mm:ss+0900"
  | 'L월 d일(EEEEEE) HH:mm:ss'
  | 'L월 d일 (EEEEEE)'
  | 'yy년 L월 d일(EEEEEE)'
  | 'yy년 M월'
  | 'yyyy-MM-dd HH:mm:ss'
  | 'yyyy. M. d'
  | 'yyyy.M.d(EEEEEE)'
  | 'M.d(EEEEEE)'
  | 'L월 d일';
export const formatDate = (date: Date | string, dateFormat: DateForamt, locale = ko) =>
  format(isDate(date) ? (date as Date) : new Date(date), dateFormat, { locale });

export const getCurrentDateWithoutTime = () => {
  const nowDate = new Date();
  return new Date(nowDate.getFullYear() + '/' + (nowDate.getMonth() + 1) + '/' + nowDate.getDate());
};

/**
 * @param orderDate '2023-02-06T20:00:00+0900'
 * @returns 11월 2일(금) 20:00:11
 */
export const orderDateFormat01 = (orderDate: string) =>
  format(new Date(orderDate), 'L월 d일(EEEEEE) HH:mm:ss', { locale: ko });

/**
 * @param orderDate '2023-02-06T20:00:00+0900'
 * @returns '2018-02-06 20:00:00'
 */
export const orderDateFormat02 = (orderDate: string) =>
  format(new Date(orderDate), 'yyyy-MM-dd HH:mm:ss', { locale: ko });

/**
 * @param orderDate Date
 * @returns '2023-02-06T00:00:00+0900'
 */
export const orderDateFormat03 = (orderDate?: Date) =>
  format(orderDate ? new Date(orderDate) : new Date(), "yyyy-MM-dd'T'HH:mm:ss+0900");

/**
 *
 * @param orderDate '2023-02-06T20:00:00+0900'
 * @returns '2022. 1. 3'
 */
export const orderDateFormat04 = (orderDate: string) => format(new Date(orderDate), 'yyyy. M. d', { locale: ko });

/**
 * @param orderDate
 * @returns '1월'
 */
export const orderDateFormat05 = (orderDate: string) => format(new Date(orderDate), 'L월', { locale: ko });

/**
 * @param date
 * @returns 'L월d일'
 */
export const dateFormat06 = (date?: Date) => format(date ? new Date(date) : new Date(), 'L월d일', { locale: ko });

/**
 * @param date
 * @returns 'yyyy. MM. dd.'
 */
export const dateFormat07 = (date?: Date) => format(date ? new Date(date) : new Date(), 'yyyy. MM. dd', { locale: ko });

/**
 * @param date
 * @returns 'Y년 L월 d일 EEEE'
 */
export const dateFormat08 = (date?: Date) =>
  format(date ? new Date(date) : new Date(), 'yy년 L월 d일(EEEEEE)', { locale: ko });

/**
 * @param date
 * @returns 'yy-MM-dd.'
 */
export const dateFormat09 = (date?: Date) => format(date ? new Date(date) : new Date(), 'yy-MM-dd', { locale: ko });

/**
 *
 * @param date
 * @returns 'yyyy-MM-dd HH:mm'
 */
export const dateFormat10 = (date?: Date) =>
  format(date ? new Date(date) : new Date(), 'yyyy-MM-dd HH:mm', { locale: ko });

/**
 * @param orderDate '2023-02-06T20:00:00+0900'
 * @returns 'yyyy. M. d.'
 */
export const dateFormat11 = (date: string) => format(new Date(date), 'yyyy. M. d', { locale: ko });

/**
 *
 * @param date
 * @returns 'M. d'
 * 사파리의 경우 Date 생성자에 '/'가 아니면 에러가 발생한다.
 * '-'를 '/'로 변경하여 생성자에 넣어준다.
 * https://stackoverflow.com/questions/4310953/invalid-date-in-safari
 */
export const dateFormat12 = (date: string) => format(new Date(date.replace(/. /g, '/')), 'M. d', { locale: ko });
