import { FC } from 'react';

import OrderHistory from '../components/OrderHistory/OrderHistory';
import { useTitle } from '../hooks/useTitle';

const OrderHistoryPage: FC = () => {
	useTitle('GU | 주문 히스토리');
	return <OrderHistory />
};

export default OrderHistoryPage;
