import { ProductDoc, ProductStateCode, productPackings, productStorages } from '@gooduncles/gu-app-schema';
import { FC, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';

import { formatNumber } from 'src/lib/1/util';

import { selectProductVolume } from 'src/redux/slices/cart';
import { selectOnEditOrder, selectOrderProductVolume } from 'src/redux/slices/order';
import { useAppSelector } from 'src/redux/store';

import FavoriteButton from '../Common/FavoriteButton/FavoriteButton';
import ProductImage from '../Common/ProductImg/ProductImg';
import classes from './ProductItem.module.scss';
import ProductVolumeController from './ProductVolumeController/ProductVolumeController';

interface ProductItemProps {
  product: ProductDoc;
  index: number;
  checkBox?: JSX.Element;
  showDetail?: boolean;
}

const storagesToShow = ['refrigerator', 'freezer'];
const packingsToShow = ['box'];
const invalidState = [ProductStateCode.DISCONTINUED, ProductStateCode.OUTOFSTOCK];

const ProductItem: FC<ProductItemProps> = ({ product, index, checkBox, showDetail }) => {
  const navigate = useNavigate();
  const onEditOrder = useAppSelector(selectOnEditOrder);
  const orderProductVolume = useAppSelector((state) => selectOrderProductVolume(state, product.productId));
  const cartProductVolume = useAppSelector((state) => selectProductVolume(state, product.productId));
  const volume = onEditOrder === false ? orderProductVolume : cartProductVolume;
  const unitPrice = product.unitPrice
    ? `${product.unitPrice.amount}${product.unitPrice.unit}당 ${formatNumber(product.unitPrice.price)}원`
    : null;
  const mainBadge = (product.badge && product.badge[0]) ?? null;

  const goToProductDetail = useCallback(() => {
    // 뒤로가기 접근시 현재 페이지의 스크롤 정보를 유지하기 위해 state에 index를 저장한다.
    navigate('.', { state: { index }, replace: true });
    navigate(`/product/${product._id}`);
  }, [index, navigate, product._id]);

  return (
    <div
      className={`${classes.productContainer} ${volume > 0 ? classes.added : ''} ${
        showDetail ? classes.showDetail : ''
      } ${invalidState.includes(product.state) ? classes.invalid : ''}`}
      style={checkBox ? { paddingLeft: 42 } : {}}>
      {checkBox}
      <div className={classes.productImageBox} onClick={goToProductDetail}>
        <ProductImage product={product} />
        {product.imageDescription && <span className={classes.imageDescription}>{product.imageDescription}</span>}
        {mainBadge && <span className={classes.badge}>{mainBadge}</span>}
        {invalidState.includes(product.state) && (
          <div className={classes.invalidImg}>
            <span>{product.state === ProductStateCode.OUTOFSTOCK ? '품절' : '판매중지'}</span>
          </div>
        )}
      </div>
      <div className={classes.productContentBox}>
        <div className={classes.contentTop}>
          <a onClick={goToProductDetail} className={classes.productTitle}>
            <span className={classes.name}>{product.fullName}</span>
            {(storagesToShow.includes(product.storage) || packingsToShow.includes(product.packing)) && (
              <div className={classes.extraInfo}>
                {storagesToShow.includes(product.storage) && (
                  <span className={classes[product.storage]}>{productStorages[product.storage]}</span>
                )}
                {packingsToShow.includes(product.packing) && (
                  <span className={classes[product.packing]}>{productPackings[product.packing]}</span>
                )}
              </div>
            )}
          </a>
          <FavoriteButton productId={product.productId} />
        </div>
        <div className={classes.contentBottom}>
          <div className={classes.priceBox}>
            <span className={classes.productPrice}>{formatNumber(product.price)}원</span>
            {showDetail && unitPrice && <span className={classes.unitPrice}>{unitPrice}</span>}
          </div>
          <ProductVolumeController product={product} volume={volume} isCartItem={onEditOrder === false} />
        </div>
      </div>
    </div>
  );
};

export default ProductItem;
