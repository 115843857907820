import { FC } from 'react';

import { useTitle } from 'src/hooks/useTitle';

import Search from 'src/components/Search/Search';

const SearchPage: FC = () => {
  useTitle('GU | 검색');
  return <Search />;
};

export default SearchPage;
