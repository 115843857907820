import { datadogRum } from '@datadog/browser-rum';
import { ConfigProvider } from 'antd';
import { FC, useEffect, version } from 'react';
import { BrowserRouter } from 'react-router-dom';
import { map } from 'rxjs';

import { isProduction } from './lib/1/constant';
import { getPublicIp, sessionId } from './lib/1/util';
import { observeProductGroupForCategorySubset, observePromotionSection } from './lib/3/firebase-short-cut';
import { CommerceLogger } from './lib/4/logger';

import useHomeConf from './redux/hooks/useHomeConf';

import useAuthentication from './hooks/useAuthentication';

import { AppProvider } from './stores/app-context';

import './App.scss';
import Router from './Router';

if (isProduction && process.env.REACT_APP_DATADOG_APPLICATIONID && process.env.REACT_APP_DATADOG_CLIENTTOKEN) {
  datadogRum.init({
    applicationId: process.env.REACT_APP_DATADOG_APPLICATIONID,
    clientToken: process.env.REACT_APP_DATADOG_CLIENTTOKEN,
    site: process.env.REACT_APP_DATADOG_SITE,
    service: process.env.REACT_APP_DATADOG_SERVICE,
    env: process.env.REACT_APP_PROJECT_ID === 'gooduncles-10f09' ? 'production' : 'development',
    version,
    /**
     * 일반 세션의 수집 비율
     * 예) 100명의 사용자 중 50명의 수집을 원한다면 50으로 설정해보자.
     */
    sampleRate: 100,
    sessionReplaySampleRate: 100,
    trackInteractions: true,
    trackResources: true,
    trackLongTasks: true,
    /**
     * Replay recordings에서의 민감정보 설정
     *
     * 'mask-user-input': 리플레이에서 form안에 들어가는 input 텍스트를 마스킹 처리한다.
     * 'mask': 리플레이에서 화면의 모든 텍스트를 마스킹 처리한다.
     * 'allow': 모든 내용을 캡쳐한다.
     */
    defaultPrivacyLevel: 'allow',
    // allowedTracingOrigins: [/https:\/\/.*\.archisketch\.com/],
    // allowedTracingOrigins: ['https://dev-api.archisketch.com', /https:\/\/.*\.archisketch\.com/],
  });
  datadogRum.startSessionReplayRecording();
}

const logger = CommerceLogger.getInstance();
getPublicIp().then((ip) => {
  logger.setPublicIp(ip);
});
logger.logCommerce(`세션 시작: ${sessionId()}`);

const App: FC = () => {
  const { onSetProductGroupForCategories, onSetPromotionSections } = useHomeConf();
  // 로그인 관련 로직 처리
  useAuthentication();

  // HomeConf
  useEffect(() => {
    const subscription = observeProductGroupForCategorySubset().subscribe({
      next: (docs) => {
        docs.forEach((doc) => {
          onSetProductGroupForCategories(doc._id, doc);
        });
      },
    });
    const promotionSubscription = observePromotionSection([['isDeleted', '!=', true]])
      .pipe(map((docs) => docs?.filter((doc) => doc.isActive).sort((a, b) => a.positionIndex - b.positionIndex)))
      .subscribe(onSetPromotionSections);
    return () => {
      subscription.unsubscribe();
      promotionSubscription.unsubscribe();
    };
  }, [onSetProductGroupForCategories, onSetPromotionSections]);

  return (
    <ConfigProvider
      theme={{
        token: {
          colorPrimary: '#FF7F4C',
        },
      }}>
      <BrowserRouter>
        <AppProvider>
          <Router />
        </AppProvider>
      </BrowserRouter>
    </ConfigProvider>
  );
};

export default App;
