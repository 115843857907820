import { ProductDoc, ProductStorage } from '@gooduncles/gu-app-schema';
import { FC } from 'react';

import Badge from 'src/components/atoms/Badge/Badge';
import Price from 'src/components/atoms/Price/Price';
import UnitPrice from 'src/components/atoms/Price/UnitPrice';
import Text from 'src/components/atoms/Text/Text';

import classes from './ProductInfo.module.scss';

const StorageBadge = (storage: ProductStorage) => {
  switch (storage) {
    case 'freezer':
      return (
        <Badge colorKey='blue' border>
          냉동
        </Badge>
      );
    case 'refrigerator':
      return (
        <Badge colorKey='green' border>
          냉장
        </Badge>
      );
  }
  return null;
};

interface ProductInfoProps {
  product: ProductDoc;
  showBadge?: boolean;
}

const ProductInfo: FC<ProductInfoProps> = ({ product, showBadge }) => {
  return (
    <div className={classes.productInfo}>
      <Text>{product.fullName}</Text>
      <Price price={product.price} />
      {product.unitPrice && <UnitPrice unitPrice={product.unitPrice} />}
      <div className={classes.badgeList}>
        {showBadge &&
          product.badge &&
          product.badge.map((badge) => (
            <Badge key={badge} colorKey='orange'>
              {badge}
            </Badge>
          ))}
        {StorageBadge(product.storage)}
        {product.packing === 'box' && (
          <Badge colorKey='brown' border>
            박스
          </Badge>
        )}
      </div>
    </div>
  );
};

export default ProductInfo;
