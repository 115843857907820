import { FC } from 'react';

import { notionFaqUrl } from 'src/lib/1/constant';

import { useTitle } from '../hooks/useTitle';

import TopNavigationBar from 'src/components/Common/TopNavigationBar/TopNavigationBar';
import NotionIframe from 'src/components/atoms/NotionIframe/NotionIframe';

const FaqPage: FC = () => {
  useTitle('GU | 서비스 안내');
  return (
    <div style={{ height: '100vh', display: 'flex', flexDirection: 'column' }}>
      <TopNavigationBar title='서비스 안내' leftButton />
      <NotionIframe uri={notionFaqUrl} />
    </div>
  );
};

export default FaqPage;
