import { FC, PropsWithChildren } from 'react';

import classes from './Divider.module.scss';

const HomeSectionDivider: FC<PropsWithChildren<React.HTMLAttributes<HTMLDivElement>>> = ({ children, ...props }) => {
  return (
    <div className={classes.homeSectionDivider} {...props}>
      <div className={classes.line} />
      {children}
    </div>
  );
};

export default HomeSectionDivider;
