import { message } from 'antd';
import { addMonths } from 'date-fns';
import useSWR from 'swr';

import { formatDate } from 'src/lib/1/date-util';
import { getStoreIssuesForStoreWithWhere } from 'src/lib/3/firebase-short-cut';

const monthRange = 6;
const getStoreIssues = async (storeCode: string) => {
  const now = new Date();
  const endDate = formatDate(addMonths(now, -monthRange), "yyyy-MM-dd'T'HH:mm:ss+0900");

  return getStoreIssuesForStoreWithWhere(
    [
      ['storeCode', '==', storeCode],
      ['isDeleted', '==', false],
      ['date', '>=', endDate],
    ],
    {
      sortKey: 'date',
      orderBy: 'desc',
    }
  );
};

const useStoreIssues = (storeCode: string | null) => {
  const { data, error } = useSWR(storeCode, getStoreIssues, {
    revalidateOnFocus: false,
    errorRetryCount: 2,
  });
  const storeIssues = data?.filter(
    (issue) => issue.message && issue.message.trim().length > 0 && issue.status === '완료'
  );

  if (error) {
    console.error(error);
    message.error(error.message);
  }

  return {
    storeIssues,
  };
};

export default useStoreIssues;
