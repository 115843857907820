import { ProductDoc } from '@gooduncles/gu-app-schema';
import { useCallback, useMemo } from 'react';
import { getAlgoliaIndex } from 'src/utils/algoliasearch-util';
import useSWRInfinite from 'swr/infinite';

import { facetFilters } from 'src/lib/1/constant';

const hitsPerPage = 20;

const useInifiniteAlgoliaSearch = (
  query: string,
  restrictSearchableAttributes?: string[],
  sortKeyKr?: string
  // userId?: string
) => {
  const algoliaIndex = useMemo(() => getAlgoliaIndex(sortKeyKr), [sortKeyKr]);

  const queryWithIndexing = useCallback((page: number) => `${query}/${sortKeyKr}/page@${page}`, [query, sortKeyKr]);
  const fetcher = useCallback(
    (value: string) => {
      const page = Number(value.split('@')[1] ?? 0);
      const personalization = {};
      // const personalization = userId ? { enablePersonalization: true, userToken: userId } : {};
      return algoliaIndex.search<ProductDoc>(query, {
        hitsPerPage,
        page,
        facetFilters,
        restrictSearchableAttributes,
        ...personalization,
      });
    },
    [algoliaIndex, query, restrictSearchableAttributes]
  );
  const { data, size, setSize, isLoading, isValidating } = useSWRInfinite(queryWithIndexing, fetcher);

  return {
    hits: data?.flatMap((item) => item.hits) ?? [],
    totalPages: data?.[data.length - 1]?.nbPages ?? 0,
    totalHits: data?.[data.length - 1]?.nbHits ?? 0,
    isLoading,
    isValidating,
    size,
    setSize,
  };
};

export default useInifiniteAlgoliaSearch;
