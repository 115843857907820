import { utils, writeFile } from 'xlsx';

/**
 * json파일을 엑셀로 변환하여 다운받는다.
 * @param json
 * @param fileName
 */
export function jsonToExcelFile<T>(json: T[], fileName: string, heading?: string[][]) {
  const workBook = utils.book_new();
  if (heading) {
    const workSheet = utils.json_to_sheet([]);
    utils.sheet_add_aoa(workSheet, heading);
    utils.sheet_add_json(workSheet, json, { skipHeader: true, origin: 'A2' });
    utils.book_append_sheet(workBook, workSheet, 'sheet1');
  } else {
    const workSheet = utils.json_to_sheet(json);
    utils.book_append_sheet(workBook, workSheet, 'sheet1');
  }
  writeFile(workBook, `${fileName}.xlsx`);
}
