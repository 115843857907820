import { OrderProduct, ProductStateCode } from '@gooduncles/gu-app-schema';
import { FC, useMemo } from 'react';
import { OrderProductUI } from 'src/schema/schema-order-ui';

import { formatNumber } from 'src/lib/1/util';

import classes from './OrderItem.module.scss';

type OrderItemProps = {
  orderProduct: OrderProduct & OrderProductUI;
  volume?: number;
};

const OrderItem: FC<OrderItemProps> = ({ orderProduct, volume = 1 }) => {
  const price = orderProduct.snapshotPrice ?? orderProduct.price;
  const productAmount = useMemo(() => price * volume, [price, volume]);
  const isOutOfStuck = orderProduct.state === ProductStateCode.OUTOFSTOCK;
  const changeLog = orderProduct._ui?.changeLog;

  return (
    <div className={classes.orderItem}>
      <p className={classes.productName}>{orderProduct.fullName}</p>
      {changeLog && <p className={classes.changeLog}>실거래량 반영: {changeLog}</p>}
      <div className={`${classes.productPrice} ${isOutOfStuck ? classes.outOfStuck : ''}`}>
        <span>
          {formatNumber(price)} x {volume}
        </span>
        {isOutOfStuck ? <span>품절</span> : <span>{formatNumber(productAmount)}원</span>}
      </div>
    </div>
  );
};

export default OrderItem;
