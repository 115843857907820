import { UserOutlined } from '@ant-design/icons';
import { ProductDoc } from '@gooduncles/gu-app-schema';
import { Modal, message } from 'antd';
import { FC, useCallback, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { GaEventType } from 'src/schema/schema-ga-event';

import { addFavoriteProduct, removeFavoriteProduct, sendGaEvent } from 'src/lib/3/firebase-short-cut';

import { selectIsProductFavorite, selectUserId } from 'src/redux/slices/auth';
import { useAppSelector } from 'src/redux/store';

import FavoriteButton from 'src/components/atoms/Button/FavoriteButton';

const { confirm } = Modal;

interface FavoriteProductProps {
  product: ProductDoc & { promotion?: string };
}

const FavoriteProduct: FC<FavoriteProductProps> = ({ product }) => {
  const navigate = useNavigate();
  const userId = useAppSelector(selectUserId);
  const isFavorite = useAppSelector((state) => selectIsProductFavorite(state, product._id));
  const [isLoading, setIsLoading] = useState(false);

  const toggleFavorite = useCallback(
    async (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
      e.stopPropagation();

      if (!userId) {
        confirm({
          title: '로그인이 필요한 서비스입니다.',
          icon: <UserOutlined />,
          okText: '로그인',
          cancelText: '취소',
          centered: true,
          onOk: () => {
            navigate('/signin');
          },
        });
        return;
      }
      setIsLoading(true);
      try {
        if (isFavorite) {
          await removeFavoriteProduct(userId, product._id);
        } else {
          await addFavoriteProduct(userId, product._id);
        }
        // GA 즐겨찾기 이벤트 수집
        sendGaEvent({
          name: isFavorite ? GaEventType.REMOVE_FROM_FAVORITE : GaEventType.ADD_TO_FAVORITE,
          eventParams: {
            productId: product._id,
            productName: product.fullName,
          },
        });
        // GA 프로모션 이벤트 수집
        if (product.promotion) {
          sendGaEvent({
            name: GaEventType.PROMOTION,
            eventParams: {
              title: product.promotion,
              trigger: isFavorite ? 'remove_from_favorite' : 'add_to_favorite',
            },
          });
        }
      } catch (error) {
        console.error(error);
        message.error('오류가 발생했습니다. 다시 시도해주세요.');
      }
      setIsLoading(false);
    },
    [isFavorite, navigate, product._id, product.fullName, product.promotion, userId]
  );
  return <FavoriteButton onClick={toggleFavorite} isLoading={isLoading} isFavorite={isFavorite} />;
};

export default FavoriteProduct;
