import { FC } from 'react';

import IconMinus from 'src/assets/icons/minus.svg';
import IconPlus from 'src/assets/icons/plus.svg';

import classes from 'src/components/atoms/Button/Button.module.scss';

interface VolumeButtonProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  actionType: 'plus' | 'minus';
  size?: number;
}

const VolumeButton: FC<VolumeButtonProps> = ({ actionType, size = 38, ...props }) => {
  return (
    <button className={classes.volumeButton} style={{ width: size, height: size }} {...props}>
      {actionType === 'plus' ? <img src={IconPlus} alt='plus' /> : <img src={IconMinus} alt='minus' />}
    </button>
  );
};

export default VolumeButton;
