import { WebMessageKeys, WebViewMessageToNative } from '@gooduncles/gu-app-schema';
import { v4 } from 'uuid';

type WebView = typeof window.ReactNativeWebView;

export class WebViewMessageHandler {
  private static instance: WebViewMessageHandler;
  private webView: WebView | null;

  private constructor() {
    this.webView = null;
  }

  private sendMessage<T extends WebMessageKeys>(message: WebViewMessageToNative<T>) {
    this.webView?.postMessage(JSON.stringify(message));
  }

  public static getInstance() {
    if (WebViewMessageHandler.instance === undefined) {
      WebViewMessageHandler.instance = new WebViewMessageHandler();
    }
    return WebViewMessageHandler.instance;
  }

  public setWebViewRef(webView: WebView | null) {
    this.webView = webView;
  }

  public getWebViewRef() {
    return this.webView;
  }

  /**
   * 웹뷰와 통신 테스트를 진행합니다.
   */
  public syncWithWebview() {
    const message: WebViewMessageToNative<'syn'> = {
      _id: v4(),
      _timeCreate: Date.now(),
      sender: 'web',
      name: 'syn',
      body: null,
    };

    this.sendMessage<'syn'>(message);
  }

  /**
   * 웹뷰에 디바이스의 정보를 요청합니다.
   */
  public requestDeviceInfo() {
    const message: WebViewMessageToNative<'device'> = {
      _id: v4(),
      _timeCreate: Date.now(),
      sender: 'web',
      name: 'device',
      body: null,
    };

    this.sendMessage<'device'>(message);
  }

  /**
   * 웹뷰에 디바이스의 푸시알림 권한 확인을 요청합니다.
   * (권한이 없을 경우 Native에서 Alert창을 띄웁니다.)
   */
  public requestPermission() {
    const message: WebViewMessageToNative<'permission'> = {
      _id: v4(),
      _timeCreate: Date.now(),
      sender: 'web',
      name: 'permission',
      body: null,
    };

    this.sendMessage<'permission'>(message);
  }

  /**
   * 페이지 뷰 이벤트를 별도로 기록하여
   * Device back 버튼에 따른 뒤로가기 동작에 대응할 수 있도록 합니다.
   */
  public syncNavigationStateChange() {
    const message: WebViewMessageToNative<'navigationStateChange'> = {
      sender: 'web',
      name: 'navigationStateChange',
      body: null,
    };

    this.sendMessage<'navigationStateChange'>(message);
  }

  /**
   * 웹에서 앱에 로그를 전달합니다.
   */
  public sendLog(message: string) {
    const body = message;

    const sendMessage: WebViewMessageToNative<'webLog'> = {
      sender: 'web',
      name: 'webLog',
      body,
    };

    this.sendMessage<'webLog'>(sendMessage);
  }

  /**
   * 웹에서 IOS 앱에 뱃지 카운트를 전달합니다.
   */
  public sendBadgeCountForIos(count: number) {
    const message: WebViewMessageToNative<'badge'> = {
      sender: 'web',
      name: 'badge',
      body: {
        count,
      },
    };

    this.sendMessage<'badge'>(message);
  }
}
