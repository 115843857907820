import { FC, PropsWithChildren } from 'react';

import classes from './Badge.module.scss';

interface BadgeProps extends PropsWithChildren<React.HTMLAttributes<HTMLSpanElement>> {
  colorKey: 'green' | 'blue' | 'orange' | 'red' | 'brown';
  border?: boolean;
}

const Badge: FC<BadgeProps> = ({ children, colorKey, border, ...props }) => {
  return (
    <div className={`${classes.badge} ${classes[colorKey]} ${border ? '' : classes.noBorder}`} {...props}>
      {children}
    </div>
  );
};

export default Badge;
