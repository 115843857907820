import { OrderProduct, ProductDoc } from '@gooduncles/gu-app-schema';
import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { GaEventType } from 'src/schema/schema-ga-event';

import { deleteTimestamp, sendGaEvent } from 'src/lib/3/firebase-short-cut';
import { calcTotalAmount, productToOrderProduct } from 'src/lib/4/order-util';

import { CartState } from '../models/cartModel';
import { RootState } from '../store';

const initialState: CartState = {
  products: [],
  totalAmount: 0,
  deliveryFee: 0,
};

const cartSlice = createSlice({
  name: 'cart',
  initialState,
  reducers: {
    /**
     * 장바구니를 초기화합니다.
     */
    resetCart: (_, action: PayloadAction<string>) => {
      const reason = action.payload;
      sendGaEvent({ name: GaEventType.RESET_CART, eventParams: { reason } });
      return initialState;
    },
    /**
     * 장바구니에 상품을 추가 또는 수량을 증가시킵니다.
     */
    addToCart: (state, action: PayloadAction<{ product: ProductDoc; volume: number }>) => {
      const { product, volume } = action.payload;
      deleteTimestamp(product);
      const orderProduct = productToOrderProduct(product, volume);
      const eventParams = { ...product, volume, firstAdded: false };

      // 1. 장바구니에 상품이 없는 경우
      if (state.products === null) {
        state.products = [orderProduct];
        state.totalAmount = orderProduct.price * orderProduct.volume;
        eventParams.firstAdded = true;
        sendGaEvent({ name: GaEventType.ADD_TO_CART, eventParams });
        return;
      }
      // 2. 장바구니에 상품이 있는 경우
      const index = state.products.findIndex((p) => p.productId === orderProduct.productId);
      if (index === -1) {
        // 1) 처음으로 담는 경우
        state.products.push(orderProduct);
        eventParams.firstAdded = true;
        sendGaEvent({ name: GaEventType.ADD_TO_CART, eventParams });
      } else {
        // 2) 같은 상품이 있는 경우
        state.products[index].volume += orderProduct.volume;
        sendGaEvent({ name: GaEventType.ADD_TO_CART, eventParams });
      }
      state.totalAmount = calcTotalAmount(state.products);
    },
    /**
     * 장바구니에 상품 수량을 감소시킨다.
     */
    subFromCart: (state, action: PayloadAction<{ productId: string; volume: number }>) => {
      const { productId, volume } = action.payload;
      const idx = state.products.findIndex((p) => p.productId === productId);
      if (idx === -1) return;
      const product = state.products[idx];
      const curVolume = product.volume - volume;

      if (curVolume < 1) {
        state.products.splice(idx, 1);
        const toOriginalData = JSON.parse(JSON.stringify(product));
        sendGaEvent({
          name: GaEventType.REMOVE_FROM_CART,
          eventParams: { ...toOriginalData, volume },
        });
      } else {
        product.volume = curVolume;
        const toOriginalData = JSON.parse(JSON.stringify(product));
        sendGaEvent({
          name: GaEventType.SUB_FROM_CART,
          eventParams: { ...toOriginalData, volume },
        });
      }
      state.totalAmount = calcTotalAmount(state.products);
    },
    /**
     * 상품을 장바구니에서 제거한다.
     */
    removeFromCart: (state, action: PayloadAction<string>) => {
      const productId = action.payload;
      const idx = state.products.findIndex((p) => p.productId === productId);
      if (idx === -1) return;
      const product = state.products[idx];
      state.products.splice(idx, 1);
      state.totalAmount = calcTotalAmount(state.products);
      const toOriginalData = JSON.parse(JSON.stringify(product));
      sendGaEvent({
        name: GaEventType.REMOVE_FROM_CART,
        eventParams: { ...toOriginalData, volume: product.volume },
      });
    },
    /**
     * 상품의 수량을 조정한다.
     * 조정하는 수량값이 0보다 작은 경우는 윗단에서 걸러내야한다.
     */
    setProductVolume: (state, action: PayloadAction<{ productId: string; inputVolume: number }>) => {
      const { productId, inputVolume: volume } = action.payload;
      const idx = state.products.findIndex((p) => p.productId === productId);
      if (idx === -1 || volume < 1) return;
      const product = state.products[idx];
      if (product.volume === volume) return;
      state.products[idx].volume = volume;
      state.totalAmount = calcTotalAmount(state.products);
      const toOriginalData = JSON.parse(JSON.stringify(product));
      sendGaEvent({
        name: GaEventType.SET_PRODUCT_VOLUME,
        eventParams: { ...toOriginalData, volume },
      });
    },
    /**
     * 장바구니에 상품을 일괄 등록한다.
     */
    setProducts: (state, action: PayloadAction<OrderProduct[]>) => {
      state.products = action.payload;
      state.products.forEach((p) => deleteTimestamp(p));
      state.totalAmount = calcTotalAmount(state.products);
      sendGaEvent({
        name: GaEventType.SET_PRODUCTS,
        eventParams: {
          products: state.products.map((p) => ({
            productId: p.productId,
            volume: p.volume,
            price: p.price,
          })),
        },
      });
    },
    /**
     * 복수의 상품을 장바구니에서 제거한다.
     */
    removeProductsFromCart: (state, action: PayloadAction<string[]>) => {
      const productIds = action.payload;
      state.products = state.products.filter((p) => !productIds.includes(p.productId));
      state.totalAmount = calcTotalAmount(state.products);
      sendGaEvent({
        name: GaEventType.REMOVE_PRODUCTS_FROM_CART,
        eventParams: {
          products: state.products,
        },
      });
    },
  },
});

export const selectCart = (state: RootState) => state.cart;
export const selectCartTotalAmount = (state: RootState) => state.cart.totalAmount;
export const selectProductVolume = (state: RootState, productId: string) => {
  const product = state.cart.products.find((p) => p.productId === productId);
  return product ? product.volume : 0;
};

export const {
  resetCart,
  addToCart,
  subFromCart,
  removeFromCart,
  setProductVolume,
  setProducts,
  removeProductsFromCart,
} = cartSlice.actions;

export default cartSlice.reducer;
