import { ProductDoc } from '@gooduncles/gu-app-schema';
import { GaEventType } from 'src/schema/schema-ga-event';
import { getAlgoliaIndex } from 'src/utils/algoliasearch-util';
import useSWR from 'swr';

import { facetFilters } from 'src/lib/1/constant';
import { sendGaEvent } from 'src/lib/3/firebase-short-cut';

const index = getAlgoliaIndex();

const fetcher = async (args: [string, number, string | undefined]) => {
  // const [query, hitsPerPage, userId] = args;
  const [query, hitsPerPage] = args;
  const personalization = {};
  // const personalization = userId ? { enablePersonalization: true, userToken: userId } : {};
  const result = await index.search<ProductDoc>(query, {
    hitsPerPage,
    facetFilters,
    ...personalization,
  });
  sendGaEvent({
    name: GaEventType.SEARCH_INPUT,
    eventParams: {
      query,
      hits: result.nbHits,
    },
  });
  return result;
};

/**
 *
 * @param query
 * @param hitsPerPage
 * @param active 검색창을 활성화 한 경우에만 api를 호출한다.
 * @returns
 */
const useAlgoliaSearch = (query: string, hitsPerPage: number, active: boolean, userId?: string) => {
  const { data, isValidating, error } = useSWR(
    active && query.length > 0 ? [query, hitsPerPage, userId] : null,
    fetcher,
    {
      revalidateOnFocus: false,
      revalidateOnReconnect: false,
      errorRetryCount: 2,
    }
  );

  return {
    hits: data?.hits,
    total: data?.nbHits,
    loading: isValidating,
    error,
  };
};

export default useAlgoliaSearch;
