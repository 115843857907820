import { StoreBase, StoreDoc } from '@gooduncles/gu-app-schema';
import { useCallback } from 'react';
import { GaEventType } from 'src/schema/schema-ga-event';

import { DEFAULT_STORE_NAME, FIREBASE_AUTH_ERROR_CODE } from 'src/lib/1/constant';
import { formatDate } from 'src/lib/1/date-util';
import { MobileAlert, errorObjectToString } from 'src/lib/1/util';
import { callCreateUser, deleteTimestamp, login, logout, sendGaEvent } from 'src/lib/3/firebase-short-cut';

import { UserExceptFavoriteProducts } from '../models/authModel';
import { clearAuth, setFavoriteProducts, setIsLoading, setStore, setUser } from '../slices/auth';
import { clearOrder } from '../slices/order';
import { useAppDispatch } from '../store';

const useAuth = () => {
  const dispatch = useAppDispatch();

  const onCreateUser = useCallback(
    async (email: string, password: string, userTel: string, store: StoreBase) => {
      dispatch(setIsLoading(true));
      try {
        const result = await callCreateUser({ email, password, userTel, store });
        if (result.data.result === 'success') {
          sendGaEvent({
            name: GaEventType.SIGNUP,
            eventParams: { hasStoreInfo: store.storeName === DEFAULT_STORE_NAME },
          });
          const userCredential = await login(email, password);
          if (userCredential.user === undefined) {
            throw new Error('계정 생성후 로그인에 실패했습니다.');
          }
        } else {
          const errorReason = FIREBASE_AUTH_ERROR_CODE[result.data.errorCode ?? 'unknown'];
          MobileAlert(
            '가입실패',
            errorReason ??
              result.data.reason ??
              '알 수 없는 에러가 발생했습니다.\n카카오톡 채널(이웃삼촌)으로 문의 바랍니다.'
          );
          dispatch(setIsLoading(false));
        }
      } catch (error) {
        const errorMsg = errorObjectToString(error);
        MobileAlert(
          '가입실패',
          errorMsg ?? '알 수 없는 에러가 발생했습니다.\n카카오톡 채널(이웃삼촌)으로 문의 바랍니다.'
        );
        dispatch(setIsLoading(false));
      }
    },
    [dispatch]
  );

  const onLogin = useCallback(
    async (email: string, password: string) => {
      dispatch(setIsLoading(true));
      await login(email, password);
    },
    [dispatch]
  );

  const onLogout = useCallback(async () => {
    await logout();
    dispatch(clearAuth());
    dispatch(clearOrder());
    dispatch(setIsLoading(false));
  }, [dispatch]);

  const onSetAuthLoading = useCallback(
    (isLoading: boolean) => {
      dispatch(setIsLoading(isLoading));
    },
    [dispatch]
  );

  const onSetUser = useCallback(
    (user: UserExceptFavoriteProducts) => {
      deleteTimestamp(user);
      dispatch(setUser(user));
    },
    [dispatch]
  );

  const onSetStore = useCallback(
    (store: StoreDoc) => {
      const timeCreate = formatDate(store._timeCreate.toDate(), 'yy년 L월 d일(EEEEEE)');
      deleteTimestamp(store);
      dispatch(setStore({ ...store, timeCreate }));
    },
    [dispatch]
  );

  const onSetFavoriteProducts = useCallback(
    (favoriteProducts: string[]) => {
      dispatch(setFavoriteProducts(favoriteProducts));
    },
    [dispatch]
  );

  return {
    onCreateUser,
    onLogin,
    onLogout,
    onSetAuthLoading,
    onSetUser,
    onSetStore,
    onSetFavoriteProducts,
  };
};

export default useAuth;
