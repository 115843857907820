import { ReloadOutlined, ShopOutlined } from '@ant-design/icons';
import { StoreBase, StoreCategory } from '@gooduncles/gu-app-schema';
import { Button, Col, Form, FormInstance, Input, InputRef, Row, Select, message } from 'antd';
import { FC, useCallback, useEffect, useMemo, useRef } from 'react';
import { useNavigate } from 'react-router-dom';

import { DaumPostcodeResponse } from '../../../lib/1/schema-daum-postcode-api';
import { requiredRule } from '../../../lib/1/string-map';
import { storeBaseInit } from 'src/lib/1/constant';

import { selectStoreCategories } from 'src/redux/slices/home';
import { useAppSelector } from 'src/redux/store';

import classes from './RegisterStore.module.scss';

interface RegisterStoreProps {
  daumResponse: DaumPostcodeResponse;
  showDaumPostcodeModal: () => void;
  setStoreBase: React.Dispatch<React.SetStateAction<StoreBase | undefined>>;
}

type StoreFormValues = {
  storeName: string;
  addressDetail: string;
  categories: StoreCategory[];
};

const { Item } = Form;

const RegisterStore: FC<RegisterStoreProps> = ({ daumResponse, showDaumPostcodeModal, setStoreBase }) => {
  const navigate = useNavigate();
  const storeCategories = useAppSelector(selectStoreCategories);
  const formRef = useRef<FormInstance>(null);
  const addressDetailInputRef = useRef<InputRef | null>(null);
  const address = useMemo(
    () => (daumResponse.userSelectedType === 'R' ? daumResponse.roadAddress : daumResponse.jibunAddress),
    [daumResponse.jibunAddress, daumResponse.roadAddress, daumResponse.userSelectedType]
  );

  useEffect(() => {
    addressDetailInputRef?.current?.focus();
  }, [address]);

  const onStoreCategoryChange = useCallback((value: StoreCategory) => {
    formRef.current?.setFieldsValue({ categories: [value] });
  }, []);

  const onFinish = useCallback(
    (values: StoreFormValues) => {
      const { storeName = '', addressDetail = '', categories = ['etc'] } = values;
      const {
        address = '',
        roadAddress = '',
        jibunAddress = '',
        sido = '',
        sigungu = '',
        bname = '',
        hname = '',
        roadname = '',
      } = daumResponse ?? {};
      const newStoreBase: StoreBase = {
        ...storeBaseInit,
        storeName,
        storeNickname: storeName,
        addressDetail,
        categories,
        address,
        roadAddress,
        jibunAddress,
        sido,
        sigungu,
        bname,
        hname,
        roadname,
      };
      setStoreBase(newStoreBase);

      navigate('/signup/user');
    },
    [daumResponse, navigate, setStoreBase]
  );

  const onFinishFailed = useCallback(() => {
    message.error('필수 입력란을 확인해주세요.');
  }, []);

  return (
    <>
      <div className={classes.addressBox}>
        <Button onClick={showDaumPostcodeModal} className={classes.editSearchAddressButton}>
          <ReloadOutlined />
          다시찾기
        </Button>
        <Row>
          <Col span={6}>주소</Col>
          <Col span={18}>{address}</Col>
        </Row>
        <Row>
          <Col span={6}>우편번호</Col>
          <Col span={18}>{daumResponse.zonecode}</Col>
        </Row>
      </div>

      <Form id='storeForm' ref={formRef} onFinish={onFinish} onFinishFailed={onFinishFailed} size='large'>
        <Item className={classes.addressDetailInput} name='addressDetail' label='상세주소'>
          <Input ref={addressDetailInputRef} />
        </Item>
        <Item className={classes.storeNameInput} name='storeName' label='가게이름' rules={requiredRule}>
          <Input />
        </Item>
        <Item className={classes.categoryInput} name='categories' label='업종'>
          <Select
            placeholder='선택된 업종이 없습니다.'
            allowClear
            style={{ width: '100%' }}
            size='large'
            onChange={onStoreCategoryChange}
            options={storeCategories
              .filter((cg) => cg.key !== 'all')
              .sort((a, b) => (a.index !== b.index ? a.index - b.index : a.title.localeCompare(b.title)))
              .map((cg) => ({
                label: `${cg.emoji} ${cg.title}`,
                value: cg.key,
              }))}
          />
        </Item>
        <Item>
          <Button icon={<ShopOutlined />} className={classes.submitStoreButton} type='primary' htmlType='submit' block>
            입력완료
          </Button>
        </Item>
      </Form>
    </>
  );
};

export default RegisterStore;
