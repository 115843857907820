import { Button } from 'antd';
import { DatePicker } from 'antd-mobile';
import { ButtonType } from 'antd/es/button';
import { FC, useState } from 'react';

import { formatDate } from 'src/lib/1/date-util';

import classes from './DatePickerButton.module.scss';

interface DatePickerButtonProps {
  title: string;
  value: Date;
  onChange: (date: Date) => void;
  buttonType?: ButtonType;
  min?: Date;
  max?: Date;
  loading?: boolean;
  disabled?: boolean;
}

const DatePickerButton: FC<DatePickerButtonProps> = ({
  title,
  value,
  onChange,
  buttonType,
  min,
  max,
  loading,
  disabled,
}) => {
  const [visible, setVisible] = useState(false);
  return (
    <>
      <Button
        className={classes.datePickerButton}
        onClick={() => setVisible(true)}
        type={buttonType}
        loading={loading}
        disabled={disabled}>
        {formatDate(value, 'yyyy-MM-dd')}
      </Button>
      <DatePicker
        title={title}
        min={min}
        max={max}
        confirmText='확인'
        cancelText='취소'
        visible={visible}
        value={value}
        onConfirm={(value) => {
          setVisible(false);
          onChange(value);
        }}
        onClose={() => setVisible(false)}
      />
    </>
  );
};

export default DatePickerButton;
