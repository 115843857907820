import { message } from 'antd';
import { FC, useCallback, useEffect, useState } from 'react';
import { Navigate, useParams, useSearchParams } from 'react-router-dom';

import { decryptAES } from 'src/lib/1/util';
import { getInstallmentBill } from 'src/lib/3/firebase-short-cut';
import { InstallmentBillDoc } from 'src/lib/3/schema-bill';

import { useTitle } from '../hooks/useTitle';

import Bill from 'src/components/Bill/Bill';
import Loading from 'src/components/Layout/Loading';

const BillPage: FC = () => {
  useTitle('GU | 정산서');
  const { receiver } = useParams();
  const [searchParams] = useSearchParams();
  const billId = searchParams.get('id');
  // 매장의 요청으로 다시 보내는 경우라면 굳이 메시지를 포함하지 않을것이다.
  const noMessage = searchParams.get('no-message');
  const userTel = receiver ? decryptAES(decodeURIComponent(receiver)) : undefined;
  const [loading, setLoading] = useState(true);
  const [bill, setBill] = useState<InstallmentBillDoc | null>(null);

  const getBill = useCallback(async (id: string) => {
    setLoading(true);
    try {
      const bill = await getInstallmentBill(id);
      if (!bill) {
        throw new Error('정산서를 불러오는데 실패했습니다.');
      }
      setBill(bill);
    } catch (error: any) {
      console.error(error);
      message.error(error.message);
    }
    setLoading(false);
  }, []);

  useEffect(() => {
    if (billId) {
      getBill(billId);
    }
  }, [billId, getBill]);

  if (!userTel || !billId) {
    return <Navigate to='/not-found' replace />;
  }

  return loading ? (
    <Loading />
  ) : bill ? (
    <Bill bill={bill} noMessage={!!noMessage} />
  ) : (
    <Navigate to='/not-found' replace />
  );
};

export default BillPage;
