import { FC } from 'react';

import { useApp } from 'src/stores/app-context';

import classes from './Footer.module.scss';

const Footer: FC = () => {
  const { businessInfo } = useApp();
  return (
    <footer className={classes.footerBox}>
      <p>
        <b>이웃삼촌</b>
        <br />
        {businessInfo.businessName} | 대표자 : {businessInfo.ownerName}
        <br />
        {businessInfo.businessAddress}
        <br />
        통신판매업 신고번호: 2022-서울송파-0432
      </p>
    </footer>
  );
};

export default Footer;
