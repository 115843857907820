import { FC, HTMLAttributes } from 'react';
import { useDrop } from 'react-dnd';
import { DragObject } from 'src/schema/schema-drag-and-drop';

import CustomDragLayer from './CustomDragLayer';

interface DragAndDropColumnProps extends HTMLAttributes<HTMLDivElement> {
  didDropCallback: () => Promise<void>;
}

const DragAndDropColumn: FC<DragAndDropColumnProps> = ({ didDropCallback, children, ...props }) => {
  const [, drop] = useDrop<DragObject, DragObject>({
    accept: 'card',
    drop: (draggedItem, monitor) => {
      const didDrop = monitor.didDrop();
      if (didDrop) {
        didDropCallback();
        return;
      }

      return draggedItem;
    },
  });
  return (
    <div ref={drop} {...props}>
      <CustomDragLayer />
      {children}
    </div>
  );
};

export default DragAndDropColumn;
