import { Product } from '@gooduncles/gu-app-schema';
import { FC } from 'react';

import { formatNumber } from 'src/lib/1/util';

import classes from './Price.module.scss';

interface UnitPriceProps {
  unitPrice: Exclude<Product['unitPrice'], null>;
}

const UnitPrice: FC<UnitPriceProps> = ({ unitPrice }) => (
  <p className={classes.unitPrice}>
    {unitPrice.amount}
    {unitPrice.unit}당 {formatNumber(unitPrice.price)}원
  </p>
);

export default UnitPrice;
