import { FC } from 'react';

import BackButton from 'src/components/atoms/Button/BackButton';
import CloseButton from 'src/components/atoms/Button/CloseButton';

import classes from './ToolBar.module.scss';

interface ToolBarProps {
  title?: string;
  backButton?: 'left' | 'right';
  rightChildren?: React.ReactNode;
}

const ToolBar: FC<ToolBarProps> = ({ title, backButton, rightChildren }) => {
  return (
    <div className={classes.toolBar}>
      {backButton === 'left' ? <BackButton /> : <span className={classes.spacer} />}
      <p className={backButton === 'left' ? classes.left : undefined}>{title}</p>
      {backButton === 'right' ? <CloseButton /> : rightChildren ? null : <span className={classes.spacer} />}
      {rightChildren}
    </div>
  );
};

export default ToolBar;
