import { FC } from 'react';

import { useTitle } from '../hooks/useTitle';

import BillHistory from '../components/BillHistory/BillHistory';

const BillHistoryPage: FC = () => {
  useTitle('GU | 정산 목록');
  return <BillHistory />;
};

export default BillHistoryPage;
