import { FC } from 'react';

import Checkbox from 'src/components/atoms/Checkbox/Checkbox';

interface StoreCategoryCheckboxListProps {
  selectedStoreCategory: string;
  options: { label: string; value: string }[];
  onChange: (e: React.ChangeEvent<HTMLInputElement>, value: string) => void;
}

const StoreCategoryCheckboxList: FC<StoreCategoryCheckboxListProps> = ({
  selectedStoreCategory,
  options,
  onChange,
}) => {
  return (
    <>
      {options
        .sort((a, b) => {
          if (a.label === '전체') return 1;
          if (b.label === '전체') return -1;
          return a < b ? -1 : a > b ? 1 : 0;
        })
        .map((option) => (
          <Checkbox
            key={option.value}
            label={option.label}
            checked={selectedStoreCategory === option.value}
            onChange={(e) => onChange(e, option.value)}
          />
        ))}
    </>
  );
};

export default StoreCategoryCheckboxList;
