import { LoadingOutlined } from '@ant-design/icons';
import { FC } from 'react';

import IconSearchActive from 'src/assets/icons/search-active.svg';
import IconSearchBlack from 'src/assets/icons/search-black.svg';

import classes from './Button.module.scss';

interface SearchButtonProps extends React.AnchorHTMLAttributes<HTMLAnchorElement> {
  isLoading?: boolean;
  iconOnly?: boolean;
}
const SearchButton: FC<SearchButtonProps> = ({ isLoading, iconOnly, onClick }) => {
  return (
    <a
      className={`${classes.searchButton} ${iconOnly ? classes.iconOnly : ''}`}
      onClick={isLoading ? undefined : onClick}>
      {isLoading ? (
        <LoadingOutlined className={classes.icon} />
      ) : (
        <img src={iconOnly ? IconSearchActive : IconSearchBlack} alt='search-active' />
      )}
      {!iconOnly && <span>상품 검색</span>}
    </a>
  );
};

export default SearchButton;
