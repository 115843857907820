import { InvoiceDetailDoc } from '@gooduncles/gu-app-schema';
import { groupBy, sumBy } from 'lodash';
import { FC, Fragment } from 'react';
import { mergeOrderProductWithStoreIssue } from 'src/utils/invoice-util';

import { formatDate } from 'src/lib/1/date-util';
import { formatNumber, roundToNearestTen } from 'src/lib/1/util';
import { getValueAddedTax, getValueOfSupply } from 'src/lib/4/order-util';

import IconNested from 'src/assets/icons/nested.svg';

import HomeSectionDivider from 'src/components/atoms/Divider/HomeSectionDivider';
import Accordion from 'src/components/molecules/Accordion/Accordion';

import classes from './Invoice.module.scss';

const getDateRangeString = (dateRange: string[]) => {
  console.log(dateRange);
  const firstDate = dateRange[0];
  const lastDate = dateRange[dateRange.length - 1];
  return `${formatDate(lastDate, 'yyyy.M.d(EEEEEE)')} ~ ${formatDate(firstDate, 'yyyy.M.d(EEEEEE)')}`;
};

const Invoice: FC<{ data: InvoiceDetailDoc }> = ({ data }) => {
  const { date, recipientInfo, invoiceAmount, paymentInfo, taxExemptAmount, taxableAmount } = data;
  const invoiceItems = mergeOrderProductWithStoreIssue(data);
  const groupByDate = groupBy(invoiceItems, (item) => formatDate(item.date, 'yyyy-MM-dd'));
  const dateRange = Object.keys(groupByDate).length > 0 ? getDateRangeString(Object.keys(groupByDate)) : null;

  return (
    <div>
      <div className={classes.top}>{formatDate(date, 'yyyy.M.d(EEEEEE)')} 청구 내역</div>
      <Accordion title='기본정보' defaultExpanded>
        <div className={`${classes.gridList} ${classes.recipientInfo}`}>
          <span>고객명</span>
          <span>{recipientInfo.name}</span>
          <span>정산금액</span>
          <span>{formatNumber(invoiceAmount)} 원</span>
          <span>정산기간</span>
          <span>{dateRange ? dateRange : formatDate(date, 'yyyy.M.d(EEEEEE)')}</span>
          <span>정산방식</span>
          <span>
            {paymentInfo.paymentVendor}/{paymentInfo.paymentMethod}
          </span>
        </div>
        <p className={classes.message}>{data.message}</p>
      </Accordion>
      <HomeSectionDivider />
      <Accordion title='상세내역' defaultExpanded>
        {Object.entries(groupByDate).map(([date, items]) => {
          const totalSupplyPrice = sumBy(items, (item) => item.supplyPrice * item.volume);
          const totalTax = sumBy(items, 'tax');
          const storeIssueCount = items.filter((item) => item.type === 'storeIssue').length;
          return (
            <Fragment key={date}>
              <Accordion
                title={formatDate(date, 'M.d(EEEEEE)')}
                badge={storeIssueCount}
                size='medium'
                pannelLevel='two'
                defaultExpanded={false}>
                <div className={classes.invoiceItemList}>
                  {items.map((item) => (
                    <div key={item.date + item.name} className={`${classes.invoiceItem} ${classes[item.type]}`}>
                      <div className={classes.name}>{item.name}</div>
                      <div className={classes.price}>
                        <span>
                          {formatNumber(item.price)} x {item.volume}
                        </span>
                        <span className={classes.total}>{formatNumber(item.totalPrice)} 원</span>
                      </div>
                    </div>
                  ))}
                </div>
              </Accordion>
              <div className={`${classes.gridList} ${classes.sumTotalPrice}`}>
                <span>금액</span>
                <span>{formatNumber(sumBy(items, 'totalPrice'))} 원</span>
              </div>
              <div className={`${classes.gridList} ${classes.sumSupply}`}>
                <span>
                  <img src={IconNested} alt='nested-item' />
                  공급가
                </span>
                <span>{formatNumber(roundToNearestTen(totalSupplyPrice))} 원</span>
              </div>
              <div className={`${classes.gridList} ${classes.sumTax}`}>
                <span>
                  <img src={IconNested} alt='nested-item' />
                  세액
                </span>
                <span>{formatNumber(roundToNearestTen(totalTax))} 원</span>
              </div>
            </Fragment>
          );
        })}
        {data.prevSettlementResult && (
          <div className={`${classes.invoiceAmount} ${classes.gridList}`}>
            <span>미납금</span>
            <span>{formatNumber(data.prevSettlementResult.unpaidAmount)} 원</span>
          </div>
        )}
        <div className={`${classes.invoiceAmount} ${classes.gridList}`}>
          <span>금액 합계</span>
          <span>{formatNumber(invoiceAmount)} 원</span>
        </div>
        <div className={`${classes.gridList} ${classes.sumSupply}`}>
          <span>
            <img src={IconNested} alt='nested-item' />
            공급가
          </span>
          <span>{formatNumber(taxExemptAmount + roundToNearestTen(getValueOfSupply(taxableAmount)))} 원</span>
        </div>
        <div className={`${classes.gridList} ${classes.sumTax}`}>
          <span>
            <img src={IconNested} alt='nested-item' />
            세액
          </span>
          <span>{formatNumber(roundToNearestTen(getValueAddedTax(taxableAmount)))} 원</span>
        </div>
      </Accordion>
      <HomeSectionDivider />
    </div>
  );
};

export default Invoice;
