import { FC } from 'react';
import { useNavigate } from 'react-router-dom';

import IconClose from 'src/assets/icons/close.svg';

import classes from './Button.module.scss';

const CloseButton: FC<React.ButtonHTMLAttributes<HTMLButtonElement>> = (props) => {
  const navigate = useNavigate();
  return (
    <button {...props} className={classes.closeButton} onClick={() => navigate(-1)}>
      <img src={IconClose} alt='close' />
    </button>
  );
};

export default CloseButton;
