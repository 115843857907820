import { partition } from 'lodash';
import { DragObject } from 'src/schema/schema-drag-and-drop';
import { ActionType, createAction, createReducer } from 'typesafe-actions';

export type DragAndDropState = {
  items: DragObject[];
};

// Actions
const moveItem = createAction('dragAndDrop/moveItem')<DragObject>();
const setItems = createAction('dragAndDrop/setItems')<DragObject[]>();
export const dragAndDropActions = {
  moveItem,
  setItems,
};
export type DragAndDropAction = ActionType<typeof dragAndDropActions>;

const filterOutDraggedItems = (currentItems: DragObject[], draggedItemId: string) => {
  return currentItems.filter((c) => draggedItemId !== c.id);
};

export const createDragAndDropReducer = (initialState: DragAndDropState) =>
  createReducer<DragAndDropState, DragAndDropAction>(initialState)
    .handleAction(moveItem, (state, action) => {
      const draggedItem = action.payload;
      const { columnId, index: insertIndex } = draggedItem;

      const [columnItems, others] = partition(state.items, (i) => i.columnId === columnId);
      const otherItems = filterOutDraggedItems(others, draggedItem.id);
      const remainingItems = filterOutDraggedItems(columnItems, draggedItem.id);
      const dividerIndex = insertIndex >= 0 && insertIndex < columnItems.length ? insertIndex : columnItems.length;
      const newItems = [
        ...otherItems,
        ...remainingItems.slice(0, dividerIndex),
        draggedItem,
        ...remainingItems.slice(dividerIndex),
      ].map((item, index) => ({ ...item, index }));
      return {
        ...state,
        items: newItems,
      };
    })
    .handleAction(setItems, (state, action) => {
      return {
        ...state,
        items: action.payload,
      };
    });
