import { OrderDoc, OrderProduct, OrderStatusCode, ProductStateCode } from '@gooduncles/gu-app-schema';
import { Dictionary } from 'lodash';
import { useCallback } from 'react';

import { OrderState } from '../models/orderModel';
import { setProducts } from '../slices/cart';
import { clearOrder, setOnEditOrder, setOrderCardOpen, setOrders } from '../slices/order';
import { useAppDispatch } from '../store';

const useOrder = () => {
  const dispatch = useAppDispatch();

  const onClearOrders = useCallback(() => {
    dispatch(clearOrder());
  }, [dispatch]);

  const onSetOrders = useCallback(
    (orderGroup: Dictionary<OrderDoc[]>) => {
      const orderList: Omit<OrderState, 'orderCardOpen' | 'onEditOrder'> = {
        submitted: orderGroup[OrderStatusCode.SUBMITTED]?.[0] ?? null,
        accepted: orderGroup[OrderStatusCode.ACCEPTED] ?? [],
        delivered: orderGroup[OrderStatusCode.DELIVERED] ?? [],
        canceled: orderGroup[OrderStatusCode.CANCELED] ?? [],
        rejected: orderGroup[OrderStatusCode.REJECTED] ?? [],
      };
      dispatch(setOrders(orderList));
    },
    [dispatch]
  );

  const onSetOrderCardOpen = useCallback(
    (isOpen: boolean) => {
      dispatch(setOrderCardOpen(isOpen));
    },
    [dispatch]
  );

  /**
   * 주문을 새로이 편집할때는 현재 주문으로 장바구니를 덮어쓴다.
   */
  const onSetOnEditOrder = useCallback(
    (isOnEdit: boolean, orderProducts?: OrderProduct[]) => {
      if (isOnEdit && orderProducts) {
        const products = orderProducts.map((orderProduct) => ({
          ...orderProduct,
          volume: orderProduct.volume === 0 ? 1 : orderProduct.volume,
          state: ProductStateCode.STOCK,
        }));
        dispatch(setProducts(products));
      }
      dispatch(setOnEditOrder(isOnEdit));
    },
    [dispatch]
  );

  /**
   * 현재 주문의 상품을 장바구니에 담는다.
   */
  const resetCartWithOrder = useCallback(
    (orderProducts: OrderProduct[]) => {
      // 만약 관리자에 의해 품절 처리된 주문이 있는 경우 수량과 상태값을 재조정 해야한다.
      const products = orderProducts.map((orderProduct) => ({
        ...orderProduct,
        volume: orderProduct.volume === 0 ? 1 : orderProduct.volume,
        state: ProductStateCode.STOCK,
      }));
      dispatch(setProducts(products));
    },
    [dispatch]
  );

  return {
    onSetOrders,
    onClearOrders,
    onSetOrderCardOpen,
    onSetOnEditOrder,
    resetCartWithOrder,
  };
};

export default useOrder;
