import { ProductDoc, ProductStateCode } from '@gooduncles/gu-app-schema';
import useSWR from 'swr';

import { FirebaseManager, WHERE } from '../lib/2/firebase-manager';

const firebaseManager = FirebaseManager.getInstance();
const productPath = 'product';

const getProrductsForMainTag = async (args: [string, string[], string, 'asc' | 'desc']) => {
  const [id, unhiddenProducts, sortKey, orderBy] = args;
  const mainTag = id.split('#')[1];
  const where: WHERE[] = [['mainTag', '==', mainTag]];
  const products = (
    await firebaseManager.getDocsArrayWithWhere<ProductDoc>(productPath, where, {
      sortKey,
      orderBy,
    })
  ).filter(
    (product) =>
      // 판매중인 상태인 상품만 가져온다.
      [ProductStateCode.STOCK, ProductStateCode.OUTOFSTOCK].includes(product.state) &&
      // hidden 상태인 상품은 제외한다.
      // 단, unhiddenProducts에 포함된 상품은 제외하지 않는다.
      (product.hidden !== true || unhiddenProducts?.includes(product._id))
  );
  return products;
};

/**
 * @param mainTag
 * @param limit 한 번에 가져올 상품 페이지의 개수(한 페이지 당 10개의 상품)
 * @returns
 */
const useProrductsForMainTag = (
  mainTag: string,
  unhiddenProducts: string[],
  sortKey = 'fullName',
  orderBy: 'asc' | 'desc' = 'asc'
) => {
  const {
    data,
    isValidating: productsForMainTagLoading,
    error: productsForMainTagError,
  } = useSWR(mainTag ? [`mainTag#${mainTag}`, unhiddenProducts, sortKey, orderBy] : null, getProrductsForMainTag, {
    revalidateOnFocus: false,
    errorRetryCount: 2,
  });

  return {
    productsForMainTag: data || [],
    productsForMainTagError,
    productsForMainTagLoading,
  };
};

export default useProrductsForMainTag;
