import { UpOutlined } from '@ant-design/icons';
import { Popconfirm } from 'antd';
import { motion } from 'framer-motion';
import { FC, useCallback, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { GaEventType } from 'src/schema/schema-ga-event';

import { formatDateForKR, formatNumber, getOrderDeadline, getOrderDeliveryDate } from '../../lib/1/util';
import { sendGaEvent } from 'src/lib/3/firebase-short-cut';
import { CommerceLogger } from 'src/lib/4/logger';
import { onCancelOrder } from 'src/lib/5/order-api';

import useCart from 'src/redux/hooks/useCart';
import useOrder from 'src/redux/hooks/useOrder';
import { selectStore } from 'src/redux/slices/auth';
import { selectOnEditOrder, selectOrderCardOpen, selectSubmiited } from 'src/redux/slices/order';
import { useAppSelector } from 'src/redux/store';

import { useApp } from 'src/stores/app-context';

import classes from './OrderCard.module.scss';

const logger = CommerceLogger.getInstance();

interface OrderCardProps {
  /** 하단의 Navigation 유무에 따라 카드의 위치를 변경하고 싶은 경우 사용한다.*/
  y?: number;
}

const OrderCard: FC<OrderCardProps> = ({ y = 0 }) => {
  const navigate = useNavigate();
  const { commerceConf } = useApp();
  const submittedOrder = useAppSelector(selectSubmiited);
  const store = useAppSelector(selectStore);
  const onEditOrder = useAppSelector(selectOnEditOrder);
  const orderCardOpen = useAppSelector(selectOrderCardOpen);
  const deliveryDate = getOrderDeliveryDate(commerceConf.deliveryDateRules);

  const { onSetProducts } = useCart();
  const { onSetOrderCardOpen, onSetOnEditOrder, resetCartWithOrder } = useOrder();
  const [isOpen, setIsOpen] = useState(false);

  const cancelOrder = useCallback(() => {
    if (submittedOrder?._id) {
      resetCartWithOrder(submittedOrder.products);
      onCancelOrder(submittedOrder, store?.storeNickname ?? store?.storeName ?? '알 수 없음');
    }
  }, [submittedOrder, resetCartWithOrder, store]);

  const moveToShoppingCart = useCallback(() => {
    navigate('/shopping-cart');
    setTimeout(() => {
      document.documentElement.scrollTo({ top: 0 });
    }, 100);
    onSetOrderCardOpen(false);
  }, [navigate, onSetOrderCardOpen]);

  const editOrder = useCallback(() => {
    onSetOnEditOrder(true);
    onSetProducts(submittedOrder?.products || []);
    sendGaEvent({
      name: GaEventType.ORDER_EDIT,
      eventParams: {
        orderId: submittedOrder?._id ?? null,
      },
    });
  }, [onSetOnEditOrder, onSetProducts, submittedOrder?._id, submittedOrder?.products]);

  const toggleOrderCard = useCallback(() => {
    logger.logCommerce(`주문 카드 ${orderCardOpen ? 'close' : 'open'}`);
    onSetOrderCardOpen(!orderCardOpen);
  }, [onSetOrderCardOpen, orderCardOpen]);

  useEffect(() => {
    setIsOpen((prev) => (prev !== commerceConf.open ? commerceConf.open : prev));
  }, [commerceConf.open]);

  if (submittedOrder === null || onEditOrder) {
    return <></>;
  }

  return (
    <motion.div
      className={classes.orderCard}
      animate={orderCardOpen ? 'open' : 'close'}
      initial={{ y: y ? `calc(${146 + y}px + env(safe-area-inset-bottom))` : 146, x: '-50%' }}
      variants={{
        open: { y: y ? `calc(${14 + y}px + env(safe-area-inset-bottom))` : 14, x: '-50%' },
        close: { y: y ? `calc(${146 + y}px + env(safe-area-inset-bottom))` : 146, x: '-50%' },
      }}>
      <button
        className={`${classes.drawerHandle} ${orderCardOpen ? classes.open : undefined}`}
        onClick={toggleOrderCard}>
        <UpOutlined />
      </button>
      <div className={classes.mainBox}>
        <div>
          <h3>총 {formatNumber(submittedOrder.paidAmount)}원 주문완료</h3>
          <p>{formatDateForKR(deliveryDate)}까지 배송완료예정</p>
        </div>
        {isOpen && <button onClick={editOrder}>수정</button>}
      </div>

      <div className={classes.extraBox}>
        <h3>
          {formatDateForKR(deliveryDate)}에 도착하는 주문은
          <br />
          {getOrderDeadline(deliveryDate)}
        </h3>

        {isOpen ? (
          <div className={classes.buttonBox}>
            <Popconfirm
              placement='top'
              title='정말로 취소하시겠습니까?'
              onConfirm={cancelOrder}
              okText='Yes'
              cancelText='No'>
              <button className={classes.cancelOrder}>주문취소</button>
            </Popconfirm>
            <button onClick={moveToShoppingCart}>주문목록확인</button>
          </div>
        ) : (
          <div className={classes.emptyBox} />
        )}
      </div>
    </motion.div>
  );
};

export default OrderCard;
