import { StoreCategory } from '@gooduncles/gu-app-schema';
import { FC } from 'react';
import { Navigate, useParams } from 'react-router-dom';

import StoreCategoryComponent from 'src/components/StoreCategoryComponent/StoreCategoryComponent';

const StoreCategoryPage: FC = () => {
  const { storeCategory } = useParams();

  return storeCategory ? (
    <StoreCategoryComponent storeCategory={storeCategory as StoreCategory} />
  ) : (
    <Navigate to='/' replace />
  );
};

export default StoreCategoryPage;
