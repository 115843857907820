import { CloseOutlined } from '@ant-design/icons';
import { Button, Drawer } from 'antd';
import { CSSProperties, FC, useEffect, useMemo } from 'react';

import { updateNotificationDoc } from 'src/lib/3/firebase-short-cut';

import useAuth from 'src/redux/hooks/useAuth';

import useNotificationById from 'src/hooks/useNotificationById';

import NotionIframe from 'src/components/atoms/NotionIframe/NotionIframe';

import TopNavigationBar from '../TopNavigationBar/TopNavigationBar';

type PostDrawerProps = {
  type?: 'notification' | 'popup';
  postId: string | null;
  onClose: () => void;
};

const PostDrawer: FC<PostDrawerProps> = ({ type = 'notification', postId, onClose }) => {
  const { onSetAuthLoading } = useAuth();
  const { notification, isValidating } = useNotificationById(postId);
  const contentType = useMemo(
    () => (notification?.action.type === 'notion' ? 'notion' : 'default'),
    [notification?.action.type]
  );

  useEffect(() => {
    onSetAuthLoading(isValidating);
  }, [isValidating, onSetAuthLoading]);

  useEffect(() => {
    if (notification?.unread) {
      updateNotificationDoc(notification?._id, { unread: false });
    }
  }, [notification?._id, notification?.unread]);

  return (
    <Drawer
      width='100vw'
      closable={false}
      placement={type === 'notification' ? 'right' : 'bottom'}
      open={!!postId}
      bodyStyle={DrawerBodyStyle}
      onClose={onClose}>
      <TopNavigationBar
        title={notification ? notification.title : '알림'}
        rightButton={
          <Button type='text' style={ButtonStyle} onClick={onClose}>
            <CloseOutlined style={IconStyle} />
          </Button>
        }
      />
      {contentType === 'notion' ? <NotionIframe uri={notification?.action.uri} /> : null}
    </Drawer>
  );
};

export default PostDrawer;

const ButtonStyle: CSSProperties = {
  border: 'none',
  width: 42,
  height: 48,
  padding: 0,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
};

const IconStyle: CSSProperties = {
  color: 'var(--gray700)',
  fontSize: '20px',
};

const DrawerBodyStyle: CSSProperties = {
  padding: 0,
  height: '100%',
  display: 'flex',
  flexDirection: 'column',
};
