import { OrderProduct } from '@gooduncles/gu-app-schema';
import { FC } from 'react';

import OrderItem from '../Common/OrderItem/OrderItem';

type OrderListProps = {
  products: OrderProduct[] | null;
};

const OrderList: FC<OrderListProps> = ({ products }) => {
  return (
    <section style={{ paddingBottom: '120px' }}>
      {products?.map((p) => (
        <OrderItem key={p.productId} orderProduct={p} volume={p.volume} />
      ))}
    </section>
  );
};

export default OrderList;
