import { LoadingOutlined } from '@ant-design/icons';
import { message } from 'antd';
import { FC, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { GaEventType } from 'src/schema/schema-ga-event';

import { saveLatestSearchKeyword } from 'src/lib/1/util';
import { sendGaEvent } from 'src/lib/3/firebase-short-cut';

import { selectUserId } from 'src/redux/slices/auth';
import { useAppSelector } from 'src/redux/store';

import useAlgoliaSearch from 'src/hooks/useAlgoliaSearch';
import useVisualViewport from 'src/hooks/useVisualViewport';

import PreviewItem from 'src/components/atoms/Item/PreviewItem';
import SearchToolBar from 'src/components/molecules/ToolBar/SearchToolBar';

import classes from './SearchWithAutoComplete.module.scss';

const pageSize = 20;

interface SearchWithAutoCompleteProps {
  showAutoComplete: boolean;
  setShowAutoComplete: React.Dispatch<React.SetStateAction<boolean>>;
  /** 최초 렌더링후 입력창에 focus를 자동으로 할지 여부 */
  autoFocus: boolean;
  /** 검색시 페이지를 push 또는 replace 여부 */
  replace?: boolean;
  defaultValue?: string;
}

const SearchWithAutoComplete: FC<SearchWithAutoCompleteProps> = ({
  showAutoComplete,
  setShowAutoComplete,
  autoFocus,
  replace = false,
  defaultValue = '',
}) => {
  const userId = useAppSelector(selectUserId);
  const navigate = useNavigate();
  const [focus, setFocus] = useState<boolean>(false);
  const [keyword, setKeyword] = useState<string>(defaultValue);
  const { hits, loading, error } = useAlgoliaSearch(keyword, pageSize, showAutoComplete, userId);

  const { height } = useVisualViewport();

  const onClickBackdrop = () => {
    setShowAutoComplete(false);
  };

  const goToSearchResultPage = (query: string, isSelectEvent?: boolean) => {
    if (query.length === 0) {
      message.error('검색어를 입력해주세요.');
      return;
    }
    saveLatestSearchKeyword(query);
    sendGaEvent({
      name: isSelectEvent ? GaEventType.SEARCH_SELECT_PREVIEW : GaEventType.SEARCH_SUBMIT,
      eventParams: {
        query,
      },
    });
    navigate(`/search/result?q=${encodeURIComponent(query)}`, { replace });
    setShowAutoComplete(false);
  };

  useEffect(() => {
    setShowAutoComplete(() => keyword.length === 0);
  }, [keyword.length, setShowAutoComplete]);

  useEffect(() => {
    if (focus) {
      setShowAutoComplete(() => keyword.length > 0);
    }
  }, [focus, keyword.length, setShowAutoComplete]);

  useEffect(() => {
    if (error) {
      message.error(error.message);
    }
  }, [error]);

  return (
    <div className={classes.overlay}>
      <SearchToolBar
        onSubmit={goToSearchResultPage}
        setKeywordForAutocomplete={setKeyword}
        setOnFocus={setFocus}
        defaultValue={defaultValue}
        autoFocus={autoFocus}
      />
      {showAutoComplete && (
        <div className={classes.result} onClick={onClickBackdrop} style={{ height }}>
          {loading ? (
            <div className={classes.loading}>
              <LoadingOutlined style={{ fontSize: 32, color: 'white' }} />
            </div>
          ) : hits && hits.length > 0 ? (
            <div className={classes.previewList}>
              {hits.map((hit) => {
                const name = hit._highlightResult?.fullName?.value;
                const query = hit.fullName;
                return (
                  name && (
                    <PreviewItem key={hit.objectID} value={name} onClick={() => goToSearchResultPage(query, true)} />
                  )
                );
              })}
            </div>
          ) : (
            <div className={classes.empty} />
          )}
        </div>
      )}
    </div>
  );
};

export default SearchWithAutoComplete;
