import { LoadingOutlined } from '@ant-design/icons';
import { Button, Form, Input, message } from 'antd';
import { FC, useCallback, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { orderDateFormat03 } from 'src/lib/1/date-util';
import { MobileAlert, errorObjectToString } from 'src/lib/1/util';
import { createDeleteAccountRequest, getDeleteAccountRequest } from 'src/lib/3/firebase-short-cut';
import { CommerceLogger } from 'src/lib/4/logger';

import { UserExceptFavoriteProducts } from 'src/redux/models/authModel';
import { selectStore } from 'src/redux/slices/auth';
import { useAppSelector } from 'src/redux/store';

import TopNavigationBar from '../Common/TopNavigationBar/TopNavigationBar';
import classes from './DeleteAccount.module.scss';

const logger = CommerceLogger.getInstance();

const { Item } = Form;

interface DeleteAccountProps {
  user: UserExceptFavoriteProducts;
}

const DeleteAccount: FC<DeleteAccountProps> = ({ user }) => {
  const store = useAppSelector(selectStore);
  const [alreadyRequested, setAlreadyRequested] = useState(false);
  const [requestForm] = Form.useForm();
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();

  const onFormFinish = useCallback(
    async (values: { reason: string }) => {
      setLoading(true);
      try {
        await createDeleteAccountRequest({
          _id: user._id,
          requestedAt: orderDateFormat03(),
          reason: values.reason,
        });
        MobileAlert('요청 완료', '빠른 시일내에 처리하겠습니다.', {
          text: '확인',
          onClick: () => {
            navigate('/', { replace: true });
          },
        });
        logger.logCommerce(`[탈퇴 요청] ${user.email} / ${store?.storeNickname}\n탈퇴사유: ${values.reason}`, {
          channel: '4-앱-고객요청',
        });
      } catch (error) {
        console.error(error);
        const msg = errorObjectToString(error);
        logger.logCommerce(`탈퇴 요청 실패: ${msg}`, {
          level: 'error',
        });
        message.error('요청에 실패했습니다.');
      }
      setLoading(false);
    },
    [navigate, store?.storeNickname, user._id, user.email]
  );

  useEffect(() => {
    getDeleteAccountRequest(user._id).then((doc) => {
      setAlreadyRequested(!!doc);
      setLoading(false);
    });
  }, [user._id]);

  return (
    <>
      <TopNavigationBar title='회원탈퇴' leftButton={true} />
      {loading && (
        <div className={classes.loadingBox}>
          <LoadingOutlined className={classes.loadingIcon} />
        </div>
      )}
      {alreadyRequested ? (
        <div className={classes.alreadyRequest}>
          요청한 이력이 있습니다.
          <br />
          빠른 시일내에 처리하겠습니다.
          <br />
          감사합니다.
        </div>
      ) : loading ? null : (
        <div className={classes.requestForm}>
          <Form form={requestForm} onFinish={onFormFinish}>
            <Item name='reason' label='탈퇴사유'>
              <Input size='large' />
            </Item>
            <Item>
              <Button type='primary' className={classes.deleteRequestButton} htmlType='submit' block>
                탈퇴요청
              </Button>
            </Item>
          </Form>
        </div>
      )}
    </>
  );
};

export default DeleteAccount;
