import {
  BellOutlined,
  CloseOutlined,
  LoginOutlined,
  LogoutOutlined,
  ProfileOutlined,
  QuestionCircleOutlined,
  RightOutlined,
  SyncOutlined,
  UserOutlined,
} from '@ant-design/icons';
import { Avatar, Badge, Button, Drawer } from 'antd';
import { Dialog } from 'antd-mobile';
import { FC, useCallback, useMemo } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { GaEventType } from 'src/schema/schema-ga-event';

import { LocalStorageKey, kakaoTalkUrl } from 'src/lib/1/constant';
import { getLocalStorageValue } from 'src/lib/1/util';
import { sendGaEvent } from 'src/lib/3/firebase-short-cut';
import { DeviceInfo } from 'src/lib/3/schema-app';

import useAuth from 'src/redux/hooks/useAuth';
import useCart from 'src/redux/hooks/useCart';
import useHomeConf from 'src/redux/hooks/useHomeConf';
import { selectStore, selectUser } from 'src/redux/slices/auth';
import { selectIsMenuOpen } from 'src/redux/slices/home';
import { useAppSelector } from 'src/redux/store';

import useNotificationForUserId from 'src/hooks/useNotificationForUserId';

import ChatIcon from '../../assets/icons/chat.svg';
import AvailableLocation from '../../assets/images/available-location.png';

import pkg from '../../../package.json';
import classes from './MenuDrawer.module.scss';

const openKakaoChat = () => window.open(kakaoTalkUrl, '_blank');
const reload = () => window.location.reload();
const openAvailableLocationModal = () => {
  Dialog.alert({
    content: (
      <div className={classes.locationModal}>
        <div className={classes.locationImageBox}>
          <img src={AvailableLocation} alt='배송지역' />
        </div>
        <p>
          서비스 지역: 서울 7구
          <br />
          강남, 송파, 강동, 서초, 관악, 광진, 성동
          <br />
          <br />
          <span>
            ※ 이웃삼촌의 이용 가능 지역은 지속적으로 확장되고 있으며, 앞으로도 더 많은 지역에서 서비스를 제공할
            예정입니다. 자세한 내용은 카카오톡으로 문의주세요.
          </span>
        </p>
      </div>
    ),
    confirmText: '닫기',
    closeOnMaskClick: true,
  });
};

const MenuDrawer: FC<{ isLoggedIn: boolean }> = ({ isLoggedIn }) => {
  const user = useAppSelector(selectUser, (prev, next) => prev?._id === next?._id);
  const isMenuOpen = useAppSelector(selectIsMenuOpen);
  const store = useAppSelector(selectStore);
  const { onResetCart } = useCart();
  const { onSetIsMenuOpen } = useHomeConf();
  const navigate = useNavigate();
  const isGuest = useMemo(() => store?.state === 'guest', [store?.state]);
  const userId = useMemo(() => user?._id, [user?._id]);
  const deviceInfo = getLocalStorageValue<DeviceInfo>(LocalStorageKey.deviceInfo);
  const { notificationList } = useNotificationForUserId(user?._id);
  const { onLogout } = useAuth();

  const logout = useCallback(() => {
    onSetIsMenuOpen(false);
    onResetCart('로그아웃');
    onLogout().then(() => {
      sendGaEvent({
        name: GaEventType.LOGOUT,
        eventParams: { email: user?.email ?? 'unknown' },
      });
    });
  }, [onSetIsMenuOpen, onResetCart, onLogout, user?.email]);

  const signin = useCallback(() => {
    onSetIsMenuOpen(false);
    navigate('/signin');
  }, [navigate, onSetIsMenuOpen]);

  const signup = useCallback(() => {
    onSetIsMenuOpen(false);
    navigate('/signup');
  }, [navigate, onSetIsMenuOpen]);

  const goNotificationPage = useCallback(() => {
    navigate('/notification');
    onSetIsMenuOpen(false);
  }, [navigate, onSetIsMenuOpen]);

  const goToProfile = useCallback(() => {
    navigate('/profile');
    onSetIsMenuOpen(false);
  }, [navigate, onSetIsMenuOpen]);

  return (
    <Drawer width='78vw' closable={false} onClose={() => onSetIsMenuOpen(false)} placement='right' open={isMenuOpen}>
      <div className={classes.drawerContainer}>
        <div className={classes.drawerContent}>
          {isLoggedIn ? (
            <div className={classes.loggedInUser}>
              <div className={classes.logout}>
                {userId ? (
                  <Button onClick={goNotificationPage} type='text' style={{ padding: '4px 15px 4px 0' }}>
                    <Badge count={notificationList?.unreadCount}>
                      <BellOutlined style={{ color: 'var(--gray700)', fontSize: '20px' }} />
                    </Badge>
                  </Button>
                ) : (
                  <span />
                )}
                <Button onClick={logout} icon={<LogoutOutlined />}>
                  로그아웃
                </Button>
              </div>

              <div className={classes.infoBox} onClick={goToProfile}>
                <Avatar icon={<UserOutlined />} style={{ marginRight: '6px', minWidth: '32px' }} />
                <div className={classes.userInfo}>
                  <p className={classes.storeName}>
                    {store?.storeName}
                    {isGuest && <span className={classes.isGuest}>승인대기</span>}
                  </p>
                  <p className={classes.email}>{user?.email}</p>
                </div>
              </div>

              <div className={classes.linkList}>
                <Link to='/order-history' className={classes.linkItem} onClick={() => onSetIsMenuOpen(false)}>
                  <ProfileOutlined style={{ fontSize: 20, marginRight: 10 }} />
                  <p>주문 히스토리</p>
                  <RightOutlined />
                </Link>
                <Link to='/profile' className={classes.linkItem} onClick={() => onSetIsMenuOpen(false)}>
                  <UserOutlined style={{ fontSize: 20, marginRight: 10 }} />
                  <p>회원정보</p>
                  <RightOutlined />
                </Link>
                {/* <Link to='/bill-history' className={classes.linkItem} onClick={() => onSetIsMenuOpen(false)}>
                  <AuditOutlined style={{ fontSize: 20, marginRight: 10 }} />
                  <p>정산 목록</p>
                  <RightOutlined />
                </Link> */}
              </div>
            </div>
          ) : (
            <div className={classes.signinButtons}>
              <button onClick={signup}>
                <UserOutlined />
                회원가입
              </button>
              <button onClick={signin}>
                <LoginOutlined />
                로그인
              </button>
            </div>
          )}
        </div>
        <div className={classes.extraSpace}>
          <div className={classes.section}>
            <Link to='/faq' className={classes.sectionTop} onClick={() => onSetIsMenuOpen(false)}>
              <span>서비스 안내</span>
              <RightOutlined className={classes.extraIcon} />
            </Link>
          </div>
          <div className={`${classes.section} ${classes.availableLocation}`}>
            <div className={classes.sectionTop}>
              <span>서비스 지역</span>
              <Button
                type='text'
                style={{ color: 'var(--gray600)' }}
                onClick={openAvailableLocationModal}
                icon={<QuestionCircleOutlined />}
              />
            </div>
            <p>
              강남, 송파, 강동, 서초, 관악, 광진, 성동
              <br />※ 자세한 지역 안내는 카카오톡으로 문의주세요.
            </p>
          </div>
          <div className={classes.section}>
            <div className={classes.sectionTop}>
              <span>고객센터</span>
              <Button
                className={classes.kakaoLink}
                onClick={openKakaoChat}
                icon={<img src={ChatIcon} alt='이웃삼촌 카카오톡 링크' />}>
                카카오 상담톡
              </Button>
            </div>
            <p>
              평일 오전9시 ~ 오후11시
              <br />
              주말/공휴일 휴무
              <br />
              Tel: 1533-2537
            </p>
          </div>
        </div>
        <footer className={classes.drawerFooter}>
          <p className={classes.appInfo}>
            <Button type='text' onClick={reload} icon={<SyncOutlined />} size='small' style={{ marginRight: 4 }} />
            version: {pkg.version}
            {deviceInfo ? ` / ${deviceInfo?.readableVersion}` : ''}
          </p>
          <Button
            className={classes.closeButton}
            onClick={() => onSetIsMenuOpen(false)}
            type='text'
            icon={<CloseOutlined style={{ fontSize: 16 }} />}
          />
        </footer>
      </div>
    </Drawer>
  );
};

export default MenuDrawer;
