import { isEqual, uniq } from 'lodash-es';
import { FC, useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { selectDelivered } from 'src/redux/slices/order';
import { useAppSelector } from 'src/redux/store';

import useOnScreen from 'src/hooks/useOnScreen';
import useProductsByIds from 'src/hooks/useProductsByIds';

import IconArrowRight from 'src/assets/icons/arrow-right.svg';

import TextButton from 'src/components/atoms/Button/TextButton';
import HomeSectionDivider from 'src/components/atoms/Divider/HomeSectionDivider';
import Title from 'src/components/atoms/Title/Title';
import HorizontalProductList from 'src/components/organisms/ProductList/HorizontalProductList/HorizontalProductList';

import classes from './LatestOrderProductList.module.scss';

interface LatestOrderProductListProps {
  /** 참조할 최근 주문의 최대 갯수 */
  numberOfOrders?: number;
}

/**
 * 고객의 최근 주문 n개의 상품 목록을 보여주는 컴포넌트
 */
const LatestOrderProductList: FC<LatestOrderProductListProps> = ({ numberOfOrders = 20 }) => {
  const navigate = useNavigate();
  const [ref, setRef] = useState<HTMLDivElement | null>(null);
  const isVisible = useOnScreen(ref);
  const [isLoaded, setIsLoaded] = useState(false);

  const deliveredOrders = useAppSelector(selectDelivered, isEqual);
  const productIds = useMemo(() => {
    const ids = deliveredOrders.slice(0, numberOfOrders).flatMap((o) => o.products.flatMap((i) => i.productId));
    return uniq(ids);
  }, [deliveredOrders, numberOfOrders]);
  const { products, productsLoading } = useProductsByIds(productIds);

  const goToPromotionPage = () => {
    const htmlElement = document.documentElement;
    navigate(`/latest-order-product`);
    htmlElement.scrollTo({ top: 0, behavior: 'auto' });
  };

  useEffect(() => {
    if (isVisible && !isLoaded) {
      setIsLoaded(true);
    }
  }, [isLoaded, isVisible]);

  if (deliveredOrders.length === 0) {
    return null;
  }

  return (
    <div ref={setRef}>
      <HomeSectionDivider style={{ marginBottom: 30 }}>
        <div className='promotionTitlePadding'>
          <div className={classes.flexRow}>
            <Title>🗓️ 최근 주문 상품</Title>
            <TextButton label='모두보기' color='gray' onClick={goToPromotionPage}>
              <img src={IconArrowRight} alt='arrow right' />
            </TextButton>
          </div>
        </div>
        <HorizontalProductList showBadge products={products} loading={productsLoading} />
      </HomeSectionDivider>
    </div>
  );
};

export default LatestOrderProductList;
