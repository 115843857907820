import { FC } from 'react';

import { useTitle } from '../hooks/useTitle';

import Home from 'src/components/Home/Home';

const HomePage: FC = () => {
  useTitle('GU | 홈');
  return <Home />;
};

export default HomePage;
