import { StoreBase } from '@gooduncles/gu-app-schema';
import { FC, useCallback, useState } from 'react';
import { Navigate, Route, Routes, useLocation, useNavigate } from 'react-router-dom';

import { DaumPostcodeResponse } from '../../lib/1/schema-daum-postcode-api';

import TopNavigationBar from '../Common/TopNavigationBar/TopNavigationBar';
import DaumPostcodeModal from '../Modal/DaumPostcodeModal/DaumPostcodeModal';
import RegisterStore from './RegisterStore/RegisterStore';
import classes from './Signup.module.scss';
import SignupIntro from './SignupIntro/SignupIntro';
import SignupUser from './SignupUser/SignupUser';

/**
 * 회원가입 단계
 * 1. 주소 찾기
 * 2. 상세주소 & 매장 이름 입력
 * 3. 회원정보 입력
 */
const Signup: FC = () => {
  const location = useLocation();
  const navigate = useNavigate();

  const [showSearchModal, setShowSearchModal] = useState(false);
  const [daumResponse, setDaumResponse] = useState<DaumPostcodeResponse>();
  const [storeBase, setStoreBase] = useState<StoreBase | undefined>();

  // userDoc
  // storeDoc
  const onSearchComplete = useCallback(
    (data: DaumPostcodeResponse) => {
      setDaumResponse(data);
      if (location.pathname !== '/signup/register-store') {
        navigate('/signup/register-store');
      }
    },
    [location.pathname, navigate]
  );

  const showDaumPostcodeModal = useCallback(() => {
    setShowSearchModal(true);
    setStoreBase(undefined);
  }, []);

  return (
    <>
      <TopNavigationBar title='회원가입' leftButton={true} />
      <div className={classes.signupContainer}>
        <Routes>
          <Route
            path='/'
            element={<SignupIntro showDaumPostcodeModal={showDaumPostcodeModal} setStoreBase={setStoreBase} />}
          />
          <Route
            path='/register-store'
            element={
              daumResponse ? (
                <RegisterStore
                  showDaumPostcodeModal={showDaumPostcodeModal}
                  daumResponse={daumResponse}
                  setStoreBase={setStoreBase}
                />
              ) : (
                <Navigate to='/signup' />
              )
            }
          />
          <Route path='/user' element={storeBase ? <SignupUser storeBase={storeBase} /> : <Navigate to='/signup' />} />
          {/* <Route path='/find-store'/>*/}
        </Routes>
        <DaumPostcodeModal visible={showSearchModal} setVisible={setShowSearchModal} onComplete={onSearchComplete} />
      </div>
    </>
  );
};

export default Signup;
