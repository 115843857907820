import { FC, useMemo } from 'react';
import { Navigate, useParams } from 'react-router-dom';

import { selectCustomPromotionSection } from 'src/redux/slices/home';
import { useAppSelector } from 'src/redux/store';

import { useTitle } from 'src/hooks/useTitle';

import Footer from 'src/components/atoms/Footer/Footer';
import ToolBar from 'src/components/molecules/ToolBar/ToolBar';
import GridProductListWithFetch from 'src/components/organisms/ProductList/GridProductList/GridProductListWithFetch';

const ProductsForCustomPromotionPage: FC = () => {
  const { promotionId } = useParams();
  const selectedCustomPromotionSection = useAppSelector((state) => selectCustomPromotionSection(state, promotionId));
  useTitle('GU | ' + selectedCustomPromotionSection?.title ?? '');
  const productIds = useMemo(
    () => (selectedCustomPromotionSection ? selectedCustomPromotionSection.items.map((i) => i.id) : []),
    [selectedCustomPromotionSection]
  );

  return selectedCustomPromotionSection ? (
    <>
      <ToolBar title={selectedCustomPromotionSection.title} backButton='left' />
      <GridProductListWithFetch productIds={productIds} promotion={selectedCustomPromotionSection.title} />
      <Footer />
    </>
  ) : (
    <Navigate to='/' replace />
  );
};

export default ProductsForCustomPromotionPage;
