import { RightOutlined } from '@ant-design/icons';
import { StoreIssueDoc } from '@gooduncles/gu-app-schema';
import { FC } from 'react';
import { useNavigate } from 'react-router-dom';

import { formatDate } from 'src/lib/1/date-util';
import { formatNumber } from 'src/lib/1/util';

import classes from './StoreIssueGroup.module.scss';

type StoreIssueGroupProps = {
  orderDate: string;
  storeIssues: StoreIssueDoc[];
};

const StoreIssueGroup: FC<StoreIssueGroupProps> = ({ orderDate, storeIssues }) => {
  const navigate = useNavigate();
  const date = formatDate(orderDate, 'L월 d일 (EEEEEE)');
  const queryParams = storeIssues ? '?' + storeIssues?.map((issue) => `issue-id=${issue._id}`).join('&') : '';
  const issuesTotalAmount = storeIssues
    ? storeIssues.reduce((acc, cur) => acc + ((cur.supplyPrice ?? 0) + (cur.tax ?? 0)) * (cur.volume ?? 1), 0)
    : 0;

  return (
    <div className={classes.storeIssueGroup} onClick={() => navigate(`/issue-detail${queryParams}`)}>
      <div className={classes.issueInfo}>
        <p className={classes.issueDate}>특이사항: {date}</p>
        <p className={classes.issueAmount}>
          {storeIssues.length}건{issuesTotalAmount ? ' / ' : ''}
          {issuesTotalAmount ? `${formatNumber(issuesTotalAmount)}원` : ''}
        </p>
      </div>
      <RightOutlined />
    </div>
  );
};

export default StoreIssueGroup;
