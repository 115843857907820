import { FC } from 'react';
import { Navigate } from 'react-router-dom';

import { selectStore, selectUser } from 'src/redux/slices/auth';
import { useAppSelector } from 'src/redux/store';

import { useTitle } from '../hooks/useTitle';

import UserProfile from '../components/Profile/UserProfile';

const ProfilePage: FC = () => {
  useTitle('GU | 내 정보');
  const user = useAppSelector(selectUser);
  const store = useAppSelector(selectStore);

  return user && store ? <UserProfile user={user} store={store} /> : <Navigate to='/' replace />;
};

export default ProfilePage;
