import { StoreCategory } from '@gooduncles/gu-app-schema';
import { FC, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';

import useHomeConf from 'src/redux/hooks/useHomeConf';
import { selectStoreCategories } from 'src/redux/slices/home';
import { useAppSelector } from 'src/redux/store';

import Loading from 'src/components/Layout/Loading';
import StoreCategoryCheckboxList from 'src/components/molecules/StoreCategoryCheckboxList/StoreCategoryCheckboxList';

interface StoreCategorySelectorProps {
  selectedStoreCategory: string;
}

const StoreCategorySelector: FC<StoreCategorySelectorProps> = ({ selectedStoreCategory }) => {
  const navigate = useNavigate();
  const { onSetSelectedStoreCategory } = useHomeConf();
  const fetchedStoreCategories = useAppSelector(selectStoreCategories);

  const onChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>, value: string) => {
      if (e.target.checked) {
        onSetSelectedStoreCategory(value as StoreCategory);
        const htmlElement = document.documentElement;
        navigate(-1);
        setTimeout(() => {
          htmlElement.scrollTo({ top: 0, behavior: 'auto' });
        }, 100);
      }
    },
    [navigate, onSetSelectedStoreCategory]
  );

  if (fetchedStoreCategories.length === 0) return <Loading />;

  return (
    <StoreCategoryCheckboxList
      selectedStoreCategory={selectedStoreCategory}
      options={fetchedStoreCategories
        .sort((a, b) => (a.index !== b.index ? a.index - b.index : a.title.localeCompare(b.title)))
        .map((cg) => ({
          label: cg.title ?? '미정',
          value: cg.key,
        }))}
      onChange={onChange}
    />
  );
};

export default StoreCategorySelector;
