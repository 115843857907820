import { combineReducers } from '@reduxjs/toolkit';

// import { productApi } from '../apis/product';
import auth from './auth';
import cart from './cart';
import home from './home';
import order from './order';

const rootReducer = combineReducers({
  cart,
  auth,
  order,
  home,
  // [productApi.reducerPath]: productApi.reducer,
});

export default rootReducer;
