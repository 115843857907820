import { PayloadAction, createSlice } from '@reduxjs/toolkit';

import { AuthState } from '../models/authModel';
import { RootState } from '../store';

const initialState: AuthState = {
  isLoading: true,
  user: null,
  store: null,
  favoriteProducts: [],
};

const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    clearAuth: () => initialState,
    setIsLoading: (state, action: PayloadAction<boolean>) => {
      state.isLoading = action.payload;
    },
    setUser: (state, action: PayloadAction<AuthState['user']>) => {
      state.user = action.payload;
    },
    setStore: (state, action: PayloadAction<AuthState['store']>) => {
      state.store = action.payload;
    },
    setFavoriteProducts: (state, action: PayloadAction<AuthState['favoriteProducts']>) => {
      state.favoriteProducts = action.payload;
    },
  },
});

export const selectUser = (state: RootState) => state.auth.user;
export const selectUserId = (state: RootState) => state.auth.user?._id;
export const selectStore = (state: RootState) => state.auth.store;
export const selectStoreId = (state: RootState) => state.auth.user?.storeId;
export const selectStoreNickname = (state: RootState) => state.auth.store?.storeNickname;
export const selectUnhiddenProducts = (state: RootState) => state.auth.store?.unhiddenProducts ?? [];
export const selectFavoriteProducts = (state: RootState) => state.auth.favoriteProducts;
export const selectIsProductFavorite = (state: RootState, productId: string) =>
  state.auth.favoriteProducts.includes(productId);
export const selectIsLoading = (state: RootState) => state.auth.isLoading;

export const { clearAuth, setIsLoading, setUser, setStore, setFavoriteProducts } = userSlice.actions;

export default userSlice.reducer;
