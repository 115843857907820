import { Skeleton } from 'antd';
import { FC } from 'react';

import classes from './SkeletonSquareItem.module.scss';

const SkeletonSquareItem: FC = () => {
  return (
    <div className={classes.skeletonSquareItem}>
      <Skeleton.Image active className={classes.squareImg} />
      <Skeleton active paragraph={{ rows: 2 }} />
    </div>
  );
};

export default SkeletonSquareItem;
