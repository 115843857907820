import { FC } from 'react';

import classes from './VolumeDisplay.module.scss';

interface VolumeDisplayProps extends React.HTMLAttributes<HTMLDivElement> {
  volume: number;
}

/**
 * '주문완료'단계의 주문서 또는 장바구니에 담긴 상품의 수량을 보여줍니다.
 */
const VolumeDisplay: FC<VolumeDisplayProps> = ({ volume, className, ...props }) => {
  return (
    <div className={`${className} ${classes.volumeDisplay}`} {...props}>
      {volume}
    </div>
  );
};

export default VolumeDisplay;
