import { ProductDoc } from '@gooduncles/gu-app-schema';
import { FC, useCallback, useState } from 'react';

import EmptyImg from 'src/assets/images/empty.png';

const ProductImage: FC<{ product: ProductDoc }> = ({ product }) => {
  const [src, setSrc] = useState(product.thumbnail ?? product.imageUrl ?? EmptyImg);

  const onErrorCapture = useCallback(() => {
    setSrc(EmptyImg);
  }, [setSrc]);

  return (
    <img
      style={{
        width: '100%',
        height: '100%',
        objectFit: 'contain',
      }}
      onErrorCapture={onErrorCapture}
      src={src}
      alt={product.fullName}
    />
  );
};

export default ProductImage;
