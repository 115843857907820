import { FC } from 'react';

import FindPassword from '../components/FindPassword/FindPassword';
import { useTitle } from '../hooks/useTitle';

const FindPasswordPage: FC = () => {
  useTitle('GU | 비밀번호 찾기');
  return <FindPassword />
};

export default FindPasswordPage;
