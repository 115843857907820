import { Order, ProductBase } from '@gooduncles/gu-app-schema';

export enum GaEventType {
  /** 페이지 조회 */
  PAGE_VIEW = 'page_view',

  /** 주문생성 */
  ORDER_SUBMIT = 'order_submit',
  /** 주문수정 */
  ORDER_EDIT = 'order_edit',
  /** 주문수정취소 */
  ORDER_EDIT_CANCEL = 'order_edit_cancel',
  /** 주문수정완료 */
  ORDER_EDIT_SUBMIT = 'order_edit_submit',
  /** 주문취소 */
  ORDER_CANCEL = 'order_cancel',

  RESET_CART = 'reset_cart',
  ADD_TO_CART = 'add_to_cart',
  SUB_FROM_CART = 'sub_from_cart',
  REMOVE_FROM_CART = 'remove_from_cart',
  /** 상품의 수량을 조정한다. */
  SET_PRODUCT_VOLUME = 'set_product_volume',
  /** 장바구니에 상품을 일괄 등록한다. */
  SET_PRODUCTS = 'set_products',
  /** 복수의 상품을 장바구니에서 제거한다. */
  REMOVE_PRODUCTS_FROM_CART = 'remove_products_from_cart',

  /** 입력한 검색어와 검색된 결과의 수 */
  SEARCH_INPUT = 'search_input',
  /** 입력한 검색어로 상세 결과를 누른 이벤트 */
  SEARCH_SUBMIT = 'search_submit',
  /** 검색 미리보기에서 선택해서 발생한 이벤트 */
  SEARCH_SELECT_PREVIEW = 'search_select_preview',
  /** 최근 검색어 선택해서 발생한 이벤트 */
  SEARCH_SELECT_LATEST = 'search_select_latest',

  /** 페이지 더 보기 이벤트 */
  PAGE_SHOW_MORE = 'page_show_more',

  /** 즐겨찾기 */
  ADD_TO_FAVORITE = 'add_to_favorite',
  /** 즐겨찾기 해제 */
  REMOVE_FROM_FAVORITE = 'remove_from_favorite',

  /** 회원가입 */
  SIGNUP = 'signup',
  /** 로그인 */
  LOGIN = 'login',
  /** 로그아웃 */
  LOGOUT = 'logout',

  /** 설정변경 */
  SETTING = 'setting',

  /** 프로모션 */
  PROMOTION = 'promotion',
}

//////////////////////////////////////////////////////////////////////////////
// 페이지
//////////////////////////////////////////////////////////////////////////////

export interface PageViewEvent {
  name: GaEventType.PAGE_VIEW;
  eventParams: {
    page_location: string;
    page_referrer: string;
  };
}

//////////////////////////////////////////////////////////////////////////////
// 주문
//////////////////////////////////////////////////////////////////////////////

export interface OrderSubmitEvent {
  name: GaEventType.ORDER_SUBMIT;
  eventParams: Order & {
    orderId: string;
  };
}

export interface OrderCancelEvent {
  name: GaEventType.ORDER_CANCEL;
  eventParams: {
    orderId: string | null;
  };
}

export interface OrderEditEvent {
  name: GaEventType.ORDER_EDIT;
  eventParams: {
    orderId: string | null;
  };
}

export interface OrderEditSubmitEvent {
  name: GaEventType.ORDER_EDIT_SUBMIT;
  eventParams: {
    orderId: string | null;
  };
}

export interface OrderEditCancelEvent {
  name: GaEventType.ORDER_EDIT_CANCEL;
  eventParams: {
    orderId: string | null;
  };
}

//////////////////////////////////////////////////////////////////////////////
// 장바구니
//////////////////////////////////////////////////////////////////////////////

type CartEventParams = ProductBase & {
  volume: number;
};

export interface ResetCartEvent {
  name: GaEventType.RESET_CART;
  eventParams: {
    reason: string;
  };
}

export interface AddToCartEvent {
  name: GaEventType.ADD_TO_CART;
  eventParams: CartEventParams & {
    firstAdded: boolean;
  };
}

export interface SubFromCartEvent {
  name: GaEventType.SUB_FROM_CART;
  eventParams: CartEventParams;
}

export interface SetProductVolumeEvent {
  name: GaEventType.SET_PRODUCT_VOLUME;
  eventParams: CartEventParams;
}

export interface SetProductsEvent {
  name: GaEventType.SET_PRODUCTS;
  eventParams: {
    products: {
      productId: string;
      volume: number;
      price: number;
    }[];
  };
}

export interface RemoveFromCartEvent {
  name: GaEventType.REMOVE_FROM_CART;
  eventParams: CartEventParams;
}

export interface RemoveProductsFromCartEvent {
  name: GaEventType.REMOVE_PRODUCTS_FROM_CART;
  eventParams: {
    products: CartEventParams[];
  };
}

//////////////////////////////////////////////////////////////////////////////
// 검색
//////////////////////////////////////////////////////////////////////////////

export interface SearchInputEvent {
  name: GaEventType.SEARCH_INPUT;
  eventParams: {
    query: string;
    hits: number;
  };
}

export interface SearchSubmitEvent {
  name: GaEventType.SEARCH_SUBMIT;
  eventParams: {
    query: string;
  };
}

export interface SearchSelectPreviewEvent {
  name: GaEventType.SEARCH_SELECT_PREVIEW;
  eventParams: {
    query: string;
  };
}

export interface SearchSelectLatestEvent {
  name: GaEventType.SEARCH_SELECT_LATEST;
  eventParams: {
    query: string;
  };
}

//////////////////////////////////////////////////////////////////////////////
// 회원가입 & 로그인
//////////////////////////////////////////////////////////////////////////////

export interface SignUpEvent {
  name: GaEventType.SIGNUP;
  eventParams: {
    hasStoreInfo: boolean;
  };
}

export interface LoginEvent {
  name: GaEventType.LOGIN;
  eventParams: {
    email: string;
  };
}

export interface LogoutEvent {
  name: GaEventType.LOGOUT;
  eventParams: {
    email: string;
  };
}

//////////////////////////////////////////////////////////////////////////////
// 설정
//////////////////////////////////////////////////////////////////////////////

export interface SettingEvent {
  name: GaEventType.SETTING;
  eventParams: {
    /** 설정한 항목 */
    key: string;
    /** 설정한 값 */
    value: any;
  };
}

//////////////////////////////////////////////////////////////////////////////
// 페이지
//////////////////////////////////////////////////////////////////////////////

export interface PageShowMoreEvent {
  name: GaEventType.PAGE_SHOW_MORE;
  eventParams: {
    /** 페이지 주제 - 검색, 카테고리 등 */
    title: string;
    query: string;
    goToPage: number;
  };
}

//////////////////////////////////////////////////////////////////////////////
// 프로모션
//////////////////////////////////////////////////////////////////////////////

export interface PromotionEvent {
  name: GaEventType.PROMOTION;
  eventParams: {
    /** 프로모션 주제 */
    title: string;
    /** 프로모션 상품에서 어떤 이벤트를 발생시켰는가? */
    trigger:
      | 'add_to_cart'
      | 'sub_from_cart'
      | 'remove_from_cart'
      | 'change_volume'
      | 'add_to_favorite'
      | 'remove_from_favorite';
  };
}

//////////////////////////////////////////////////////////////////////////////
// 즐겨찾기
//////////////////////////////////////////////////////////////////////////////

export interface AddToFavoriteEvent {
  name: GaEventType.ADD_TO_FAVORITE;
  eventParams: {
    /** 상품 아이디 */
    productId: string;
    /** 상품 이름 */
    productName: string;
  };
}

export interface RemoveFromFavoriteEvent {
  name: GaEventType.REMOVE_FROM_FAVORITE;
  eventParams: {
    /** 상품 아이디 */
    productId: string;
    /** 상품 이름 */
    productName: string;
  };
}

//////////////////////////////////////////////////////////////////////////////
// 공통
//////////////////////////////////////////////////////////////////////////////

export type GaEvent =
  | PageViewEvent
  | OrderSubmitEvent
  | OrderCancelEvent
  | OrderEditEvent
  | OrderEditSubmitEvent
  | OrderEditCancelEvent
  | ResetCartEvent
  | AddToCartEvent
  | SubFromCartEvent
  | SetProductVolumeEvent
  | SetProductsEvent
  | RemoveFromCartEvent
  | RemoveProductsFromCartEvent
  | SearchInputEvent
  | SearchSubmitEvent
  | SearchSelectPreviewEvent
  | SearchSelectLatestEvent
  | SignUpEvent
  | LoginEvent
  | LogoutEvent
  | SettingEvent
  | PageShowMoreEvent
  | PromotionEvent
  | AddToFavoriteEvent
  | RemoveFromFavoriteEvent;
