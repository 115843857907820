import { FC, useCallback, useState } from 'react';

import FallbackImg from 'src/assets/images/fallback.png';

import classes from './ImageWithFallback.module.scss';

const ImageWithFallback: FC<React.ImgHTMLAttributes<HTMLImageElement>> = ({ src, ...props }) => {
  const [imageUrl, setImageUrl] = useState(src ?? FallbackImg);

  const onErrorCapture = useCallback(() => {
    setImageUrl(FallbackImg);
  }, [setImageUrl]);

  return <img {...props} src={imageUrl} loading='lazy' onErrorCapture={onErrorCapture} className={classes.image} />;
};

export default ImageWithFallback;
