import { UserDoc } from '@gooduncles/gu-app-schema';

import { LocalStorageKey } from '../1/constant';
import { getLocalStorageValue, sessionId } from '../1/util';
import { FirebaseManager } from '../2/firebase-manager';
import { DeviceInfo } from '../3/schema-app';
import { Log, LogCommerce, LogLevel } from '../3/schema-log';

const firebaseManager = FirebaseManager.getInstance();
const logCommercePath = 'logCommerce';

export class CommerceLogger {
  private static instance: CommerceLogger;
  private user: UserDoc | null;
  private publicIp: string;

  private constructor() {
    this.publicIp = 'unknown';
    this.user = null;
  }

  public static getInstance() {
    if (CommerceLogger.instance === undefined) {
      CommerceLogger.instance = new CommerceLogger();
    }
    return CommerceLogger.instance;
  }

  public setLoggerUser(user: UserDoc | null) {
    this.user = user;
  }

  public setPublicIp(ip: string) {
    this.publicIp = ip;
  }

  public getPublicIp() {
    return this.publicIp;
  }

  public logCommerce(
    message: string,
    option?: {
      level?: LogLevel;
      channel?: '4-앱-알림' | '4-앱-고객요청' | 'dev';
      extraInfo?: Partial<Log<LogCommerce>['body']>;
      showBody?: boolean;
    }
  ) {
    const deviceInfo = getLocalStorageValue<DeviceInfo>(LocalStorageKey.deviceInfo);
    const path = window.location.pathname;
    const { level, channel, extraInfo, showBody } = option || {};

    const log: Log<LogCommerce> = {
      level: level ?? 'log',
      sessionId: sessionId(),
      publicIp: this.publicIp,
      message,
      slack: {
        channel,
        showBody,
      },
      body: {
        path,
        deviceId: deviceInfo?.tokenId ?? 'unknown',
        user: this.user,
        orderId: extraInfo?.orderId ?? null,
      },
    };

    if (!channel && !showBody) {
      delete log.slack;
    }

    return firebaseManager.createDoc(logCommercePath, undefined, log);
    // return isLocal ? console.log(log.message) : firebaseManager.createDoc(logCommercePath, undefined, log);
  }
}
