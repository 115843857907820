import { StoreCategory } from '@gooduncles/gu-app-schema';
import { FC } from 'react';

import { ProductGroupForTagSubset } from 'src/lib/3/schema-product-group-for-category-subset';

import ProductListForMainTag from '../ProductListForMainTag/ProductListForMainTag';
import classes from './MainTagListForStoreCategory.module.scss';

interface MainTagListForStoreCategoryProps {
  storeCategory: StoreCategory;
  mainTags: ProductGroupForTagSubset[];
}

const MainTagListForStoreCategory: FC<MainTagListForStoreCategoryProps> = ({ storeCategory, mainTags }) => {
  return (
    <div key={storeCategory} className={classes.mainTagList}>
      {mainTags.map(({ mainTag, products }) => (
        <ProductListForMainTag key={mainTag} mainTag={mainTag} productIds={products} />
      ))}
    </div>
  );
};

export default MainTagListForStoreCategory;
