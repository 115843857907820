import { FC } from 'react';

import { formatNumber } from 'src/lib/1/util';

import classes from './Price.module.scss';

interface PriceProps {
  price: number;
  size?: 'small' | 'medium' | 'large';
}

const Price: FC<PriceProps> = ({ price, size = 'medium' }) => (
  <p className={`${classes.price} ${classes[size]}`}>{formatNumber(price)}원</p>
);

export default Price;
