import useSWRImmutable from 'swr/immutable';

import { getNotificationDoc } from 'src/lib/3/firebase-short-cut';

const useNotificationById = (id: string | null) => {
  const { data: notification, isValidating } = useSWRImmutable(id, getNotificationDoc);

  return {
    notification,
    isValidating,
  };
};

export default useNotificationById;
