import { OrderDoc } from '@gooduncles/gu-app-schema';

import { MobileAlert } from '../1/util';
import { cancelOrder } from '../3/firebase-short-cut';
import { CommerceLogger } from '../4/logger';

const logger = CommerceLogger.getInstance();

/**
 * 주문을 취소하는 경우에는 장바구니에 상품을 남겨야한다.
 * @param orderId
 */
export const onCancelOrder = async (order: OrderDoc, storeName: string, resetCart?: boolean) => {
  try {
    logger.logCommerce(`주문취소 - 업소명: ${storeName}, 취소금액: ${order.paidAmount}`, {
      extraInfo: {
        orderId: order._id,
      },
    });
    await cancelOrder(order._id);
    MobileAlert('주문취소 완료', resetCart ? undefined : '취소된 상품을 장바구니로 옮겼습니다.');
  } catch (error: any) {
    logger.logCommerce(`주문취소 실패 - 업소명: ${storeName}, orderId: ${order._id}`, {
      level: 'error',
    });
    MobileAlert('주문취소 실패', error.message);
  }
};
