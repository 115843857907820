import { PayloadAction, createSlice } from '@reduxjs/toolkit';

import { ProductGroupForCategorySubset } from 'src/lib/3/schema-product-group-for-category-subset';

import { HomeState } from '../models/homeModel';
import { RootState } from '../store';

const initialState: HomeState = {
  isMenuOpen: false,
  selectedStoreCategory: null,
  productGroupForCategories: {},
  promotionSections: [],
  customPromotionSections: [],
};

const homeSlice = createSlice({
  name: 'home',
  initialState,
  reducers: {
    setProductGroupForCategories(
      state,
      action: PayloadAction<{ category: string; subset: ProductGroupForCategorySubset }>
    ) {
      state.productGroupForCategories = {
        ...state.productGroupForCategories,
        [action.payload.category]: {
          data: action.payload.subset,
        },
      };
    },
    setSelectedStoreCategory(state, action) {
      state.selectedStoreCategory = action.payload;
    },
    setIsMenuOpen(state, action) {
      state.isMenuOpen = action.payload;
    },
    setPromotionSections(state, action) {
      state.promotionSections = action.payload;
    },
    setCustomPromotionSections(state, action) {
      state.customPromotionSections = action.payload;
    },
  },
});

export const selectSelectedStoreCategory = (state: RootState) => state.home.selectedStoreCategory;
export const selectStoreCategories = (state: RootState) => {
  const { productGroupForCategories } = state.home;
  const categories = productGroupForCategories
    ? Object.entries(productGroupForCategories)
        .filter(([, v]) => v !== null)
        .map(([key, value]) => {
          const { data } = value;
          return {
            key,
            emoji: data?.emoji ?? '',
            title: data?.title ?? '',
            index: data?.index ?? 6,
          };
        })
    : [];
  return (
    categories
      // TODO: 임시 변경
      .filter((category) => !['baekban_guksu', 'zzim_tang_jjigae', 'jokbal', 'barbeque'].includes(category.key))
  );
};
export const selectProductGroupForCategory = (state: RootState, category: string) =>
  state.home.productGroupForCategories?.[category];
export const selectIsMenuOpen = (state: RootState) => state.home.isMenuOpen;
export const selectPromotionSections = (state: RootState) => {
  if (state.home.selectedStoreCategory) {
    return (state.home.promotionSections ?? [])?.filter(
      (promotion) => promotion.storeCategory === null || promotion.storeCategory === state.home.selectedStoreCategory
    );
  }
  return state.home.promotionSections ?? [];
};
export const selectPromotionSection = (state: RootState, id?: string) =>
  id ? state.home.promotionSections.find((v) => v._id === id) : undefined;
export const selectCustomPromotionSections = (state: RootState) => state.home.customPromotionSections ?? [];
export const selectCustomPromotionSection = (state: RootState, id?: string) =>
  id ? state.home.customPromotionSections.find((v) => v._id === id) : undefined;

export const {
  setSelectedStoreCategory,
  setProductGroupForCategories,
  setIsMenuOpen,
  setPromotionSections,
  setCustomPromotionSections,
} = homeSlice.actions;

export default homeSlice.reducer;
