import { FC, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { GaEventType } from 'src/schema/schema-ga-event';

import { LocalStorageKey } from 'src/lib/1/constant';
import {
  observeLocalStorageValue,
  removeLocalStorageItem,
  saveLatestSearchKeyword,
  setLocalStorageValue,
} from 'src/lib/1/util';
import { sendGaEvent } from 'src/lib/3/firebase-short-cut';

import classes from './LatestSearchKeywords.module.scss';

const LatestSearchKeywords: FC = () => {
  const navigate = useNavigate();
  const [latestSearchKeywords, setLatestSearchKeywords] = useState<string[]>([]);

  const goToSearchResultPage = (query: string) => {
    saveLatestSearchKeyword(query);
    sendGaEvent({
      name: GaEventType.SEARCH_SELECT_LATEST,
      eventParams: {
        query,
      },
    });
    navigate(`/search/result?q=${encodeURIComponent(query)}`);
  };

  const onClickDeleteKeyword = (keyword: string) => {
    const index = latestSearchKeywords.findIndex((value) => value === keyword);
    if (index !== -1) {
      latestSearchKeywords.splice(index, 1);
    }
    setLocalStorageValue(LocalStorageKey.latestSearchKeywords, latestSearchKeywords);
  };

  useEffect(() => {
    const subscription = observeLocalStorageValue<string[]>(LocalStorageKey.latestSearchKeywords).subscribe((value) => {
      setLatestSearchKeywords(value ?? []);
    });
    window.dispatchEvent(new Event('storage'));

    return () => {
      subscription.unsubscribe();
    };
  }, []);

  return latestSearchKeywords.length > 0 ? (
    <div className={classes.latestSearchKeywords}>
      <div className={classes.top}>
        <h3>최근 검색어</h3>
        <button
          className={classes.roundButton}
          onClick={() => removeLocalStorageItem(LocalStorageKey.latestSearchKeywords)}>
          전체삭제
        </button>
      </div>
      <div className={classes.bottom}>
        {latestSearchKeywords.map((keyword) => (
          <button key={keyword} className={classes.roundButton} onClick={() => goToSearchResultPage(keyword)}>
            {keyword}
            <span
              className={classes.deleteKeyword}
              onClick={(e) => {
                e.stopPropagation();
                onClickDeleteKeyword(keyword);
              }}>
              ✕
            </span>
          </button>
        ))}
      </div>
    </div>
  ) : null;
};

export default LatestSearchKeywords;
