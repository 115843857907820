import { ButtonHTMLAttributes, FC, PropsWithChildren } from 'react';

import classes from './Button.module.scss';

interface RectButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  label: string | JSX.Element;
}

const RectButton: FC<PropsWithChildren<RectButtonProps>> = ({ label, children, ...props }) => {
  return (
    <button {...props} className={classes.rectButton}>
      <span>{label}</span>
      {children}
    </button>
  );
};

export default RectButton;
