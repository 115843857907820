import { DownloadOutlined } from '@ant-design/icons';
import { Button, message } from 'antd';
import html2canvas from 'html2canvas';
import { FC, useCallback, useState } from 'react';

import { dateFormat08, dateFormat09, dateFormat12 } from 'src/lib/1/date-util';
import { downloadFromUrl, numberFormat } from 'src/lib/1/util';
import { InstallmentBillDoc } from 'src/lib/3/schema-bill';

import classes from './Bill.module.scss';

type BillProps = {
  bill: InstallmentBillDoc;
  noMessage?: boolean;
};

const Bill: FC<BillProps> = ({ bill, noMessage }) => {
  const [ref, setRef] = useState<HTMLDivElement | null>();
  const date = new Date(bill.settlementDate);
  const dateForTitle = dateFormat08(date);
  const dateForFileName = dateFormat09(date);
  const details = bill.detail.filter((detail) => detail.totalAmount !== 0);
  const warn = bill.prevUnpaidAmount > bill.settlementAmount;
  const sumDetails = details.reduce(
    (acc, cur) => {
      return {
        supplyAmount: acc.supplyAmount + cur.supplyAmount,
        tax: acc.tax + cur.tax,
        totalAmount: acc.totalAmount + cur.totalAmount,
      };
    },
    { supplyAmount: 0, tax: 0, totalAmount: 0 }
  );
  const cmsComment = bill.settlementType.includes('CMS') ? ' (금일 오후 8시 이후 출금 예정)' : '';

  const downloadBillImage = useCallback(async () => {
    if (!ref) {
      message.error('Element에 대한 참조가 없습니다.');
      return;
    }

    try {
      const canvas = await html2canvas(ref, {
        width: ref.offsetWidth,
        height: ref.offsetHeight,
        scale: 2,
        useCORS: true,
      });
      const dataURL = canvas.toDataURL('image/png');
      downloadFromUrl(dataURL, `${bill.storeName}-${dateForFileName}.png`);
    } catch (error: any) {
      message.error(error.message);
    }
  }, [bill.storeName, dateForFileName, ref]);

  return (
    <>
      <Button
        onClick={downloadBillImage}
        icon={<DownloadOutlined style={{ fontSize: 18 }} />}
        className={classes.topButton}
      />
      <div className={classes.billImageContainer} ref={setRef}>
        <div className={`${classes.top} ${warn ? classes.warn : ''}`}>
          {warn ? '⚠️ ' : ''}
          {dateForTitle} 정산 내역
        </div>
        <div className={classes.summary}>
          <div className={classes.item}>
            <div className={classes.label}>고객명 :</div>
            <div>{bill.storeName}</div>
          </div>

          <div className={classes.item}>
            <div className={classes.label}>정산금액 :</div>
            <div className={classes.settlementAmount}>
              {numberFormat(bill.unpaidAmount)}
              <span>원</span>
            </div>
          </div>

          <div className={classes.item}>
            <div className={classes.label}>정산기간 :</div>
            <div>
              {bill.detail[0].date} ~ {bill.detail[bill.detail.length - 1].date}
            </div>
          </div>

          <div className={classes.item}>
            <div className={classes.label}>정산방식 :</div>
            <div>
              {bill.settlementType}
              {cmsComment}
            </div>
          </div>
        </div>
        <>
          <h2>상세내역</h2>
          <div className={classes.details}>
            <div className={`${classes.detailItem} ${classes.header}`}>
              <div>일</div>
              <div>공급가</div>
              <div>세액</div>
              <div>총 금액</div>
            </div>
            {details.length > 0 &&
              details.map((detail, idx) => (
                <div key={bill.storeCode + detail.date + idx} className={classes.detailItem}>
                  <div>{dateFormat12(detail.date)}</div>
                  <div className={classes.price}>{numberFormat(detail.supplyAmount)}</div>
                  <div className={classes.price}>{numberFormat(detail.tax)}</div>
                  <div className={classes.total}>{numberFormat(detail.totalAmount)}</div>
                </div>
              ))}
            <div className={`${classes.detailItem} ${classes.sum}`}>
              <div>합계</div>
              <div className={classes.price}>{numberFormat(sumDetails.supplyAmount)}</div>
              <div className={classes.price}>{numberFormat(sumDetails.tax)}</div>
              <div className={classes.total}>{numberFormat(sumDetails.totalAmount)}</div>
            </div>

            {bill.prevUnpaidAmount > 0 && (
              <div className={`${classes.detailItem} ${classes.attention}`}>
                <div>이전 미수금{`(+)`}</div>
                <div className={classes.price}>{numberFormat(bill.prevUnpaidAmount)}</div>
              </div>
            )}
            {bill.settlementAmount !== 0 && (
              <div className={`${classes.detailItem} ${classes.paid}`}>
                <div>금주 정산액{`(-)`}</div>
                <div className={classes.price}>{numberFormat(bill.settlementAmount)}</div>
              </div>
            )}
            <div className={`${classes.detailItem} ${classes.total}`}>
              <div>총 미정산액</div>
              <div className={classes.price}>{numberFormat(bill.unpaidAmount)}</div>
            </div>
          </div>
        </>
        {!noMessage && <p className={classes.message}>{bill.message}</p>}
      </div>
    </>
  );
};

export default Bill;
