import { FC } from 'react';

import IconSearchBlack from 'src/assets/icons/search-black.svg';

import classes from './PreviewItem.module.scss';

interface PreviewItemProps extends React.AnchorHTMLAttributes<HTMLAnchorElement> {
  value: string | undefined;
}

const PreviewItem: FC<PreviewItemProps> = ({ value, ...props }) => {
  if (!value) {
    return null;
  }
  return (
    <a {...props} className={classes.previewItem}>
      <img src={IconSearchBlack} alt='search-icon' />
      <span dangerouslySetInnerHTML={{ __html: value }} />
    </a>
  );
};

export default PreviewItem;
