import { FC } from 'react';
import { Navigate, useSearchParams } from 'react-router-dom';

import { useTitle } from 'src/hooks/useTitle';

import SearchResult from 'src/components/SearchResult/SearchResult';

const SearchResultPage: FC = () => {
  useTitle('GU | 검색결과');
  const [searchParams] = useSearchParams();
  const query = searchParams.get('q');

  return query ? <SearchResult keyword={query} /> : <Navigate to='/' replace />;
};

export default SearchResultPage;
