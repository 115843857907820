import { FC } from 'react';

import { useTitle } from '../hooks/useTitle';

const NotFoundPage: FC = () => {
  useTitle('GU | 페이지를 찾을 수 없습니다.');
  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        height: '100%',
      }}>
      <h1>404</h1>
      <p>페이지를 찾을 수 없습니다.</p>
    </div>
  );
};

export default NotFoundPage;
