/**
 * {@link https://www.algolia.com/doc/api-client/getting-started/install/javascript/?client=javascript | Algolia Doc}
 */
import algoliasearch from 'algoliasearch';

import { isProduction } from 'src/lib/1/constant';

const applicationId = isProduction ? 'ESJZE44DAV' : 'DNH45MYQTF';
const searchApiKey = isProduction ? '9ff539f22ea2ea850f2b8d448f7381b4' : '2764bcfedffe33f7edeba86548d543eb';
export const searchClient = algoliasearch(applicationId, searchApiKey);

/**
 * 검색 정렬과 Algolia 인덱스를 매핑한다.
 */
export const sortKeyMappings: Record<
  string,
  {
    sortKey: string;
    orderBy: 'asc' | 'desc';
  }
> = {
  기본순: {
    sortKey: 'fullName',
    orderBy: 'asc',
  },
  판매량순: {
    sortKey: 'sales',
    orderBy: 'desc',
  },
  낮은단가순: {
    sortKey: 'unitprice',
    orderBy: 'asc',
  },
  낮은가격순: {
    sortKey: 'price',
    orderBy: 'asc',
  },
  높은가격순: {
    sortKey: 'price',
    orderBy: 'desc',
  },
};

/**
 * 정렬키에 따른 Algolia 인덱스를 반환한다.
 */
export const getAlgoliaIndex = (sortKeyKr?: string) => {
  const prefix = 'product';
  if (!sortKeyKr) return searchClient.initIndex(prefix);

  const suffix =
    sortKeyMappings[sortKeyKr].sortKey !== 'fullName'
      ? `${sortKeyMappings[sortKeyKr]?.sortKey}_${sortKeyMappings[sortKeyKr]?.orderBy}`
      : undefined;

  return suffix ? searchClient.initIndex(`${prefix}_${suffix}`) : searchClient.initIndex(prefix);
};
