import { FC, PropsWithChildren } from 'react';

import IconArrowRight from 'src/assets/icons/arrow-right.svg';

import classes from './Button.module.scss';

interface GoToPageButtonProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  label: string | JSX.Element;
  size: 'small' | 'medium' | 'large';
}

const GoToPageButton: FC<PropsWithChildren<GoToPageButtonProps>> = ({ label, size, children, ...props }) => {
  return (
    <button {...props} className={`${classes.goToPageButton} ${classes[size]}`}>
      <span>{label}</span>
      {children}
      {size === 'large' && <div className={classes.spacer} />}
      <img src={IconArrowRight} alt='arrow right' />
    </button>
  );
};

export default GoToPageButton;
