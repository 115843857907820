import { FC } from 'react';

import { selectOnEditOrder, selectSubmiited } from 'src/redux/slices/order';
import { useAppSelector } from 'src/redux/store';

import { useTitle } from 'src/hooks/useTitle';

import Order from 'src/components/Order/Order';
import ShoppingCart from 'src/components/ShoppingCart/ShoppingCart';

const ShoppingCartPage: FC = () => {
  const onEditOrder = useAppSelector(selectOnEditOrder);
  const submittedOrder = useAppSelector(selectSubmiited);

  useTitle(`GU | ${onEditOrder === false && submittedOrder ? '주문목록' : '장바구니'}`);
  return onEditOrder === false && submittedOrder ? <Order order={submittedOrder} /> : <ShoppingCart />;
};

export default ShoppingCartPage;
