import { UserOutlined } from '@ant-design/icons';
import { Button, Form, Input } from 'antd';
import { Dialog } from 'antd-mobile';
import { FC, useCallback } from 'react';
import { Link } from 'react-router-dom';
import { GaEventType } from 'src/schema/schema-ga-event';

import { requiredRule } from '../../lib/1/string-map';
import { FIREBASE_AUTH_ERROR_CODE, FIREBASE_AUTH_ERROR_CODE_FOR_ALERT, kakaoTalkUrl } from 'src/lib/1/constant';
import { normalizeEmail } from 'src/lib/1/util';
import { sendGaEvent } from 'src/lib/3/firebase-short-cut';
import { CommerceLogger } from 'src/lib/4/logger';

import useAuth from 'src/redux/hooks/useAuth';
import { selectIsLoading } from 'src/redux/slices/auth';
import { useAppSelector } from 'src/redux/store';

import ChatIcon from '../../assets/icons/chat.svg';

import TopNavigationBar from '../Common/TopNavigationBar/TopNavigationBar';
import classes from './Signin.module.scss';

const logger = CommerceLogger.getInstance();
const { Item } = Form;

type SigninFormValues = {
  email: string;
  password: string;
};

const openKakaoChat = () => window.open(kakaoTalkUrl, '_blank');

const Signin: FC = () => {
  const isLoading = useAppSelector(selectIsLoading);
  const { onLogin, onSetAuthLoading } = useAuth();

  const onFinish = useCallback(
    async (values: SigninFormValues) => {
      const { email, password } = values;
      try {
        await onLogin(email, password);
        sendGaEvent({
          name: GaEventType.LOGIN,
          eventParams: { email },
        });
      } catch (error: any) {
        onSetAuthLoading(false);
        const code: string = error.code ?? 'unknown';
        const content = FIREBASE_AUTH_ERROR_CODE[code];
        if (FIREBASE_AUTH_ERROR_CODE_FOR_ALERT.includes(code)) {
          logger.logCommerce(`로그인에 문제가 생긴 사용자가 있습니다.\nreason: ${content}`, {
            channel: '4-앱-알림',
          });
        }
        Dialog.alert({
          title: '로그인 실패',
          content,
          confirmText: '확인',
          bodyStyle: {
            wordBreak: 'keep-all',
            whiteSpace: 'pre-line',
            textAlign: 'center',
          },
        });
      }
    },
    [onLogin, onSetAuthLoading]
  );

  return (
    <div className={classes.signinContainer} style={{ minHeight: `${window.innerHeight}px` }}>
      <TopNavigationBar title='로그인' leftButton={true} />
      <div className={classes.signinContent}>
        <Form id='signin' onFinish={onFinish} size='large'>
          <Item
            name='email'
            label='이메일'
            rules={[...requiredRule, { type: 'email', message: '이메일 형식이 올바르지 않습니다.' }]}
            normalize={normalizeEmail}>
            <Input />
          </Item>
          <Item name='password' label='비밀번호' rules={requiredRule}>
            <Input.Password minLength={6} />
          </Item>

          <Item>
            <Button
              type='primary'
              icon={<UserOutlined />}
              className={classes.submitSigninButton}
              loading={isLoading}
              htmlType='submit'
              block>
              로그인
            </Button>
          </Item>
          <div className={classes.buttons}>
            <Link to='/signup'>회원가입</Link>
            <span>
              <Link to='/find-email'>이메일 찾기</Link> | <Link to='/find-password'>비밀번호 찾기</Link>
            </span>
          </div>
        </Form>
      </div>
      <div className={classes.cs}>
        <div className={classes.csTop}>
          <span>고객센터</span>
          <Button
            className={classes.kakaoLink}
            onClick={openKakaoChat}
            icon={<img src={ChatIcon} alt='이웃삼촌 카카오톡 링크' />}>
            카카오 상담톡
          </Button>
        </div>
        <p>
          평일 오전9시 ~ 오후11시
          <br />
          주말/공휴일 휴무
          <br />
          Tel: 1533-2537
        </p>
      </div>
    </div>
  );
};

export default Signin;
