import { PromotionSection } from '@gooduncles/gu-app-schema';
import { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import useOnScreen from 'src/hooks/useOnScreen';
import useProductsByIds from 'src/hooks/useProductsByIds';

import IconArrowRight from 'src/assets/icons/arrow-right.svg';

import RectButton from 'src/components/atoms/Button/RectButton';
import TextButton from 'src/components/atoms/Button/TextButton';
import Text from 'src/components/atoms/Text/Text';
import Title from 'src/components/atoms/Title/Title';
import GridProductList from 'src/components/organisms/ProductList/GridProductList/GridProductList';
import HorizontalProductList from 'src/components/organisms/ProductList/HorizontalProductList/HorizontalProductList';

import classes from './ProductListForPromotion.module.scss';

interface ProductListForPromotionProps {
  promotionSection: PromotionSection & { _id: string; storeId?: string };
}

const ProductListForPromotion: FC<ProductListForPromotionProps> = ({ promotionSection }) => {
  const navigate = useNavigate();
  const [ref, setRef] = useState<HTMLDivElement | null>(null);
  const isVisible = useOnScreen(ref);
  const [isLoaded, setIsLoaded] = useState(false);
  const productIds = useMemo(
    () => promotionSection.items.map((i) => i.id).slice(0, promotionSection.maxItemsToPreview),
    [promotionSection.items, promotionSection.maxItemsToPreview]
  );
  const { products, productsLoading } = useProductsByIds(isLoaded ? productIds : undefined);
  const productsWithPromotion = products.map((p) => ({ ...p, promotion: promotionSection.title }));
  const hasMore = promotionSection.items.length > promotionSection.maxItemsToPreview;

  const goToPromotionPage = useCallback(() => {
    const htmlElement = document.documentElement;
    if (promotionSection.storeId) {
      navigate(`/custom-promotion/${promotionSection._id}`);
    } else {
      navigate(`/promotion/${promotionSection._id}`);
    }
    htmlElement.scrollTo({ top: 0, behavior: 'auto' });
  }, [navigate, promotionSection._id, promotionSection.storeId]);

  useEffect(() => {
    if (isVisible && !isLoaded) {
      setIsLoaded(true);
    }
  }, [isLoaded, isVisible]);

  return (
    <div ref={setRef}>
      <div className='promotionTitlePadding'>
        <div className={classes.flexRow}>
          <Title>{promotionSection.title}</Title>
          {hasMore && promotionSection.direction === 'horizontal' && (
            <TextButton label='더 보기' color='gray' onClick={goToPromotionPage}>
              <img src={IconArrowRight} alt='arrow right' />
            </TextButton>
          )}
        </div>
        <Text style={{ color: 'var(--gray600)', paddingTop: 6, fontSize: 13 }}>{promotionSection.description}</Text>
      </div>
      {promotionSection.direction === 'horizontal' ? (
        <HorizontalProductList showBadge products={productsWithPromotion} loading={!isLoaded || productsLoading} />
      ) : (
        <GridProductList
          showBadge
          products={productsWithPromotion}
          loading={!isLoaded || productsLoading}
          style={{ paddingTop: 0 }}
        />
      )}
      {hasMore && promotionSection.direction === 'vertical' && (
        <div className={classes.more}>
          <RectButton
            label={
              <>
                <b>{promotionSection.title}</b> 더 보기
              </>
            }
            onClick={goToPromotionPage}>
            <img src={IconArrowRight} alt='arrow right' />
          </RectButton>
        </div>
      )}
    </div>
  );
};

export default ProductListForPromotion;
