import { isProduction } from './constant';

export const imageBaseUrl = isProduction
  ? 'https://firebasestorage.googleapis.com/v0/b/gooduncles-10f09.appspot.com/o/gu-files'
  : 'https://firebasestorage.googleapis.com/v0/b/gooduncles-dev.appspot.com/o/gu-files';

/** 상품 - 종목 */
export const productCategories: { [key: string]: string } = {
  all: '전체',
  preCutVegetable: '전처리채소',
  vegetable: '채소',
  fruit: '과일',
  grain: '쌀/곡물류',
  livestock: '축산/계란',
  processed: '가공식품',
  marine: '수산/건어물',
  grocery: '잡화',
};

/** with 이모지(iOS 앱 소개 스크린샷에 이모지를 넣기 위해서는 앱에도 넣어야한다...) */
export const productCategoriesWithEmoji: { [key: string]: string } = {
  all: '전체',
  preCutVegetable: '🥗 전처리채소',
  vegetable: '🥕 채소',
  fruit: '🍎 과일',
  grain: '🍚 쌀/곡물류',
  livestock: '🥚 축산/계란',
  processed: '🌭 가공식품',
  marine: '🐟 수산/건어물',
  grocery: '🧃 잡화',
};

/** 상품 - 종목(Kr to En) */
export const productCategoriesForKr: { [key: string]: string } = {
  맞춤상품: 'custom',
  채소: 'vegetable',
  과일: 'fruit',
  '쌀/곡물류': 'grain',
  '축산/계란': 'livestock',
  가공식품: 'processed',
  '수산/건어물': 'marine',
  잡화: 'grocery',
  전처리채소: 'preCutVegetable',
};

export type ProductCategory = keyof typeof productCategories;

export const requiredRule = [{ required: true, message: '필수 항목입니다!' }];

export const orderStatusKr = {
  /** SUBMITTED */
  10: '✅ 주문완료',
  /** ACCEPTED */
  20: '🚛 배송진행',
  /** DELIVERED */
  50: '배송완료',
  /** CANCELED */
  60: '주문취소',
  /** REJECTED */
  70: '취소된 주문',
};

export const orderStatusEn = {
  10: 'submitted',
  20: 'accepted',
  50: 'delivered',
  60: 'canceled',
  70: 'rejected',
};

export const editOrderErrors = {
  signup: '로그인이 필요합니다.',
  onEdit: '주문 수정 모드에서 수정할 수 있습니다.\n주문 수정 모드로 변경하시겠습니까?',
};
