import { ProductDoc } from '@gooduncles/gu-app-schema';
import { FC } from 'react';

import ProductSquareItem from 'src/components/organisms/ProductItem/ProductSquareItem';

import SkeletonSquareItem from '../../ProductItem/SkeletonSquareItem/SkeletonSquareItem';
import classes from './HorizontalProductList.module.scss';

interface HorizontalProductListProps {
  products: ProductDoc[];
  loading: boolean;
  showBadge?: boolean;
}

const HorizontalProductList: FC<HorizontalProductListProps> = ({ products, loading, showBadge }) => {
  return (
    <div className={classes.horizontalProductList}>
      {loading
        ? [...Array(3)].map((_, index) => <SkeletonSquareItem key={index} />)
        : products.map((product) => (
            <ProductSquareItem key={product._id} product={product} horizontal showBadge={showBadge} />
          ))}
    </div>
  );
};

export default HorizontalProductList;
