import { FC } from 'react';

import { useTitle } from '../hooks/useTitle';

import StoreIssueDetail from 'src/components/StoreIssueDetail/StoreIssueDetail';

const StoreIssueDetailPage: FC = () => {
  useTitle('GU | 특이사항');
  return <StoreIssueDetail />;
};

export default StoreIssueDetailPage;
