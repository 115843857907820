import React from 'react';

import IconNext from 'src/assets/icons/next.svg';

import classes from './ExpandCollapseIcon.module.scss';

interface ExpandCollapseIconProps {
  expanded: boolean;
}

const ExpandCollapseIcon: React.FC<ExpandCollapseIconProps> = ({ expanded }) => {
  return (
    <div className={classes.middleIconBox}>
      <img src={IconNext} alt='expand-collapse' className={`${expanded ? classes.expanded : ''}`} />
    </div>
  );
};

export default ExpandCollapseIcon;
