import { EditOutlined } from '@ant-design/icons';
import { ProductDoc } from '@gooduncles/gu-app-schema';
import { InputNumber, Modal } from 'antd';
import { FC, useCallback, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { GaEventType, PromotionEvent } from 'src/schema/schema-ga-event';

import { editOrderErrors } from 'src/lib/1/string-map';
import { sendGaEvent } from 'src/lib/3/firebase-short-cut';

import useCart from 'src/redux/hooks/useCart';
import useOrder from 'src/redux/hooks/useOrder';
import { selectUserId } from 'src/redux/slices/auth';
import { selectProductVolume } from 'src/redux/slices/cart';
import { selectOnEditOrder, selectOrderProductVolume, selectSubmiited } from 'src/redux/slices/order';
import { useAppSelector } from 'src/redux/store';

import VolumeController from 'src/components/molecules/VolumeController/VolumeController';

/**
 * 프로모션 전용 GA 이벤트를 수집한다.
 */
const sendGaEventForPromotion = (title: string, trigger: PromotionEvent['eventParams']['trigger']) =>
  sendGaEvent({
    name: GaEventType.PROMOTION,
    eventParams: {
      title,
      trigger,
    },
  });

/**
 * 상품의 수량을 변경하는 모달을 띄운다.
 */
const showControlModal = (
  productId: string,
  volume: number,
  onChange: (productId: string, inputVolume: number) => void
) => {
  const prevVolume = volume;
  Modal.confirm({
    icon: <EditOutlined />,
    title: '수량변경',
    content: (
      <InputNumber
        controls={true}
        color='#FF5E1A'
        defaultValue={volume}
        type='number'
        min={1}
        max={99}
        size='large'
        onChange={(e) => onChange(productId, e ?? 1)}
        style={{ width: '100%', textAlign: 'center', maxWidth: '100%' }}
      />
    ),
    okText: '변경',
    cancelText: '취소',
    okButtonProps: { size: 'large', style: { minWidth: 80, backgroundColor: 'var(--orange700)', fontWeight: 600 } },
    cancelButtonProps: { size: 'large', style: { minWidth: 80, fontWeight: 600 } },
    onCancel: () => onChange(productId, prevVolume),
  });
};

interface ProductVolumeControllerProps {
  product: ProductDoc & { promotion?: string };
}

/**
 * 상품을 장바구니에 담거나 주문서('주문완료'상태의)에 담긴 수량을 표시하는 컴포넌트
 */
const ProductVolumeController: FC<ProductVolumeControllerProps> = ({ product }) => {
  const navigate = useNavigate();
  const userId = useAppSelector(selectUserId);
  const onEditOrder = useAppSelector(selectOnEditOrder);
  const submittedOrder = useAppSelector(selectSubmiited);
  const orderProductVolume = useAppSelector((state) => selectOrderProductVolume(state, product.productId));
  const cartProductVolume = useAppSelector((state) => selectProductVolume(state, product.productId));

  const { onSetOnEditOrder } = useOrder();
  const { onAddToCart, onSubFromCart, onRemoveFromCart, onSetProductVolume, onSetProducts } = useCart();

  /**
   * onEditOrder === null : 주문이 없음
   * - 장바구니의 수량을 보여준다
   * - 장바구니 수정 가능
   *
   * onEditOrder === true : 주문이 있으면서 수정 모드
   * - 장바구니의 수량을 보여준다.
   * - 장바구니 수정 가능
   *
   * onEditOrder === false : 주문이 있으며 수정 모드가 아님
   * - 주문의 수량을 보여준다.
   * - 수정모드로 변경 필요
   */
  const editable = useMemo(() => onEditOrder === null || onEditOrder === true, [onEditOrder]);
  const volume = onEditOrder === false ? orderProductVolume : cartProductVolume;

  /**
   * 상품 수량 변경 액션을 수행하기 전에
   * 1. 비로그인 상태인 경우 로그인 페이지로 이동시킨다.
   * 2. 수정모드인지 확인한다.
   */
  const handleUpdateCart = useCallback(
    (action: () => void) => {
      // 1. 비로그인 상태인 경우
      if (!userId) {
        Modal.confirm({
          title: '로그인 후 이용해 주세요.',
          onOk: () => navigate('/signin'),
          okText: '로그인',
          cancelText: '취소',
        });
        return;
      }

      // 2. 수정 모드가 아닌경우
      if (!editable) {
        Modal.confirm({
          title: '이미 완료한 주문이 있습니다.',
          content: editOrderErrors.onEdit,
          onOk: () => {
            onSetOnEditOrder(true);
            onSetProducts(submittedOrder?.products || []);
            sendGaEvent({
              name: GaEventType.ORDER_EDIT,
              eventParams: {
                orderId: submittedOrder?._id ?? null,
              },
            });
          },
          style: { whiteSpace: 'pre-line' },
          okText: '수정 모드로 변경',
          cancelText: '취소',
        });
        return;
      }

      action();
    },
    [userId, editable, navigate, onSetOnEditOrder, onSetProducts, submittedOrder?._id, submittedOrder?.products]
  );

  const onClickAddButton = useCallback(
    (e: React.MouseEvent<HTMLElement, MouseEvent>) => {
      e.stopPropagation();
      // 프로모션 상품인 경우 프로모션 정보를 제외한 상품 정보를 전달한다.
      const { promotion, ...product0 } = product;
      handleUpdateCart(() => onAddToCart(product0, 1));

      // GA 프로모션 이벤트 수집
      if (promotion) {
        sendGaEventForPromotion(promotion, 'add_to_cart');
      }
    },
    [product, handleUpdateCart, onAddToCart]
  );

  const onClickMinusButton = useCallback(
    (e: React.MouseEvent<HTMLElement, MouseEvent>) => {
      e.stopPropagation();
      handleUpdateCart(() => onSubFromCart(product.productId, 1));

      // GA 프로모션 이벤트 수집
      if (product.promotion) {
        sendGaEventForPromotion(product.promotion, 'sub_from_cart');
      }
    },
    [handleUpdateCart, product.promotion, product.productId, onSubFromCart]
  );

  const onClickVolumeDisplay = useCallback(
    (e: React.MouseEvent<HTMLElement, MouseEvent>) => {
      e.stopPropagation();
      handleUpdateCart(() => showControlModal(product.productId, volume, onSetProductVolume));

      // GA 프로모션 이벤트 수집
      if (product.promotion) {
        sendGaEventForPromotion(product.promotion, 'change_volume');
      }
    },
    [handleUpdateCart, product.promotion, product.productId, volume, onSetProductVolume]
  );

  const onClickRemoveButton = useCallback(
    (e: React.MouseEvent<HTMLElement, MouseEvent>) => {
      e.stopPropagation();
      handleUpdateCart(() => onRemoveFromCart(product.productId));

      // GA 프로모션 이벤트 수집
      if (product.promotion) {
        sendGaEventForPromotion(product.promotion, 'remove_from_cart');
      }
    },
    [handleUpdateCart, product.promotion, product.productId, onRemoveFromCart]
  );

  return (
    <VolumeController
      volume={volume}
      productState={product.state}
      onClickAddButton={onClickAddButton}
      onClickMinusButton={onClickMinusButton}
      onClickVolumeDisplay={onClickVolumeDisplay}
      onClickRemoveButton={onClickRemoveButton}
    />
  );
};

export default ProductVolumeController;
