import { ProductDoc, ProductStorage } from '@gooduncles/gu-app-schema';
import { FC } from 'react';

import Badge from 'src/components/atoms/Badge/Badge';
import Price from 'src/components/atoms/Price/Price';
import UnitPrice from 'src/components/atoms/Price/UnitPrice';
import Text from 'src/components/atoms/Text/Text';
import FavoriteProduct from 'src/components/molecules/FavoriteProduct/FavoriteProduct';
import ProductThumbnail from 'src/components/molecules/ProductThumbnail/ProductThumbnail';

import ProductVolumeController from '../ProductVolumeController/ProductVolumeController';
import classes from './ProductHorizontalItem.module.scss';

const StorageBadge = (storage: ProductStorage) => {
  switch (storage) {
    case 'freezer':
      return (
        <Badge colorKey='blue' border>
          냉동
        </Badge>
      );
    case 'refrigerator':
      return (
        <Badge colorKey='green' border>
          냉장
        </Badge>
      );
  }
  return null;
};

interface ProductHorizontalItemProps {
  product: ProductDoc & { promotion?: string };
  disabled?: boolean;
}

const ProductHorizontalItem: FC<ProductHorizontalItemProps> = ({ product, disabled }) => {
  return (
    <div className={classes.productHorizontalItem}>
      <div className={classes.leftBox}>
        <ProductThumbnail product={product} />
      </div>
      <div className={classes.rightBox}>
        <div className={classes.flexRow}>
          <div className={classes.flexColumn}>
            <Text className={classes.name}>{product.fullName}</Text>
            <div className={classes.badgeList}>
              {product.badge &&
                product.badge.map((badge) => (
                  <Badge key={badge} colorKey='orange'>
                    {badge}
                  </Badge>
                ))}
              {StorageBadge(product.storage)}
              {product.packing === 'box' && (
                <Badge colorKey='brown' border>
                  박스
                </Badge>
              )}
            </div>
          </div>
          {!disabled && <FavoriteProduct product={product} />}
        </div>
        <div className={classes.flexRowCenter}>
          <div className={classes.flexColumn}>
            <Price price={product.price} size='large' />
            {product.unitPrice && <UnitPrice unitPrice={product.unitPrice} />}
          </div>
          {!disabled && (
            <div className={classes.controllerBox}>
              <ProductVolumeController product={product} />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default ProductHorizontalItem;
