import { FC } from 'react';
import { useNavigate } from 'react-router-dom';

import IconArrowBack from 'src/assets/icons/arrow-back.svg';

import classes from './Button.module.scss';

const BackButton: FC<React.ButtonHTMLAttributes<HTMLButtonElement>> = (props) => {
  const navigate = useNavigate();
  return (
    <button {...props} className={classes.backButton} onClick={() => navigate(-1)}>
      <img src={IconArrowBack} alt='arrow-back' />
    </button>
  );
};

export default BackButton;
