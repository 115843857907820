import { DeleteOutlined } from '@ant-design/icons';
import { ProductState, ProductStateCode } from '@gooduncles/gu-app-schema';
import { Button } from 'antd';
import { FC } from 'react';

import VolumeButton from 'src/components/atoms/Button/VolumeButton';
import FlexSpacer from 'src/components/atoms/FlexSpacer';
import VolumeDisplay from 'src/components/atoms/VolumeDisplay/VolumeDisplay';
import classes from 'src/components/molecules/VolumeController/VolumeController.module.scss';

const borderSize = 2;

interface VolumeControllerProps {
  volume: number;
  productState: ProductState;
  size?: number;
  onClickAddButton: (e: React.MouseEvent<HTMLElement, MouseEvent>) => void;
  onClickMinusButton: (e: React.MouseEvent<HTMLElement, MouseEvent>) => void;
  onClickVolumeDisplay: (e: React.MouseEvent<HTMLElement, MouseEvent>) => void;
  onClickRemoveButton: (e: React.MouseEvent<HTMLElement, MouseEvent>) => void;
}

const VolumeController: FC<VolumeControllerProps> = ({
  volume,
  productState,
  onClickAddButton,
  onClickMinusButton,
  onClickVolumeDisplay,
  onClickRemoveButton,
  size = 38,
}) => {
  const added = volume > 0;

  /** 판매가능 상태가 아닌 상품이 이미 추가되어 있는 경우 */
  if (productState !== ProductStateCode.STOCK) {
    return volume > 0 ? (
      <div className={classes.removeButton} style={{ height: size, width: size, borderRadius: size / 2 }}>
        <Button icon={<DeleteOutlined />} type='primary' danger onClick={onClickRemoveButton} />
      </div>
    ) : null;
  }

  return (
    <div className={classes.wrapper} style={{ height: size + borderSize }}>
      <div
        className={`${classes.volumeController} ${added ? classes.added : ''}`}
        style={{ borderRadius: size / 2, width: added ? '100%' : size + borderSize }}>
        <div className={classes.fixedToRight}>
          <VolumeButton actionType='plus' size={size} onClick={onClickAddButton} />
        </div>
        <FlexSpacer size={size} />
        <VolumeDisplay className={classes.animate} volume={volume} onClick={onClickVolumeDisplay} />
        <div className={classes.animate}>
          <VolumeButton actionType='minus' size={size} onClick={onClickMinusButton} />
        </div>
      </div>
    </div>
  );
};

export default VolumeController;
