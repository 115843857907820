import type { Identifier, XYCoord } from 'dnd-core';
import { FC, PropsWithChildren, useRef } from 'react';
import { useDrag, useDrop } from 'react-dnd';
import { DragObject } from 'src/schema/schema-drag-and-drop';

import classes from './DragAndDropItem.module.scss';
import DragHandleBar from './DragHandleBar';

interface DragAndDropItemProps {
  item: DragObject;
  moveItem: (item: DragObject) => void;
}

const DragAndDropItem: FC<PropsWithChildren<DragAndDropItemProps>> = ({ item, moveItem, children }) => {
  const ref = useRef<HTMLDivElement | null>(null);
  const previewRef = useRef<HTMLDivElement | null>(null);

  // 드롭 이벤트 처리(덮혀지는 카드)
  const [{ handlerId }, drop] = useDrop<DragObject, any, { handlerId: Identifier | null }>({
    accept: 'card',
    // 선택된 카드가 들어갈 곳의 위치를 잡는다.
    hover: (draggedItem, monitor) => {
      if (!ref.current) return;
      const dragIndex = draggedItem.index;
      const hoverIndex = item.index;

      // 카드의 크기를 구한다.
      const hoverBoundingRect = ref.current?.getBoundingClientRect();

      // 카드의 중간 지점을 구한다.
      const hoverMiddleY = (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;

      // 터치의 위치를 구한다.
      const clientOffset = monitor.getClientOffset();

      // 카드의 위치를 구한다.
      const hoverClientY = (clientOffset as XYCoord).y - hoverBoundingRect.top;

      // 아래로 드래그 했을 때
      if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
        return;
      }

      // 위로 드래그 했을 때
      if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
        return;
      }

      // 카드를 이동시킨다.
      moveItem({ ...draggedItem, index: hoverIndex });
    },
    collect: (monitor) => ({
      handlerId: monitor.getHandlerId(),
    }),
  });

  const [{ opacity }, drag, preview] = useDrag<DragObject, any, { opacity: number }>({
    type: 'card',
    item: () => {
      return item;
    },
    collect: (monitor) => ({
      opacity: monitor.isDragging() ? 0.1 : 1,
    }),
  });

  drag(ref);
  drop(preview(previewRef));

  return (
    <div ref={previewRef} className={classes.item} style={{ opacity }} data-handler-id={handlerId}>
      {children}
      <DragHandleBar ref={ref} />
    </div>
  );
};

export default DragAndDropItem;
