import { OrderProduct, ProductDoc } from '@gooduncles/gu-app-schema';
import { useCallback } from 'react';

import {
  addToCart,
  removeFromCart,
  removeProductsFromCart,
  resetCart,
  setProductVolume,
  setProducts,
  subFromCart,
} from '../slices/cart';
import { useAppDispatch } from '../store';

const useCart = () => {
  const dispatch = useAppDispatch();
  const onResetCart = useCallback(
    (reason: string) => {
      dispatch(resetCart(reason));
    },
    [dispatch]
  );

  /**
   * 장바구니에 상품을 담거나 추가한다.
   */
  const onAddToCart = useCallback(
    (product: ProductDoc, volume = 1) => {
      dispatch(addToCart({ product, volume }));
    },
    [dispatch]
  );

  /**
   * 장바구니에 담긴 상품을 줄이거나 삭제한다.
   */
  const onSubFromCart = useCallback(
    (productId: string, volume = 1) => {
      dispatch(subFromCart({ productId, volume }));
    },
    [dispatch]
  );

  /**
   * 장바구니에 담긴 상품의 수량을 변경 또는 삭제한다.
   */
  const onSetProductVolume = useCallback(
    (productId: string, inputVolume: number) => {
      if (inputVolume < 1) {
        dispatch(removeFromCart(productId));
      } else {
        dispatch(setProductVolume({ productId, inputVolume }));
      }
    },
    [dispatch]
  );

  /**
   * 장바구니에 담긴 상품을 삭제한다.
   */
  const onRemoveFromCart = useCallback(
    (productId: string) => {
      dispatch(removeFromCart(productId));
    },
    [dispatch]
  );

  const onRemoveProductsFromCart = useCallback(
    (productIds: string[]) => {
      dispatch(removeProductsFromCart(productIds));
    },
    [dispatch]
  );

  const onSetProducts = useCallback(
    (orderProducts: OrderProduct[]) => {
      dispatch(setProducts(orderProducts));
    },
    [dispatch]
  );

  return {
    onResetCart,
    onAddToCart,
    onSubFromCart,
    onSetProductVolume,
    onRemoveFromCart,
    onRemoveProductsFromCart,
    onSetProducts,
  };
};

export default useCart;
