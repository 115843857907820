import { MailOutlined } from '@ant-design/icons';
import { Button, message } from 'antd';
import dayjs from 'dayjs';
import { useState } from 'react';

import { formatDate } from 'src/lib/1/date-util';
import { errorObjectToString } from 'src/lib/1/util';
import { callSendOrderHistoryExcelEmail } from 'src/lib/3/firebase-short-cut';
import { CommerceLogger } from 'src/lib/4/logger';

import { selectStore } from 'src/redux/slices/auth';
import { useAppSelector } from 'src/redux/store';

import DatePickerButton from 'src/components/atoms/DatePickerButton/DatePickerButton';

import classes from './SendOrderHistoryBar.module.scss';

const logger = CommerceLogger.getInstance();

/** 2024.8.7일 이전으로는 배송완료 필드가 다르기 때문에 지원하지 않는다. */
const minDate = new Date(2024, 7, 7);
const aWeekAgo = dayjs().subtract(7, 'day').toDate();
const today = dayjs().toDate();

const SendOrderHistoryBar = () => {
  const store = useAppSelector(selectStore);
  const [startDate, setStartDate] = useState<Date>(aWeekAgo);
  const [endDate, setEndDate] = useState<Date>(today);
  const [loading, setLoading] = useState(false);

  const handleSendOrderHistory = async () => {
    try {
      if (!startDate || !endDate) {
        message.error('시작 날짜와 종료 날짜를 모두 선택해주세요.');
        return;
      }
      if (dayjs(startDate).isAfter(dayjs(endDate))) {
        message.error('종료 날짜는 시작 날짜보다 이후여야 합니다.');
        return;
      }
      if (!store) return;
      if (!store.storeEmail) {
        message.error('매장 이메일이 없습니다.\n메뉴 -> 회원 정보를 확인해주세요.');
        return;
      }
      setLoading(true);
      const response = await callSendOrderHistoryExcelEmail({
        storeId: store._id,
        startDate: formatDate(startDate, 'yyyy-MM-dd'),
        endDate: formatDate(endDate, 'yyyy-MM-dd'),
        email: store.storeEmail,
      });
      if (response.data.result !== 'success') {
        message.error('주문 상세 내역 이메일 발송(요청) 실패 카톡으로 문의해주세요.');
        logger.logCommerce(`주문 상세 내역 이메일 발송(요청) 실패\nerror: ${response.data.message}`, {
          level: 'error',
        });
        return;
      }
      message.success('주문 상세 내역 이메일을 발송했습니다.');
      logger.logCommerce(
        `주문 상세 내역 이메일 발송 완료\n${store.storeNickname} - ${store.storeEmail}\n${startDate}~${endDate}`,
        {
          channel: 'dev',
        }
      );
    } catch (error) {
      const errorMessage = errorObjectToString(error);
      message.error('주문 상세 내역 이메일 발송 실패 카톡으로 문의해주세요.');
      logger.logCommerce(`주문 상세 내역 이메일 발송 실패\nerror: ${errorMessage}`, {
        level: 'error',
      });
    }
    setLoading(false);
  };

  return (
    <div className={classes.barContainer}>
      <p className={classes.info}>주문 상세 내역 이메일 발송</p>
      <div className={classes.controlBox}>
        <div className={classes.datePickerButtons}>
          <DatePickerButton
            min={minDate}
            max={today}
            title='시작 날짜'
            value={startDate}
            onChange={setStartDate}
            disabled={loading}
            buttonType='text'
          />
          <span className={classes.tilde}>~</span>
          <DatePickerButton
            min={minDate}
            max={today}
            title='종료 날짜'
            value={endDate}
            onChange={setEndDate}
            disabled={loading}
            buttonType='text'
          />
        </div>
        <Button
          className={classes.sendButton}
          onClick={handleSendOrderHistory}
          icon={<MailOutlined />}
          loading={loading}
          disabled={!startDate || !endDate}>
          발송
        </Button>
      </div>
    </div>
  );
};

export default SendOrderHistoryBar;
