import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { GaEventType } from 'src/schema/schema-ga-event';

import { sendGaEvent } from 'src/lib/3/firebase-short-cut';
import { WebViewMessageHandler } from 'src/lib/5/webveiw-message-handler';

const webViewMessageHandler = WebViewMessageHandler.getInstance();
let page_referrer = document.referrer;

/**
 * {@link https://v5.reactrouter.com/web/api/Hooks/uselocation}
 */
const usePageViews = () => {
  const location = useLocation();

  useEffect(() => {
    // localhost의 port 번호를 제거한다.
    const page_location = `${window.location.protocol}//${window.location.hostname}${window.location.pathname}`;
    sendGaEvent({
      name: GaEventType.PAGE_VIEW,
      eventParams: {
        page_location,
        page_referrer,
      },
    });
    page_referrer = page_location;
    webViewMessageHandler.syncNavigationStateChange();
  }, [location.pathname]);
};

export default usePageViews;
