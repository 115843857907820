import { FC, useState } from 'react';
import { useSearchParams } from 'react-router-dom';

import { selectStore, selectUser } from 'src/redux/slices/auth';
import { useAppSelector } from 'src/redux/store';

import ProductRequestModal from 'src/components/Modal/ProductRequestModal/ProductRequestModal';

import classes from './EmptyProductSearch.module.scss';

const EmptyProductSearch: FC = () => {
  const [searchParams] = useSearchParams();
  const query = searchParams.get('q') ?? '';
  const user = useAppSelector(selectUser, (prev, next) => prev?._id === next?._id && prev?.status === next?.status);
  const store = useAppSelector(selectStore, (prev, next) => prev?._id === next?._id);
  const [requested, setRequested] = useState(false);

  return (
    <div className={classes.noResult}>
      <h1>🛒</h1>
      <p>검색 결과가 없습니다.</p>
      {requested ? (
        <p className={classes.requested}>
          상품 담당자에게
          <br /> 요청이 전달되었습니다!
        </p>
      ) : (
        <>
          <p className={classes.requestInfo}>필요한 상품을 요청해보세요!</p>
          <ProductRequestModal
            keyword={query}
            onRequest={() => setRequested(true)}
            disabled={!user}
            storeName={store?.storeNickname}
          />
        </>
      )}
    </div>
  );
};

export default EmptyProductSearch;
