import { FC } from 'react';
import { useSearchParams } from 'react-router-dom';

import { formatDate } from 'src/lib/1/date-util';

import useStoreIssuesByIds from 'src/hooks/useStoreIssuesByIds';

import EmptyList from '../Common/EmptyList/EmptyList';
import StoreIssueItem from '../Common/StoreIssueItem/StoreIssueItem';
import TopNavigationBar from '../Common/TopNavigationBar/TopNavigationBar';

const StoreIssueDetail: FC = () => {
  const [searchParams] = useSearchParams();
  const issueIds = searchParams.getAll('issue-id');
  const { storeIssues, storeIssuesLoading } = useStoreIssuesByIds(issueIds.length > 0 ? issueIds : null);
  const title = storeIssues && storeIssues?.length > 0 ? formatDate(storeIssues[0].date, 'L월 d일 (EEEEEE)') : '';
  const isEmpty = !storeIssuesLoading && storeIssues === undefined;

  return (
    <div>
      <TopNavigationBar title={title} leftButton />
      {isEmpty ? (
        <EmptyList message='이슈 정보를 불러오지 못했습니다.' />
      ) : (
        <div>
          <p style={{ padding: '10px 30px', color: 'var(--orange400)' }}>※ 특이사항</p>
          {storeIssues?.map((storeIssue) => {
            return <StoreIssueItem key={storeIssue._id} storeIssue={storeIssue} />;
          })}
          <p
            style={{
              padding: '10px 30px',
              color: 'var(--gray600)',
            }}>
            안내된 내용은 다음 정산에 포함됩니다.
          </p>
        </div>
      )}
    </div>
  );
};

export default StoreIssueDetail;
