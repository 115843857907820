import { Button, Checkbox, Popconfirm } from 'antd';
import { isEqual } from 'lodash-es';
import { FC, useCallback, useEffect, useMemo, useState } from 'react';

import { CheckboxChangeEvent } from 'antd/lib/checkbox';
import { CheckboxValueType } from 'antd/lib/checkbox/Group';
import { CommerceLogger } from 'src/lib/4/logger';
import { loadPrevOrderProducts } from 'src/lib/4/order-util';
import { onCancelOrder } from 'src/lib/5/order-api';

import useCart from 'src/redux/hooks/useCart';
import useOrder from 'src/redux/hooks/useOrder';
import { selectStore } from 'src/redux/slices/auth';
import { selectCart } from 'src/redux/slices/cart';
import { selectDelivered, selectSubmiited } from 'src/redux/slices/order';
import { useAppSelector } from 'src/redux/store';

import useProductsByIds from 'src/hooks/useProductsByIds';

import EmptyList from '../Common/EmptyList/EmptyList';
import TopNavigationBar from '../Common/TopNavigationBar/TopNavigationBar';
import ProductItem from '../ProductItem/ProductItem';
import classes from './ShoppingCart.module.scss';

const logger = CommerceLogger.getInstance();

const ShoppingCart: FC = () => {
  const submittedOrder = useAppSelector(selectSubmiited);
  const cart = useAppSelector(selectCart);
  const store = useAppSelector(selectStore);
  const { resetCartWithOrder } = useOrder();
  const { onSetProducts, onResetCart } = useCart();
  const cartProductIds = useMemo(() => cart.products.map((p) => p.productId), [cart.products]);
  const deliveredOrders = useAppSelector(selectDelivered, isEqual);
  const { products, productsLoading, productsError } = useProductsByIds(cartProductIds);
  const [cartItems, setCartItems] = useState<JSX.Element[]>([]);
  const [loading, setLoading] = useState(false);

  const [onEdit, setOnEdit] = useState(false);
  const [checkedList, setCheckedList] = useState<CheckboxValueType[]>([]);
  const [indeterminate, setIndeterminate] = useState(false);
  const [checkAll, setCheckAll] = useState(false);

  const cancelOrder = useCallback(
    (reset?: boolean) => {
      if (submittedOrder?._id) {
        if (!reset) {
          resetCartWithOrder(submittedOrder.products);
        } else {
          onResetCart('장바구니 비우기');
        }
        onCancelOrder(submittedOrder, store?.storeNickname ?? store?.storeName ?? '알 수 없음', reset);
      }
    },
    [submittedOrder, store?.storeNickname, store?.storeName, resetCartWithOrder, onResetCart]
  );

  const onChange = useCallback(
    (list: CheckboxValueType[]) => {
      const curProducts = cart.products ?? [];
      setCheckedList(list);
      setIndeterminate(!!list.length && list.length < curProducts.length);
      setCheckAll(list.length === curProducts.length);
    },
    [cart]
  );

  const onCheckAllChange = useCallback(
    (e: CheckboxChangeEvent) => {
      const cartItemList = cart.products?.map((i) => i.productId) ?? [];
      setCheckedList(e.target.checked ? cartItemList : []);
      setIndeterminate(false);
      setCheckAll(e.target.checked);
    },
    [cart.products]
  );

  const offEdit = useCallback(() => {
    setOnEdit(false);
    setCheckedList([]);
    setIndeterminate(false);
    setCheckAll(false);
  }, []);

  const resetCart = useCallback(() => {
    onResetCart('장바구니 비우기');
    offEdit();
  }, [offEdit, onResetCart]);

  const onLoadPrevOrderProducts = useCallback(async () => {
    logger.logCommerce(
      `이전 주문 불러오기 - orderId: ${deliveredOrders[0]?._id}, orderDate: ${deliveredOrders[0]?.orderDate}`
    );
    setLoading(true);
    const prevProducts = await loadPrevOrderProducts(deliveredOrders[0]?.products);
    if (prevProducts) {
      onSetProducts(prevProducts);
    }
    setLoading(false);
  }, [deliveredOrders, onSetProducts]);

  useEffect(() => {
    if (products !== undefined && productsLoading === false) {
      const items = products.map((product, idx) => (
        <ProductItem
          index={idx}
          key={product.productId}
          product={product}
          checkBox={
            onEdit ? <Checkbox value={product._id} style={{ position: 'absolute', top: 12, left: 16 }} /> : undefined
          }
        />
      ));
      setCartItems(items);
    } else {
      setCartItems([]);
    }
  }, [onEdit, products, productsLoading]);

  const NavBarLeftButton = onEdit ? (
    <Checkbox
      indeterminate={indeterminate}
      onChange={onCheckAllChange}
      checked={checkAll}
      style={{ paddingLeft: 12 }}
      disabled={cartItems.length < 1}>
      모두선택
    </Checkbox>
  ) : (
    <Button type='text' onClick={() => setOnEdit(true)} disabled={cartItems.length < 1}>
      편집
    </Button>
  );

  const NavBarRightButton = useMemo(() => {
    if (cart.products.length > 0) {
      // check된 항목을 제외한 신규 목록
      const newProducts = cart.products.filter((f) => !checkedList.includes(f.productId));
      return (
        <>
          {!onEdit &&
            (submittedOrder ? (
              <Popconfirm
                placement='bottomRight'
                title={`지금 장바구니를 비우면 주문이 취소됩니다.\n주문을 취소하시겠습니까?`}
                okText='주문취소'
                cancelText='닫기'
                onConfirm={() => cancelOrder(true)}
                overlayInnerStyle={{ whiteSpace: 'pre-line' }}>
                <Button type='text' className='redColor'>
                  장바구니 비우기
                </Button>
              </Popconfirm>
            ) : (
              <Button type='text' onClick={resetCart} className='redColor'>
                장바구니 비우기
              </Button>
            ))}
          {onEdit && (
            <>
              {newProducts.length > 0 ? (
                <Button
                  type='text'
                  disabled={checkedList.length === 0}
                  onClick={() => onSetProducts(newProducts)}
                  className='redColor'>
                  선택삭제
                </Button>
              ) : (
                // 신규 목록이 없다면 장바구니를 비운다는 메시지를 노출한다.
                <Popconfirm
                  placement='bottomRight'
                  disabled={checkedList.length === 0}
                  title={submittedOrder ? '주문을 취소하시겠습니까?' : '장바구니를 비우시겠습니까?'}
                  okText={submittedOrder ? '주문취소' : '비우기'}
                  cancelText={submittedOrder ? '닫기' : '취소'}
                  onConfirm={() => (submittedOrder ? cancelOrder() : resetCart())}>
                  <Button className='redColor' type='text'>
                    선택삭제
                  </Button>
                </Popconfirm>
              )}
              <Button type='text' style={{ color: 'var(--gray800)' }} onClick={offEdit}>
                취소
              </Button>
            </>
          )}
        </>
      );
    }
  }, [cancelOrder, cart.products, checkedList, offEdit, onEdit, onSetProducts, resetCart, submittedOrder]);

  return (
    <div className={`${classes.cartContainer} ${cartItems.length > 0 ? 'addPadding' : ''}`}>
      <TopNavigationBar borderBottom leftButton={NavBarLeftButton} rightButton={NavBarRightButton} />
      {productsLoading ? (
        <></>
      ) : (
        <>
          {cartItems.length > 0 ? (
            <Checkbox.Group
              onChange={onChange}
              value={checkedList}
              style={{ width: '100%', display: 'flex', flexDirection: 'column' }}>
              {cartItems}
            </Checkbox.Group>
          ) : (
            <EmptyList message='장바구니가 비어있습니다.' isError={productsError}>
              {/* 배송완료(한 번이라도 소비자가 확인한)상품만 불러올 수 있다. */}
              <Button
                disabled={!deliveredOrders[0]}
                loading={loading}
                type='primary'
                size='large'
                shape='round'
                onClick={onLoadPrevOrderProducts}>
                이전 주문 불러오기
              </Button>
            </EmptyList>
          )}
        </>
      )}
    </div>
  );
};

export default ShoppingCart;
