import { CommerceConfDoc, Order, OrderDoc, OrderStatusCode, ProductStateCode } from '@gooduncles/gu-app-schema';
import { format, sub } from 'date-fns';
import { Timestamp } from 'firebase/firestore';
import { useMemo } from 'react';
import useSWR from 'swr';

import { FirebaseManager, WHERE } from '../lib/2/firebase-manager';
import { MobileAlert } from 'src/lib/1/util';
import { CommerceLogger } from 'src/lib/4/logger';

import { selectStore } from 'src/redux/slices/auth';
import { useAppSelector } from 'src/redux/store';

const logger = CommerceLogger.getInstance();
const firebaseManager = FirebaseManager.getInstance();
const productPath = 'product';
const orderPath = 'order';
/** 6개월 이내의 주문을 가져온다. */
const monthRange = 6;

const getOrderDocsForStore = async (path: string) => {
  const storeId = path.split('/')[1];
  const now = new Date();
  const endDate = format(new Date(now.setMonth(now.getMonth() - monthRange)), "yyyy-MM-dd'T'HH:mm:ss+0900");
  const where: WHERE[] = [
    ['storeId', '==', storeId],
    ['orderDate', '>=', endDate],
  ];
  return firebaseManager.getDocsWithWhere<OrderDoc>(orderPath, where, {
    sortKey: 'orderDate',
    orderBy: 'desc',
  });
};

/**
 * 실제 주문을 하기전에 이미 주문한 주문이 있는지 확인한다.
 * 있다면 주문을 할 수 없으므로 false를 리턴한다.
 */
const checkIsOrderable = async (storeId: string) => {
  const now = new Date();
  const endDate = format(sub(now, { days: 3 }), "yyyy-MM-dd'T'HH:mm:ss+0900");
  const where: WHERE[] = [
    ['storeId', '==', storeId],
    ['orderDate', '>=', endDate],
  ];
  const orderedOrders = await firebaseManager.getDocsArrayWithWhere<OrderDoc>(orderPath, where, {
    sortKey: 'orderDate',
    orderBy: 'desc',
  });
  return orderedOrders && orderedOrders.filter((o) => o.orderStatus === OrderStatusCode.SUBMITTED).length < 1;
};

const updateOrder = async (orderId: string, docData: Partial<Order>) =>
  firebaseManager.updateDoc(orderPath, orderId, docData);
const cancelOrder = async (orderId: string) =>
  firebaseManager.updateDoc(orderPath, orderId, { orderStatus: OrderStatusCode.CANCELED });

const useOrders = () => {
  const store = useAppSelector(selectStore);
  const {
    data: orders,
    isValidating: orderLoading,
    mutate,
    error,
  } = useSWR(store?._id ? `orders/${store?._id}` : null, getOrderDocsForStore);

  const orderedOrder = useMemo(() => {
    const orderedOrders = orders
      ? Object.values(orders).filter((order) => order.orderStatus === OrderStatusCode.SUBMITTED)
      : [];
    if (orderedOrders.length > 0) {
      // TODO: 주문 완료된 주문이 여러개일 경우에 대한 경우를 생각해봐야한다.
      return orderedOrders[0];
    }
    return null;
  }, [orders]);

  /**
   * 배송까지 완료된 이전 주문을 가져온다.
   */
  const prevDeliveredOrder = useMemo(() => {
    const deliveredOrders = orders
      ? Object.values(orders).filter((order) => order.orderStatus === OrderStatusCode.DELIVERED)
      : [];
    if (deliveredOrders.length > 0) {
      return deliveredOrders[0];
    }
    return null;
  }, [orders]);

  const mutateCreateOrder = async (docData: Order, orderId?: string) => {
    const isOrderable = await checkIsOrderable(docData.storeId);
    if (!isOrderable) {
      throw new Error('이미 주문한 주문이 있습니다.');
    }
    if (store?._id) {
      const orderDocRef = firebaseManager.getDocRef(orderPath, orderId);
      const docId = orderDocRef.id;
      // // 로컬 업데이트 및 갱신 비활성화
      mutate({ ...orders, [docId]: docData as OrderDoc }, false);

      // API호출을 통한 실제 DB 업데이트
      await firebaseManager.transaction<void>(async (transaction) => {
        const commerceConfigRef = firebaseManager.getDocRef('config', 'commerce');
        const commerceConfigSnapshot = await transaction.get(commerceConfigRef);
        const commerceConfig = commerceConfigSnapshot.data() as CommerceConfDoc;
        if (!commerceConfig.open) {
          throw new Error('주문이 불가능한 시간입니다.');
        }

        if (commerceConfig.closed.isClosed) {
          throw new Error(commerceConfig.closed.msgClosed);
        }

        // 상품수량 및 상태체크
        const promises = docData.products.map((p) =>
          transaction.get(firebaseManager.getDocRef(productPath, p.productId))
        );
        const products = await Promise.all(promises);
        const soldOutProducts = products.filter((p) => p.data()?.state !== ProductStateCode.STOCK);
        if (soldOutProducts.length > 0) {
          throw new Error(
            `구매 불가능한 상품이 있습니다. ${soldOutProducts.map((p) => p.data()?.fullName).join(', ')}`
          );
        }

        const additionalData = orderId
          ? {
              _timeUpdate: Timestamp.now(),
            }
          : {
              _timeCreate: Timestamp.now(),
            };

        transaction.set(orderDocRef, {
          _id: docId,
          ...docData,
          ...additionalData,
        });
      });
      // 실 데이터 갱신
      mutate();
      return docId;
    }
  };

  /**
   * 주문을 취소하는 경우에는 장바구니에 상품을 남겨야한다.
   * @param orderId
   */
  const mutateCancelOrder = async (orderId: string, emptyCart?: boolean) => {
    const order = orders?.[orderId];
    if (store?._id && order) {
      logger.logCommerce(`주문취소 - 업소명: ${store.storeName}, 취소금액: ${order.paidAmount}`, {
        extraInfo: {
          orderId,
        },
      });
      order.orderStatus = OrderStatusCode.CANCELED;
      // 로컬 업데이트 및 갱신 비활성화
      mutate({ ...orders, [orderId]: order }, false);
      // API호출을 통한 실제 DB 업데이트
      await cancelOrder(orderId);
      MobileAlert('주문취소 완료', emptyCart ? '주문을 취소하였습니다.' : '취소된 상품을 장바구니로 옮겼습니다.');
      // 실 데이터 갱신
      mutate();
    } else {
      MobileAlert('주문취소 실패', '주문을 취소할 수 없습니다.');
      logger.logCommerce(`주문취소 실패 - 업소명: ${store?.storeName}, 주문번호: ${orderId}`, {
        level: 'error',
      });
    }
  };

  const mutateUpdateOrder = async (orderId: string, docData: Order) => {
    const order = orders?.[orderId];
    // '주문완료'상태인 주문만 변경이 가능하다.
    if (store?._id && order && order.orderStatus === OrderStatusCode.SUBMITTED) {
      // 로컬 업데이트 및 갱신 비활성화
      mutate({ ...orders, [orderId]: { ...order, ...docData } }, false);
      // API호출을 통한 실제 DB 업데이트
      await updateOrder(orderId, docData);
      // 실 데이터 갱신
      mutate();
    } else {
      MobileAlert('주문변경 실패', '주문을 변경할 수 없습니다.');
      logger.logCommerce(`주문변경 실패 - 업소명: ${store?.storeName}, 주문번호: ${orderId}`, {
        level: 'error',
      });
    }
  };

  return {
    orders,
    orderedOrder,
    orderLoading,
    prevDeliveredOrder,
    mutateCreateOrder,
    mutateCancelOrder,
    mutateUpdateOrder,
    mutateOrderError: error,
  };
};

export default useOrders;
