import { PayloadAction, createSlice } from '@reduxjs/toolkit';

import { OrderState } from '../models/orderModel';
import { RootState } from '../store';

const initialState: OrderState = {
  submitted: null,
  accepted: [],
  delivered: [],
  canceled: [],
  rejected: [],
  onEditOrder: false,
  orderCardOpen: false,
};

const orderSlice = createSlice({
  name: 'order',
  initialState,
  reducers: {
    clearOrder: () => initialState,
    setOrders: (state, action: PayloadAction<Omit<OrderState, 'orderCardOpen' | 'onEditOrder'>>) => {
      state.submitted = action.payload.submitted;
      state.accepted = action.payload.accepted;
      state.delivered = action.payload.delivered;
      state.canceled = action.payload.canceled;
      state.rejected = action.payload.rejected;
    },
    setOnEditOrder: (state, action: PayloadAction<boolean>) => {
      state.onEditOrder = action.payload;
    },
    setOrderCardOpen: (state, action: PayloadAction<boolean>) => {
      state.orderCardOpen = action.payload;
    },
  },
});

export const selectSubmiited = (state: RootState) => state.order.submitted;
export const selectAccepted = (state: RootState) => state.order.accepted;
export const selectDelivered = (state: RootState) => state.order.delivered;
export const selectCanceled = (state: RootState) => state.order.canceled;
export const selectRejected = (state: RootState) => state.order.rejected;
export const selectOnEditOrder = (state: RootState) => {
  return state.order.submitted === null ? null : state.order.onEditOrder;
};
export const selectOrderCardOpen = (state: RootState) => state.order.orderCardOpen;
export const selectOrderProductVolume = (state: RootState, productId: string) => {
  const product = state.order.submitted?.products.find((p) => p.productId === productId);
  return product ? product.volume : 0;
};
// TODO:
export const selectAllOrders = (state: RootState) => state.order;

export const { clearOrder, setOrders, setOrderCardOpen, setOnEditOrder } = orderSlice.actions;

export default orderSlice.reducer;
