import { FC } from 'react';

import { useTitle } from '../hooks/useTitle';

import Notification from 'src/components/Notification/Notification';

const NotificationPage: FC = () => {
  useTitle('GU | 알림');
  return <Notification />;
};

export default NotificationPage;
