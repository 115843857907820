import { UserOutlined } from '@ant-design/icons';
import { Modal } from 'antd';
import { useCallback } from 'react';
import { NavigateOptions, useNavigate } from 'react-router-dom';

import { selectUserId } from 'src/redux/slices/auth';
import { useAppSelector } from 'src/redux/store';

const { confirm } = Modal;

const useNavigateWithGuard = () => {
  const userId = useAppSelector(selectUserId);
  const navigate = useNavigate();

  const showLoginDalog = useCallback(async () => {
    confirm({
      title: '로그인이 필요한 서비스입니다.',
      icon: <UserOutlined />,
      okText: '로그인',
      cancelText: '취소',
      centered: true,
      onOk: () => {
        navigate('/signin');
      },
    });
  }, [navigate]);

  const navigateWithGuard = useCallback(
    (path: string, options?: NavigateOptions) => {
      if (userId) {
        navigate(path, options);
      } else {
        showLoginDalog();
      }
    },
    [userId, navigate, showLoginDalog]
  );

  return {
    navigateWithGuard,
    showLoginDalog,
  };
};

export default useNavigateWithGuard;
