import { FC } from 'react';
import { Navigate } from 'react-router-dom';

import { selectIsLoading, selectUser } from 'src/redux/slices/auth';
import { useAppSelector } from 'src/redux/store';

import { useTitle } from 'src/hooks/useTitle';

import DeleteAccount from 'src/components/DeleteAccount/DeleteAccount';

const DeleteAccountPage: FC = () => {
  useTitle('GU | 회원탈퇴');
  const loading = useAppSelector(selectIsLoading);
  const user = useAppSelector(selectUser);
  if (loading) return <div />;

  return user ? <DeleteAccount user={user} /> : <Navigate to='/' replace />;
};

export default DeleteAccountPage;
