import { forwardRef } from 'react';

import DragHandle from 'src/assets/icons/drag-handle.svg';

import classes from './DragHandleBar.module.scss';

type Ref = HTMLDivElement;

const DragHandleBar = forwardRef<Ref | null>((props, ref) => {
  return (
    <div ref={ref} {...props} className={classes.handle}>
      <img src={DragHandle} alt='drag-handle' />
    </div>
  );
});

DragHandleBar.displayName = 'DragHandleBar';
export default DragHandleBar;
