import { FC, PropsWithChildren } from 'react';
import { createPortal } from 'react-dom';

interface ModalProps {
  isOpen: boolean;
}

const Modal: FC<PropsWithChildren<ModalProps>> = ({ isOpen, children }) => {
  if (!isOpen) return null;

  return createPortal(
    <div className='modalContainer'>{children}</div>,
    document.getElementById('modal-root') as HTMLElement
  );
};

export default Modal;
