import useSWRImmutable from 'swr/immutable';

import { productCategories } from 'src/lib/1/string-map';
import { getProductCountForCategory } from 'src/lib/3/firebase-short-cut';

/**
 * 모든 카테고리별 유효한 상품 개수를 가져온다.(재고가 없는 상품 포함)
 */
const getProductCountForAllCategories = async (): Promise<Record<string, number | undefined>> => {
  const promises = Object.keys(productCategories).map(async (category) => {
    try {
      const count = await getProductCountForCategory(category);
      return [category, count];
    } catch (error) {
      console.error(error);
      return [category, undefined];
    }
  });
  const entires = await Promise.all(promises);
  return Object.fromEntries(entires);
};

/**
 * 특정 카테고리의 유효한 상품 개수를 가져온다.(재고가 없는 상품 포함)
 */
const useProductCountForAllCategories = () => {
  const { data } = useSWRImmutable('product-count-for-all-categories', getProductCountForAllCategories);

  return {
    productCountRecord: data,
  };
};

export default useProductCountForAllCategories;
