import { FC, PropsWithChildren } from 'react';

import classes from './Text.module.scss';

const Text: FC<PropsWithChildren<React.HTMLAttributes<HTMLParagraphElement>>> = ({ children, ...props }) => {
  return (
    <p className={classes.text} {...props}>
      {children}
    </p>
  );
};

export default Text;
