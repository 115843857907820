import { LoadingOutlined, SendOutlined } from '@ant-design/icons';
import { Button, Form, Input, Modal, message } from 'antd';
import { FC, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';

import { requiredRule } from '../../lib/1/string-map';
import { FirebaseManager } from '../../lib/2/firebase-manager';
import { kakaoTalkUrl } from 'src/lib/1/constant';
import { MobileAlert } from 'src/lib/1/util';
import { CommerceLogger } from 'src/lib/4/logger';

import useAuth from 'src/redux/hooks/useAuth';
import { selectIsLoading, selectUser } from 'src/redux/slices/auth';
import { useAppSelector } from 'src/redux/store';

import ChatIcon from 'src/assets/icons/chat.svg';

import TopNavigationBar from '../Common/TopNavigationBar/TopNavigationBar';
import classes from './FindPassword.module.scss';

const logger = CommerceLogger.getInstance();
const openKakaoChat = () => window.open(kakaoTalkUrl, '_blank');

const { Item } = Form;

type FindPasswordFormValues = {
  email: string;
  password: string;
};

const firebaseManager = FirebaseManager.getInstance();

const getFindPasswordError = (error: any) => {
  switch (error.code) {
    case 'auth/invalid-email':
      return '이메일 형식이 올바르지 않습니다.';
    case 'auth/user-not-found':
      return '존재하지 않는 이메일입니다.';
    default:
      return '알 수 없는 에러가 발생했습니다.\n카카오톡 채널(이웃삼촌)으로 문의 바랍니다.';
  }
};

const FindPassword: FC = () => {
  const { onSetAuthLoading } = useAuth();
  const user = useAppSelector(selectUser, (prev, next) => prev?._id === next?._id);
  const isLoading = useAppSelector(selectIsLoading);
  const navigate = useNavigate();

  const onFinish = useCallback(
    async (values: FindPasswordFormValues) => {
      const { email } = values;

      try {
        onSetAuthLoading(true);
        await firebaseManager.sendPasswordResetEmail(email);
        onSetAuthLoading(false);
        Modal.confirm({
          title: '이메일이 발송되었습니다.',
          content: (
            <p>
              비밀번호 재설정후 로그인해주세요.
              <br />※ 미수신시 스팸메일함을 확인해주세요.
            </p>
          ),
          onOk: () => {
            navigate('/signin');
          },
          okText: '확인',
          okCancel: false,
        });
      } catch (error) {
        const errorMsg = getFindPasswordError(error) ?? '알 수 없는 에러 발생!';
        logger.logCommerce(`비밀번호 찾기에서 에러발생 - email: ${email}, error: ${errorMsg}`, {
          level: 'error',
        });
        MobileAlert(
          errorMsg,
          <p>
            수신이 불가능한 이메일로 가입하신 경우
            <br />
            <span className='openKakao' onClick={openKakaoChat}>
              <img src={ChatIcon} alt='이웃삼촌 카카오톡 링크' />
              카카오톡 채널(이웃삼촌)
            </span>
            으로 문의 바랍니다.
          </p>
        );
        onSetAuthLoading(false);
      }
    },
    [navigate, onSetAuthLoading]
  );

  const onFinishFailed = useCallback((errorInfo: any) => {
    message.error(errorInfo ?? '알 수 없는 에러발생');
  }, []);

  return (
    <>
      <TopNavigationBar title='비밀번호 찾기' leftButton={true} />
      <div className={classes.findPasswordContainer}>
        {!user ? (
          <Form id='findPassword' onFinish={onFinish} onFinishFailed={onFinishFailed} size='large'>
            <Item
              name='email'
              label='이메일'
              rules={[...requiredRule, { type: 'email', message: '이메일 형식이 올바르지 않습니다.' }]}>
              <Input />
            </Item>

            <Item>
              <Button
                type='primary'
                icon={<SendOutlined />}
                className={classes.submitSigninButton}
                htmlType='submit'
                block
                loading={isLoading}>
                비밀번호 재설정 이메일 발송
              </Button>
            </Item>
          </Form>
        ) : (
          <div className={classes.loadingBox}>
            <LoadingOutlined />
          </div>
        )}
      </div>
    </>
  );
};

export default FindPassword;
