import {
  DeliveryTaskInfo,
  Order,
  OrderStatusCode,
  PickupTaskInfo,
  StoreAddressBase,
  StoreDoc,
  StoreState,
  UserDoc,
  storeStateList,
} from '@gooduncles/gu-app-schema';
import { message } from 'antd';
import { FC, useCallback, useEffect, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { GaEventType } from 'src/schema/schema-ga-event';

import { kakaoTalkUrl, safeBottomForFooterButton } from 'src/lib/1/constant';
import { MobileAlert, formatOrderDate, numberFormat } from 'src/lib/1/util';
import { getCommerceConf, sendGaEvent } from 'src/lib/3/firebase-short-cut';
import { CommerceLogger } from 'src/lib/4/logger';
import { checkIsFirstOrder, getDeliveryFee } from 'src/lib/4/order-util';

import useCart from 'src/redux/hooks/useCart';
import useOrder from 'src/redux/hooks/useOrder';
import { CartState } from 'src/redux/models/cartModel';
import { selectStore, selectUser } from 'src/redux/slices/auth';
import { selectCart } from 'src/redux/slices/cart';
import { selectOnEditOrder, selectSubmiited } from 'src/redux/slices/order';
import { useAppSelector } from 'src/redux/store';

import useOrders from 'src/hooks/useOrder';

import { useApp } from 'src/stores/app-context';

import ChatIcon from 'src/assets/icons/chat.svg';

import FooterButton from '../Common/FooterButton';
import classes from './OrderButtons.module.scss';

const logger = CommerceLogger.getInstance();

const openKakaoChat = () => window.open(kakaoTalkUrl, '_blank');

const createOrderData = (user: UserDoc, store: StoreDoc, cart: CartState, deliveryFee: number): Order => {
  const orderDate = formatOrderDate();

  const storeAddress: StoreAddressBase = {
    address: store.address ?? '',
    roadAddress: store.roadAddress ?? '',
    jibunAddress: store.jibunAddress ?? '',
    sido: store.sido ?? '',
    sigungu: store.sigungu ?? '',
    bname: store.bname ?? '',
    hname: store.hname ?? '',
    roadname: store.roadname ?? '',
  };

  const invoiceDetails = {
    settledAt: null,
    invoiceId: null,
  };

  const deliveryDetails: DeliveryTaskInfo = {
    deliveryPartnerId: null,
    deliveryPartnerName: null,
    deliverySpotId: null,
    deliveryTaskFinishedAt: null,

    // 아래는 물류앱 이후에 제거될 예정
    deliveredAt: null,
    courierId: null,
    courierName: null,
  };

  const pickupDetails: PickupTaskInfo = {
    pickupPartnerId: null,
    pickupPartnerName: null,
    pickupTaskFinishedAt: null,
  };
  const grandTotal = cart.totalAmount + deliveryFee;

  return {
    date: null,
    orderDate,
    products: cart.products,
    orderStatus: OrderStatusCode.SUBMITTED,
    totalAmount: cart.totalAmount,
    totalAmountDiff: null,
    paidAmount: grandTotal,
    grandTotal,
    userId: user._id,
    storeId: user.storeId,
    deliveryFee,
    storeAddress,
    ...invoiceDetails,
    ...deliveryDetails,
    ...pickupDetails,
  };
};

type OrderButtonsProps = {
  goToCart?: boolean;
  stickToBottom?: boolean;
};

const PromotiveMsg: FC<{ text: string }> = ({ text }) => {
  return (
    <div className={classes.promotiveMsg}>
      <span>{text}</span>
    </div>
  );
};

const OrderButtons: FC<OrderButtonsProps> = ({ goToCart, stickToBottom }) => {
  const navigate = useNavigate();
  const { onResetCart } = useCart();
  const user = useAppSelector(selectUser);
  const store = useAppSelector(selectStore);
  const onEditOrder = useAppSelector(selectOnEditOrder);
  const submittedOrder = useAppSelector(selectSubmiited);
  const { onSetOnEditOrder } = useOrder();
  const { showLoading, setShowLoading, commerceConf } = useApp();
  const { closed, open } = commerceConf;
  const msgClosed = useMemo(
    () => (closed.isClosed ? closed.msgClosed : open ? undefined : '주문 가능한 시간이 아닙니다.'),
    [closed, open]
  );

  const cart = useAppSelector(selectCart);
  // 배송비 무료 적용까지 남은 금액
  const leftUntilFreeDelivery = useMemo(
    () => (store?.chargeDeliveryFee ? commerceConf.minAmountForFreeDelivery - cart.totalAmount : 0),
    [cart.totalAmount, commerceConf.minAmountForFreeDelivery, store?.chargeDeliveryFee]
  );
  const deliveryFee = useMemo(
    () => getDeliveryFee(store, commerceConf, cart.totalAmount),
    [cart.totalAmount, commerceConf, store]
  );
  // FIXME: need refactor
  const { mutateCreateOrder, mutateCancelOrder, mutateUpdateOrder, mutateOrderError } = useOrders();

  /**
   * 현재 주문 내역이 없고, 장바구니에 상품이 있다면 주문 버튼을 보여준다.
   */
  const showOrderButton = useMemo(
    () => submittedOrder === null && cart.products.length > 0,
    [cart.products.length, submittedOrder]
  );
  const showUpdateOrderButton = useMemo(() => onEditOrder, [onEditOrder]);

  const createOrder = useCallback(async () => {
    setShowLoading(true);
    try {
      if (user === null) {
        logger.logCommerce('불가능한 액션 - 사용자 정보가 없습니다.', {
          level: 'error',
        });
        MobileAlert('불가능한 액션', '사용자 정보가 없습니다.');
        setShowLoading(false);
        return;
      }

      if (store?.state !== 'open') {
        if (store?.state === 'break') {
          MobileAlert(
            '주문 접수 중지된 매장입니다.',
            '주문 접수를 원하시는 경우\n카카오톡 채널(이웃삼촌)으로 문의 바랍니다.'
          );
        } else if (store?.state === 'closed') {
          MobileAlert(
            '운영 중지된 매장입니다.',
            '운영 중지 해제를 원하시는 경우\n카카오톡 채널(이웃삼촌)으로 문의 바랍니다.'
          );
        } else {
          MobileAlert(
            '승인 대기중인 매장입니다.',
            <p>
              빠른 처리를 원하시는 경우
              <br />
              <span className='openKakao' onClick={openKakaoChat}>
                <img src={ChatIcon} alt='이웃삼촌 카카오톡 링크' />
                카카오톡 채널(이웃삼촌)
              </span>
              으로 문의 바랍니다.
            </p>
          );
        }
        const stateKr = store?.state ? storeStateList[store?.state as StoreState] : '???';
        logger.logCommerce(
          `권한이 없는 사용자 액션\n📢 주문하기 버튼 클릭 📢\nstate: ${stateKr} email: ${user.email}, store: ${store?.storeNickname}`,
          {
            channel: '4-앱-알림',
          }
        );
        setShowLoading(false);
        return;
      }

      const currentCommerceConf = await getCommerceConf(true);
      if (currentCommerceConf === undefined || !currentCommerceConf.open) {
        logger.logCommerce(`주문시간 외 주문 - email: ${user.email}, store: ${store?.storeNickname}`, {
          channel: '4-앱-알림',
        });
        MobileAlert('주문 가능한 시간이 아닙니다.');
        setShowLoading(false);
        return;
      }

      // 1. 주문을 수정하는 경우
      if (onEditOrder) {
        if (submittedOrder === null) {
          logger.logCommerce('불가능한 액션 - 더 이상 존재하지 않는 주문입니다.', {
            level: 'error',
          });
          message.warning('불가능한 액션 - 더 이상 존재하지 않는 주문입니다.');
          setShowLoading(false);
          return;
        }

        // 1-1. 주문을 취소하는 경우
        if (cart.products.length === 0) {
          onResetCart('장바구니 비우기 & 주문 취소');
          await mutateCancelOrder(submittedOrder._id, true);
          // mutate에서 로그 수집
          setShowLoading(false);
          return;
        }

        const updateOrder = createOrderData(user, store, cart, deliveryFee);
        await mutateUpdateOrder(submittedOrder._id, updateOrder);
        onResetCart('주문 수정 완료');
        onSetOnEditOrder(false);
        sendGaEvent({
          name: GaEventType.ORDER_EDIT_SUBMIT,
          eventParams: {
            orderId: submittedOrder._id,
          },
        });
        logger.logCommerce(`주문수정 - 업소명: ${store.storeNickname}, 주문금액: ${updateOrder.totalAmount}`, {
          extraInfo: {
            orderId: submittedOrder?._id,
          },
        });
        message.success('수정완료!');
        setShowLoading(false);
        if (goToCart) {
          navigate('/shopping-cart');
        }
        return;
      }

      if (cart.products.length === 0) {
        logger.logCommerce('불가능한 액션 - 상품이 없습니다.', {
          level: 'error',
        });
        MobileAlert('불가능한 액션 - 상품이 없습니다.');
        setShowLoading(false);
        return;
      }

      // 2. 주문을 생성하는 경우
      checkIsFirstOrder(store);
      const order = createOrderData(user, store, cart, deliveryFee);
      const orderId = await mutateCreateOrder(order);
      onResetCart('주문 완료');
      onSetOnEditOrder(false);
      sendGaEvent({
        name: GaEventType.ORDER_SUBMIT,
        eventParams: {
          ...order,
          orderId: orderId ?? 'error',
        },
      });
      logger.logCommerce(`주문완료 - 업소명: ${store.storeNickname}, 주문금액: ${order.paidAmount}`, {
        extraInfo: {
          orderId,
        },
      });
      message.success('주문완료!');

      setShowLoading(false);
      if (goToCart) {
        navigate('/shopping-cart');
      }
    } catch (error: any) {
      message.error(error.message ?? '주문 변경 실패');
      setShowLoading(false);
    }
  }, [
    cart,
    goToCart,
    mutateCancelOrder,
    mutateCreateOrder,
    mutateUpdateOrder,
    navigate,
    onEditOrder,
    onResetCart,
    onSetOnEditOrder,
    submittedOrder,
    setShowLoading,
    store,
    user,
    deliveryFee,
  ]);

  const cancelEditOrder = useCallback(() => {
    onSetOnEditOrder(false);
    onResetCart('주문 수정 취소');
    sendGaEvent({
      name: GaEventType.ORDER_EDIT_CANCEL,
      eventParams: {
        orderId: submittedOrder?._id ?? null,
      },
    });
  }, [onResetCart, onSetOnEditOrder, submittedOrder?._id]);

  useEffect(() => {
    if (mutateOrderError) {
      message.error(mutateOrderError);
    }
  }, [mutateOrderError]);

  return (
    <>
      {showOrderButton && (
        <FooterButton
          bottom={stickToBottom ? safeBottomForFooterButton : undefined}
          text={msgClosed ? msgClosed : `${numberFormat(cart.totalAmount + deliveryFee)}원 주문확정하기`}
          onClick={() => createOrder()}
          disabled={!!msgClosed || showLoading}
          child={
            leftUntilFreeDelivery > 0 && (
              <PromotiveMsg text={`${numberFormat(leftUntilFreeDelivery)}원 더 담으면 무료배송`} />
            )
          }
        />
      )}
      {showUpdateOrderButton && (
        <div
          className={classes.updateOrderButtons}
          style={stickToBottom ? { bottom: safeBottomForFooterButton } : undefined}>
          <button className={classes.cancel} onClick={cancelEditOrder}>
            수정취소
          </button>
          <button className={classes.updateOrder} onClick={() => createOrder()} disabled={!!msgClosed || showLoading}>
            {msgClosed
              ? msgClosed
              : cart.totalAmount
              ? `${numberFormat(cart.totalAmount + deliveryFee)}원 수정완료`
              : '주문취소'}
            {!msgClosed && cart.totalAmount > 0 && leftUntilFreeDelivery > 0 && (
              <PromotiveMsg text={`${numberFormat(leftUntilFreeDelivery)}원 더 담으면 무료배송`} />
            )}
          </button>
        </div>
      )}
    </>
  );
};

export default OrderButtons;
