import {
  CustomPromotionSectionDoc,
  PromotionSection,
  PromotionSectionDoc,
  StoreCategory,
} from '@gooduncles/gu-app-schema';
import { useCallback } from 'react';

import { deleteTimestamp } from 'src/lib/3/firebase-short-cut';
import { ProductGroupForCategorySubsetDoc } from 'src/lib/3/schema-product-group-for-category-subset';

import {
  setCustomPromotionSections,
  setIsMenuOpen,
  setProductGroupForCategories,
  setPromotionSections,
  setSelectedStoreCategory,
} from '../slices/home';
import { useAppDispatch } from '../store';

const useHomeConf = () => {
  const dispatch = useAppDispatch();

  const onSetSelectedStoreCategory = useCallback(
    (selectedStoreCategory: StoreCategory) => {
      dispatch(setSelectedStoreCategory(selectedStoreCategory));
    },
    [dispatch]
  );

  const onSetProductGroupForCategories = useCallback(
    (category: string, subset: ProductGroupForCategorySubsetDoc) => {
      // Redux state에는 _timeCreate, _timeUpdate를 저장하지 않는다.
      deleteTimestamp(subset);
      dispatch(setProductGroupForCategories({ category, subset }));
    },
    [dispatch]
  );

  const onSetIsMenuOpen = useCallback(
    (isMenuOpen: boolean) => {
      dispatch(setIsMenuOpen(isMenuOpen));
    },
    [dispatch]
  );

  const onSetPromotionSections = useCallback(
    (promotionSections0: PromotionSectionDoc[]) => {
      // Redux state에는 _timeCreate, _timeUpdate를 저장하지 않는다.
      const promotionSections: PromotionSection[] = promotionSections0.map((promotionSection) =>
        deleteTimestamp(promotionSection)
      );
      dispatch(setPromotionSections(promotionSections));
    },
    [dispatch]
  );

  const onSetCustomPromotionSections = useCallback(
    (customPromotionSections0: CustomPromotionSectionDoc[]) => {
      const customPromotionSections: CustomPromotionSectionDoc[] = customPromotionSections0.map((section) =>
        deleteTimestamp(section)
      );
      dispatch(setCustomPromotionSections(customPromotionSections));
    },
    [dispatch]
  );

  return {
    onSetSelectedStoreCategory,
    onSetProductGroupForCategories,
    onSetIsMenuOpen,
    onSetPromotionSections,
    onSetCustomPromotionSections,
  };
};

export default useHomeConf;
