import { FC } from 'react';
import { useNavigate } from 'react-router-dom';

import { productCategoriesWithEmoji } from 'src/lib/1/string-map';
import { insert } from 'src/lib/1/util';

import { selectStore } from 'src/redux/slices/auth';
import { useAppSelector } from 'src/redux/store';

import useModal from 'src/hooks/useModal';
import useProductCountForAllCategories from 'src/hooks/useProductCountForCategory';

import IconArrowRightOrange from 'src/assets/icons/arrow-right-orange.svg';

import GoToPageButton from 'src/components/atoms/Button/GoToPageButton';
import TextButton from 'src/components/atoms/Button/TextButton';
import Modal from 'src/components/atoms/Modal/Modal';
import ToolBar from 'src/components/molecules/ToolBar/ToolBar';

const ProductCategoryListModal: FC = () => {
  const navigate = useNavigate();
  const { isOpen, openModal } = useModal('product-category-list');
  const { productCountRecord } = useProductCountForAllCategories();

  const store = useAppSelector(selectStore);
  const productCategoriesWithCustom =
    store?.unhiddenProducts && store?.unhiddenProducts.length > 0
      ? insert(Object.entries(productCategoriesWithEmoji), 1, ['custom', '⭐️ 맞춤상품'])
      : Object.entries(productCategoriesWithEmoji);

  const goToProductsForCategoryPage = (category: string) => {
    navigate(`/category/${category}`, { replace: true });
  };

  return (
    <>
      <TextButton label='전체상품' color='orange' onClick={openModal}>
        <img src={IconArrowRightOrange} alt='arrow right' />
      </TextButton>
      <Modal isOpen={isOpen}>
        <ToolBar title='전체 카테고리' backButton='right' />
        <div>
          {productCategoriesWithCustom
            .filter(([key]) => key !== 'all')
            .map(([key, title]) => (
              <GoToPageButton key={key} label={title} size='large' onClick={() => goToProductsForCategoryPage(key)}>
                {productCountRecord?.[key] && <span className='sub-text'>({productCountRecord[key]})</span>}
              </GoToPageButton>
            ))}
        </div>
      </Modal>
    </>
  );
};

export default ProductCategoryListModal;
