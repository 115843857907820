import { Dropdown, Radio } from 'antd-mobile';
import { FC, useState } from 'react';

import { DropdownRef } from 'antd-mobile/es/components/dropdown';

import classes from './DropdownBox.module.scss';

interface DropdownBoxProps {
  values: string[];
  currentValue: string;
  handleValue: (value: string) => void;
}

const DropdownBox: FC<DropdownBoxProps> = ({ values, currentValue, handleValue }) => {
  const [dropdownRef, setDropdownRef] = useState<DropdownRef | null>(null);

  return (
    <Dropdown ref={setDropdownRef} className={classes.dropdown} closeOnClickAway>
      <Dropdown.Item key='sorter' title={currentValue}>
        <Radio.Group
          defaultValue={currentValue ?? values[0]}
          onChange={(value) => {
            handleValue(String(value));
            dropdownRef?.close();
          }}>
          <div className={classes.radioList}>
            {values.map((value) => (
              <Radio key={value} value={value}>
                {value}
              </Radio>
            ))}
          </div>
        </Radio.Group>
      </Dropdown.Item>
    </Dropdown>
  );
};

export default DropdownBox;
