import { StoreAddressBase, StoreBase } from '@gooduncles/gu-app-schema';
import versionJson from 'src/version.json';

export const isProduction = process.env.REACT_APP_PROJECT_ID === 'gooduncles-10f09';

export const version = isProduction ? versionJson.prod : versionJson.dev;

export const logArt = `개발자 여러분, 오늘도 고생이 많으십니다.
🥙🥘🍚🍖🥩🍕🍣🍝🥟🍜🍔🍙🍲
`;
export const firebaseConfig = {
  apiKey: process.env.REACT_APP_API_KEY,
  authDomain: process.env.REACT_APP_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_PROJECT_ID,
  storageBucket: process.env.REACT_APP_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_ID,
  measurementId: process.env.REACT_APP_MEASUREMENT_ID === 'null' ? undefined : process.env.REACT_APP_MEASUREMENT_ID,
};

/**
 * 기타 상품코드
 */
export const EtcProductCode = 'P10000';

export const defaultPageSize = 25;
export const enum LocalStorageKey {
  sessionId = 'sessionId',
  deviceInfo = 'deviceInfo',
  cart = 'cart',
  selectedStoreCategory = 'selectedStoreCategory',
  latestSearchKeywords = 'latestSearchKeywords',
}

export const domainUrlRegex = /^[a-zA-Z]{3,5}:\/{2}[a-zA-Z0-9_.:-]+\//;

export const commerceIssueCategoryKr: Record<string, string> = {
  refund: '환불',
  order: '주문',
  discount: '할인',
  delivery: '추가배송',
  storage: '보관',
  pickup: '회수',
  supply: '매입처매입',
  return: '매입처반품',
  etc: '기타',
};

export const kakaoTalkUrl = 'https://pf.kakao.com/_LZZLK/chat';

export const DEFAULT_STORE_NAME = '미등록';

export const STORE_CATEGORY_EMOJI_MAPPING = {
  salad_cafe: '🥙',
  zzim_tang_jjigae: '🥘',
  // 제거 예정
  baekban_guksu: '🍚',
  // 제거 예정
  jokbal: '🍖',
  // 제거 예정
  barbeque: '🥩',
  korean: '🍚',
  barbeque_jokbal: '🍖',
  chicken_pizza: '🍕',
  japanese: '🍣',
  western: '🍝',
  chinese: '🥟',
  asian: '🍜',
  fastfood: '🍔',
  snack: '🍙',
  all: '🍲',
  etc: '🍲',
};

export const FIREBASE_AUTH_ERROR_CODE: Record<string, string> = {
  unknown: '알 수 없는 오류가 발생하였습니다.',
  // sign in
  'auth/user-not-found': '존재하지 않는 이메일입니다.',
  'auth/user-disabled': '사용중지된 계정입니다.',
  'auth/wrong-password': '잘못된 비밀번호입니다.',
  'auth/too-many-requests':
    '연속된 실패로 인해 이용이 제한되었습니다.\n잠시 후 다시 시도하시거나 비밀번호 찾기를 이용해 주세요.',
  // sign up
  'auth/email-already-exists': '이미 사용중인 이메일입니다',
  'auth/email-already-in-use': '이미 사용중인 이메일입니다.',
  'auth/invalid-email': '잘못된 이메일 형식입니다.',
  'auth/weak-password': '비밀번호는 6자 이상이어야 합니다.',
};

/**
 * 관리자 개입이 필요한 에러코드
 */
export const FIREBASE_AUTH_ERROR_CODE_FOR_ALERT = ['auth/too-many-requests', 'auth/user-disabled'];

/**
 * Algolia 관련 설정
 * ['state:stock', 'state:out-of-stock']: 판매중지된 상품을 제외한다.
 * hidden:false: 숨김 상품을 제외한다.
 */
export const facetFilters = [['state:stock', 'state:out-of-stock'], ['hidden:false']];

export const safeBottomForFooterButton = 'env(safe-area-inset-bottom)';

/**
 * 불필요한 검색 쿼리를 막기위한 한글 자모 및 특수문자 목록
 */
export const hangulJamoAndSpecialChar = [
  'ㄱ',
  'ㄴ',
  'ㄷ',
  'ㄹ',
  'ㅁ',
  'ㅂ',
  'ㅅ',
  'ㅇ',
  'ㅈ',
  'ㅊ',
  'ㅋ',
  'ㅌ',
  'ㅍ',
  'ㅎ',
  'ㄲ',
  'ㄸ',
  'ㅃ',
  'ㅆ',
  'ㅉ',
  'ㄳ',
  'ㄵ',
  'ㄶ',
  'ㄺ',
  'ㄻ',
  'ㄼ',
  'ㄽ',
  'ㄾ',
  'ㄿ',
  'ㅀ',
  'ㅄ',
  'ㅏ',
  'ㅑ',
  'ㅓ',
  'ㅕ',
  'ㅗ',
  'ㅛ',
  'ㅜ',
  'ㅠ',
  'ㅡ',
  'ㅣ',
  'ㅐ',
  'ㅒ',
  'ㅔ',
  'ㅖ',
  'ㅘ',
  'ㅙ',
  'ㅚ',
  'ㅝ',
  'ㅞ',
  'ㅟ',
  'ㅢ',
  '!',
  '@',
  '#',
  '$',
  '%',
  '^',
  '&',
  '=',
  '~',
];

/**
 * 자주 묻는 질문 Notion URL
 */
export const notionFaqUrl = 'https://post.gooduncles.com/febb547b-ec96-4f94-8f7e-947c826fde2f';

/**
 * 매장정보 초기화
 */
export const storeBaseInit: Omit<
  StoreBase,
  'storeName' | 'storeNickname' | 'categories' | 'addressDetail' | keyof StoreAddressBase
> = {
  storeCode: null,
  storeEmail: null,
  storeManagerId: null,
  state: 'guest',
  deliverySpotId: 'unregistered',
  paymentMethod: '미정',
  paymentVendor: '기타',
  latestSettledAt: null,
  accessInfo: null,
  deliveryNote: null,
};
