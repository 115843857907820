import { UserOutlined } from '@ant-design/icons';
import { LoadingOutlined, StarFilled } from '@ant-design/icons';
import { Modal, message } from 'antd';
import { CSSProperties, FC, useCallback, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { addFavoriteProduct, removeFavoriteProduct } from 'src/lib/3/firebase-short-cut';

import { selectIsProductFavorite, selectUserId } from 'src/redux/slices/auth';
import { useAppSelector } from 'src/redux/store';

const { confirm } = Modal;

const style: CSSProperties = {
  zIndex: 10,
  margin: 0,
  padding: 4,
  border: 'none',
  verticalAlign: 'middle',
};

type FavoriteButtonProps = {
  productId: string;
  size?: 'medium' | 'large';
};

const FavoriteButton: FC<FavoriteButtonProps> = ({ productId, size = 'medium' }) => {
  const navigate = useNavigate();
  const userId = useAppSelector(selectUserId);
  const isFavorite = useAppSelector((state) => selectIsProductFavorite(state, productId));
  const [isLoading, setIsLoading] = useState(false);

  const toggleFavorite = useCallback(async () => {
    if (!userId) {
      confirm({
        title: '로그인이 필요한 서비스입니다.',
        icon: <UserOutlined />,
        okText: '로그인',
        cancelText: '취소',
        centered: true,
        onOk: () => {
          navigate('/signin');
        },
      });
      return;
    }
    setIsLoading(true);
    try {
      if (isFavorite) {
        await removeFavoriteProduct(userId, productId);
      } else {
        await addFavoriteProduct(userId, productId);
      }
    } catch (error) {
      console.error(error);
      message.error('오류가 발생했습니다. 다시 시도해주세요.');
    }
    setIsLoading(false);
  }, [isFavorite, navigate, productId, userId]);

  return isLoading ? (
    <LoadingOutlined
      style={{
        ...style,
        padding: size === 'large' ? 11 : '4px 9px 9px',
        fontSize: size === 'large' ? 26 : 20,
        color: 'var(--orange500)',
      }}
    />
  ) : (
    <StarFilled
      style={{
        ...style,
        padding: size === 'large' ? 11 : '4px 9px 9px',
        fontSize: size === 'large' ? 26 : 20,
        color: isFavorite ? 'var(--orange500)' : 'var(--gray400)',
      }}
      onClick={toggleFavorite}
    />
  );
};

export default FavoriteButton;
