import { FC } from 'react';
import { Navigate, useParams } from 'react-router-dom';

import ProductDetail from 'src/components/ProductDetail/ProductDetail';

const ProductDetailPage: FC = () => {
  const { productId } = useParams();
  return productId ? <ProductDetail productId={productId} /> : <Navigate to='/' replace />;
};

export default ProductDetailPage;
