import { FC, useCallback, useEffect, useState } from 'react';

import { getInstallmentBillsForStore } from 'src/lib/3/firebase-short-cut';
import { InstallmentBillDoc } from 'src/lib/3/schema-bill';

import { selectStore } from 'src/redux/slices/auth';
import { useAppSelector } from 'src/redux/store';

import TopNavigationBar from '../Common/TopNavigationBar/TopNavigationBar';
import Loading from '../Layout/Loading';
import classes from './BillHistory.module.scss';

const BillHistory: FC = () => {
  const [loading, setLoading] = useState(false);
  const [bills, setBills] = useState<InstallmentBillDoc[] | null>(null);
  const store = useAppSelector(selectStore);

  const getBills = useCallback(async () => {
    if (!store?.storeCode) {
      return;
    }
    setLoading(true);
    try {
      const bills = await getInstallmentBillsForStore(store?.storeCode);
      bills.filter((bill) => bill.isDeleted !== true);
      setBills(bills);
    } catch (error: any) {
      console.error(error);
    }
    setLoading(false);
  }, [store?.storeCode]);

  useEffect(() => {
    getBills();
  }, [getBills]);

  return (
    <div className={classes.billHistoryContainer}>
      <TopNavigationBar title='정산 목록' leftButton />
      {store?.storeCode ? (
        <>
          {loading ? (
            <Loading />
          ) : (
            <div>
              {bills?.map((bill) => (
                <div key={bill._id}>{bill._id}</div>
              ))}
            </div>
          )}
        </>
      ) : (
        <div>등록된 가게가 없습니다.</div>
      )}
    </div>
  );
};

export default BillHistory;
