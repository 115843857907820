import { FC, useEffect, useRef } from 'react';

import FlexLoadingBox from 'src/components/atoms/FlexLoadingBox/FlexLoadingBox';
import EmptyProductSearch from 'src/components/molecules/EmptyProductSearch/EmptyProductSearch';
import GridProductListWithFetch from 'src/components/organisms/ProductList/GridProductList/GridProductListWithFetch';

interface InfiniteProductListProps {
  isLoading: boolean;
  isValidating: boolean;
  productIds: string[];
  totalPages: number;
  size: number;
  setSize: (size: number) => void;
}

const InfiniteProductList: FC<InfiniteProductListProps> = ({
  isLoading,
  isValidating,
  productIds,
  totalPages,
  size,
  setSize,
}) => {
  const sentinelRef = useRef<HTMLDivElement>(null);
  const isReachingEnd = size >= totalPages;

  useEffect(() => {
    if (sentinelRef.current) {
      const observer = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting && !isReachingEnd && !isValidating) {
          setSize(size + 1);
        }
      });
      observer.observe(sentinelRef.current);

      return () => {
        observer.disconnect();
      };
    }
  }, [isReachingEnd, isValidating, setSize, size]);

  return (
    <>
      {productIds.length > 0 ? (
        <GridProductListWithFetch productIds={productIds} />
      ) : (
        !isLoading && <EmptyProductSearch />
      )}
      {isValidating && (
        <div style={{ height: 140, paddingBottom: 80 }}>
          <FlexLoadingBox />
        </div>
      )}
      {productIds.length > 0 && <div ref={sentinelRef} style={{ height: 80 }} />}
    </>
  );
};

export default InfiniteProductList;
