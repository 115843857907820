import { DeleteOutlined, EditOutlined } from '@ant-design/icons';
import { ProductDoc, ProductStateCode } from '@gooduncles/gu-app-schema';
import { Button, InputNumber, Modal } from 'antd';
import { FC, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { GaEventType } from 'src/schema/schema-ga-event';

import { editOrderErrors } from 'src/lib/1/string-map';
import { sendGaEvent } from 'src/lib/3/firebase-short-cut';

import useCart from 'src/redux/hooks/useCart';
import useOrder from 'src/redux/hooks/useOrder';
import { selectUserId } from 'src/redux/slices/auth';
import { selectSubmiited } from 'src/redux/slices/order';
import { useAppSelector } from 'src/redux/store';

import IconMinus from 'src/assets/icons/minus.svg';
import IconPlus from 'src/assets/icons/plus.svg';

import classes from './ProductVolumeController.module.scss';

interface ProductVolumeControllerProps {
  product: ProductDoc;
  volume: number;
  /**
   * onEditOrder === null : 주문이 없음
   * - 장바구니의 수량을 보여준다
   * - 장바구니 수정 가능
   *
   * onEditOrder === true : 주문이 있으면서 수정 모드
   * - 장바구니의 수량을 보여준다.
   * - 장바구니 수정 가능
   *
   * onEditOrder === false : 주문이 있으며 수정 모드가 아님
   * - 주문의 수량을 보여준다.
   * - 수정모드로 변경 필요
   */
  isCartItem: boolean;
}

const showControlModal = (
  productId: string,
  volume: number,
  onChange: (productId: string, inputVolume: number) => void
) => {
  const prevVolume = volume;
  Modal.confirm({
    icon: <EditOutlined />,
    title: '수량변경',
    content: (
      <InputNumber
        controls={true}
        color='#FF5E1A'
        defaultValue={volume}
        type='number'
        min={1}
        max={99}
        size='large'
        onChange={(e) => onChange(productId, e ?? 1)}
        style={{ width: '100%', textAlign: 'center', maxWidth: '100%' }}
      />
    ),
    okText: '변경',
    cancelText: '취소',
    okButtonProps: { size: 'large', style: { minWidth: 80, backgroundColor: 'var(--orange700)', fontWeight: 600 } },
    cancelButtonProps: { size: 'large', style: { minWidth: 80, fontWeight: 600 } },
    onCancel: () => onChange(productId, prevVolume),
  });
};

const confirmUpdateCart =
  (natigate: () => void, onOk: () => void) => (signedIn: boolean, editable: boolean, action: () => void) => {
    if (!signedIn) {
      Modal.confirm({
        title: '로그인 후 이용해 주세요.',
        onOk: natigate,
        okText: '로그인',
        cancelText: '취소',
      });
      return;
    }

    if (!editable) return action();
    Modal.confirm({
      title: '이미 완료한 주문이 있습니다.',
      content: editOrderErrors.onEdit,
      onOk,
      style: { whiteSpace: 'pre-line' },
      okText: '수정 모드로 변경',
      cancelText: '취소',
    });
  };

const ProductVolumeController: FC<ProductVolumeControllerProps> = ({ product, volume, isCartItem }) => {
  const navigate = useNavigate();
  const userId = useAppSelector(selectUserId);
  const submittedOrder = useAppSelector(selectSubmiited);
  const { onSetOnEditOrder } = useOrder();
  const { onAddToCart, onSubFromCart, onRemoveFromCart, onSetProductVolume, onSetProducts } = useCart();
  const handleUpdateCart = useMemo(
    () =>
      confirmUpdateCart(
        () => navigate('/signin'),
        () => {
          onSetOnEditOrder(true);
          onSetProducts(submittedOrder?.products ?? []);
          sendGaEvent({
            name: GaEventType.ORDER_EDIT,
            eventParams: {
              orderId: submittedOrder?._id ?? null,
            },
          });
        }
      ),
    [navigate, onSetOnEditOrder, onSetProducts, submittedOrder?._id, submittedOrder?.products]
  );

  return (
    <>
      {product.state === ProductStateCode.STOCK ? (
        <div className={`${classes.volumeControler} ${volume > 0 ? classes.added : ''}`}>
          <button onClick={() => handleUpdateCart(!!userId, isCartItem, () => onAddToCart(product, 1))}>
            <img src={IconPlus} alt='plus' />
          </button>
          <span
            className={classes.volume}
            onClick={() =>
              handleUpdateCart(!!userId, isCartItem, () =>
                showControlModal(product.productId, volume, onSetProductVolume)
              )
            }>
            {volume}
          </span>
          <button
            className={classes.minus}
            disabled={volume < 1}
            onClick={() => handleUpdateCart(!!userId, isCartItem, () => onSubFromCart(product.productId, 1))}>
            <img src={IconMinus} alt='minus' />
          </button>
        </div>
      ) : (
        volume > 0 && (
          <div className={classes.volumeControler} style={{ borderColor: 'var(--red600)' }}>
            <Button
              icon={<DeleteOutlined />}
              type='primary'
              danger
              onClick={() => handleUpdateCart(!!userId, isCartItem, () => onRemoveFromCart(product.productId))}
            />
          </div>
        )
      )}
    </>
  );
};

export default ProductVolumeController;
