import { FC, PropsWithChildren } from 'react';

import classes from './EmptyList.module.scss';

type EmptyListProps = {
  emoji?: string;
  message?: string;
  isError?: boolean;
};

const EmptyList: FC<PropsWithChildren<EmptyListProps>> = ({
  emoji = '🛒',
  message = '결과가 없습니다.',
  isError,
  children,
}) => {
  return (
    <div className={classes.emptyList}>
      {<h1>{isError ? '⚠️' : emoji}</h1>}
      <h3>{isError ? '인터넷 연결이 불안정하여\n상품을 불러오는데 실패했습니다.' : message}</h3>
      {isError && <button onClick={() => location.reload()}>새로고침</button>}
      {!isError && children}
    </div>
  );
};

export default EmptyList;
