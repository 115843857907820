import { OrderDoc } from '@gooduncles/gu-app-schema';
import { format } from 'date-fns';
import { ko } from 'date-fns/locale';
import { FC } from 'react';

import { formatNumber } from 'src/lib/1/util';

import classes from './OrderInfo.module.scss';

const OrderInfo: FC<{ order: OrderDoc; paidAmount: number; minAmountForFreeDelivery?: number }> = ({
  order,
  paidAmount,
  minAmountForFreeDelivery = 0,
}) => {
  const deliveryFee = order.deliveryFee ?? 0;
  const leftUntilFreeDelivery = deliveryFee > 0 ? minAmountForFreeDelivery - order.totalAmount : 0;
  return (
    <>
      <section className={classes.orderInfo}>
        <span>주문금액</span>
        <h2>{formatNumber(paidAmount)}원</h2>
        {/* https://date-fns.org/v2.28.0/docs/format */}
        <span>주문시각 {format(new Date(order.orderDate), 'L월 d일(EEEEEE) HH:mm:ss', { locale: ko })}</span>
      </section>
      {deliveryFee > 0 && (
        <div className={classes.deliveryFee}>
          {leftUntilFreeDelivery > 0 ? (
            <span className={classes.promotiveMsg}>※ {formatNumber(leftUntilFreeDelivery)}원 더 담으면 무료배송</span>
          ) : null}
          <div className={classes.content}>
            <span className={classes.label}>배송비</span>
            <span>{formatNumber(deliveryFee)}원</span>
          </div>
        </div>
      )}
    </>
  );
};

export default OrderInfo;
