import useSWRImmutable from 'swr/immutable';

import { getProductDoc } from 'src/lib/3/firebase-short-cut';

/**
 * 상품 정보를 가져옵니다.
 * 1시간 마다 갱신합니다.
 * @returns
 */
const useImmutableProductById = (id: string) => {
  const {
    data,
    isValidating: productLoading,
    error: productError,
  } = useSWRImmutable(id ? id : null, getProductDoc, {
    revalidateOnMount: true,
    // 5분
    dedupingInterval: 5 * 60 * 1000,
  });

  return {
    product: data,
    productLoading,
    productError,
  };
};

export default useImmutableProductById;
