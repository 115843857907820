import { datadogRum } from '@datadog/browser-rum';
import { OrderStatusCode } from '@gooduncles/gu-app-schema';
import { useEffect, useState } from 'react';
import { EMPTY, Subscription, catchError, map } from 'rxjs';
import { validateStore, validateUser } from 'src/validators/validateAuth';

import { isProduction, logArt, version } from 'src/lib/1/constant';
import { MobileAlert, errorObjectToString, getBrowserInfo, sessionId } from 'src/lib/1/util';
import {
  observeCustomPromotionSectionFor,
  observeFriebaseAuth,
  observeStoreDoc,
  observeUserDoc,
  orderSubscriptionForStore,
  setUserForGa,
  setUserPropertiesForGa,
  updateUser,
} from 'src/lib/3/firebase-short-cut';
import { CommerceLogger } from 'src/lib/4/logger';

import useAuth from 'src/redux/hooks/useAuth';
import useHomeConf from 'src/redux/hooks/useHomeConf';
import useOrder from 'src/redux/hooks/useOrder';
import { UserExceptFavoriteProducts } from 'src/redux/models/authModel';
import { selectStoreId, selectStoreNickname, selectUser } from 'src/redux/slices/auth';
import { useAppSelector } from 'src/redux/store';

const logger = CommerceLogger.getInstance();

let userSubscription: Subscription;
let storeSubscription: Subscription;
let orderSubscription: Subscription;
let customPromotionSectionSubscription: Subscription;

const handleAuthError = (subscription: Subscription | undefined, logout: () => void) => (error: any) => {
  console.error(error);
  const errorMessage = errorObjectToString(error);
  logger.logCommerce(`사용자 인증 에러: ${errorMessage}`, {
    level: 'error',
  });
  logout();
  subscription?.unsubscribe();
  MobileAlert(errorMessage, null, {
    text: '새로고침',
    onClick: () => window.location.reload(),
  });
  return EMPTY;
};

const useAuthentication = () => {
  const [userForInit, setUserForInit] = useState<UserExceptFavoriteProducts | null>();
  const user = useAppSelector(selectUser);
  const storeId = useAppSelector(selectStoreId);
  const storeNickname = useAppSelector(selectStoreNickname);
  const { onSetAuthLoading, onSetUser, onSetStore, onSetFavoriteProducts, onLogout } = useAuth();
  const { onSetOrders, onSetOnEditOrder } = useOrder();
  const { onSetCustomPromotionSections } = useHomeConf();

  useEffect(() => {
    const authSubscription = observeFriebaseAuth().subscribe(async (firebaseAuth) => {
      const { uid, email } = firebaseAuth ?? {};

      if (uid && email) {
        await updateUser(uid, {
          email,
          ip: logger.getPublicIp(),
          sessionId: sessionId(),
          userAgent: getBrowserInfo(),
          version,
        });
        userSubscription = observeUserDoc(uid)
          .pipe(catchError(handleAuthError(userSubscription, onLogout)))
          .subscribe((user0) => {
            try {
              validateUser(user0);
            } catch (error) {
              const errorMessage = errorObjectToString(error);
              MobileAlert(errorMessage, null, {
                text: '확인',
                onClick: () => {
                  onLogout();
                  window.location.reload();
                },
              });
              return;
            }
            const { favoriteProducts, ...user } = user0;
            onSetUser(user);
            onSetFavoriteProducts(favoriteProducts ?? []);
          });
      } else {
        userSubscription?.unsubscribe();
        storeSubscription?.unsubscribe();
        orderSubscription?.unsubscribe();
        customPromotionSectionSubscription?.unsubscribe();
        // 비회원의 최초 로딩이 해제되는 시점.
        onLogout();
      }
    });
    return () => {
      authSubscription.unsubscribe();
      userSubscription?.unsubscribe();
    };
  }, [onLogout, onSetFavoriteProducts, onSetUser]);

  useEffect(() => {
    if (storeId) {
      storeSubscription = observeStoreDoc(storeId)
        .pipe(catchError(handleAuthError(storeSubscription, onLogout)))
        .subscribe((store) => {
          validateStore(store);
          onSetStore(store);
        });

      orderSubscription = orderSubscriptionForStore(storeId)
        .pipe(catchError(handleAuthError(orderSubscription, onLogout)))
        .subscribe((orderGroup) => {
          onSetOrders(orderGroup);
          // 11시가 지나서 수정가능하지 못하게 되는 경우 강제로 수정모드를 해제한다.
          if (orderGroup[OrderStatusCode.SUBMITTED] === undefined) {
            onSetOnEditOrder(false);
          }
          onSetAuthLoading(false);
        });

      customPromotionSectionSubscription = observeCustomPromotionSectionFor(storeId)
        .pipe(map((docs) => docs.filter((doc) => doc.isDeleted !== true && doc.isActive === true)))
        .subscribe(onSetCustomPromotionSections);

      return () => {
        storeSubscription?.unsubscribe();
        orderSubscription?.unsubscribe();
        customPromotionSectionSubscription?.unsubscribe();
      };
    }
  }, [onLogout, onSetAuthLoading, onSetCustomPromotionSections, onSetOnEditOrder, onSetOrders, onSetStore, storeId]);

  // 최초 로그인 여부 판단
  useEffect(() => {
    if (user?._id !== userForInit?._id) {
      setUserForInit(user);
    } else if (!user) {
      setUserForInit(null);
    }
  }, [user, userForInit?._id]);

  // 최초 로그인에 따른 로그
  useEffect(() => {
    if (userForInit === undefined) {
      console.log(`%c\n${logArt}`, isProduction ? 'color:orange' : 'color:black');
    }
  }, [userForInit]);

  useEffect(() => {
    // 외부 api[datadog, ga]에 사용자 정보를 업데이트합니다.
    if (userForInit && storeNickname) {
      // GA에 사용자 정보를 업데이트합니다.
      setUserForGa(userForInit._id);
      setUserPropertiesForGa(userForInit);
      datadogRum.setUser({
        userId: userForInit._id,
        storeName: storeNickname,
        email: userForInit.email,
        deviceId: userForInit.deviceTokenId,
        localSessionId: sessionId(),
      });
    } else {
      datadogRum.setUser({
        userId: undefined,
        storeName: storeNickname,
        email: undefined,
        deviceId: undefined,
        localSessionId: sessionId(),
      });
    }
  }, [userForInit, storeNickname]);
};

export default useAuthentication;
