import { FC } from 'react';

/**
 * react-router-dom v6부터 exact은 deprecate 되었다.
 * 대신 하위에 path='*'를 명시적으로 주는 것을 추천하고있다.
 */
import { Navigate, Route, Routes } from 'react-router-dom';

import { selectIsLoading, selectUser } from './redux/slices/auth';
import { useAppSelector } from './redux/store';

import usePageViews from './hooks/usePageViews';
import useWebViewMessage from './hooks/useWebViewMessage';

import BillHistoryPage from './pages/BillHistoryPage';
import BillPage from './pages/BillPage';
import DeleteAccountPage from './pages/DeleteAccountPage';
import FaqPage from './pages/FaqPage';
import FavoritePage from './pages/FavoritePage';
import FindEmailPage from './pages/FindEmailPage';
import FindPasswordPage from './pages/FindPasswordPage';
import HomePage from './pages/HomePage';
import InvoicePage from './pages/InvoicePage';
import LatestOrderProductListPage from './pages/LatestOrderProductListPage';
import NotFoundPage from './pages/NotFoundPage';
import NotificationPage from './pages/NotificationPage';
import OrderDetailPage from './pages/OrderDetailPage';
import OrderHistoryPage from './pages/OrderHistoryPage';
import ProductDetailPage from './pages/ProductDetailPage';
import ProductsForCategoryPage from './pages/ProductsForCategoryPage';
import ProductsForCustomPromotionPage from './pages/ProductsForCustomPromotionPage';
import ProductsForPromotionPage from './pages/ProductsForPromotionPage';
import ProfilePage from './pages/ProfilePage';
import SearchPage from './pages/SearchPage';
import SearchResultPage from './pages/SearchResultPage';
import ShoppingCartPage from './pages/ShoppingCartPage';
import SigninPage from './pages/SigninPage';
import SignupPage from './pages/SignupPage';
import StoreCategoryPage from './pages/StoreCategoryPage';
import StoreIssueDetailPage from './pages/StoreIssueDetailPage';
import TagPage from './pages/TagPage';

import Layout from './components/Layout/Layout';

const Router: FC = () => {
  const user = useAppSelector(selectUser, (prev, next) => prev?.email === next?.email);
  const isLoggedIn = !!user;
  const isLoading = useAppSelector(selectIsLoading);
  usePageViews();
  useWebViewMessage();

  return (
    <>
      <Layout isLoggedIn={isLoggedIn} isLoading={isLoading}>
        <Routes>
          <Route path='/home' element={<HomePage />} />
          {isLoggedIn ? (
            <>
              <Route path='/favorite' element={<FavoritePage />} />
              <Route path='/shopping-cart' element={<ShoppingCartPage />} />
              <Route path='/order-history' element={<OrderHistoryPage />} />
              <Route path='/bill-history' element={<BillHistoryPage />} />
              {/* 우선은 로그인 전용 알림부터 시작한다. */}
              <Route path='/notification' element={<NotificationPage />} />
              <Route path='/issue-detail' element={<StoreIssueDetailPage />} />
              <Route path='/latest-order-product' element={<LatestOrderProductListPage />} />
            </>
          ) : (
            <>
              <Route path='/signin' element={<SigninPage />} />
              <Route path='/signup/*' element={<SignupPage />} />
              <Route path='/find-email' element={<FindEmailPage />} />
              <Route path='/find-password' element={<FindPasswordPage />} />
            </>
          )}
          <Route path='/profile' element={isLoggedIn ? <ProfilePage /> : <Navigate to='/login' replace />} />
          <Route path='/tag/:tag' element={<TagPage />} />
          <Route path='/search/result' element={<SearchResultPage />} />
          <Route path='/search' element={<SearchPage />} />
          <Route path='/product/:productId' element={<ProductDetailPage />} />
          <Route path='/store-category/:storeCategory' element={<StoreCategoryPage />} />
          <Route path='/category/:category' element={<ProductsForCategoryPage />} />
          <Route path='/promotion/:promotionId' element={<ProductsForPromotionPage />} />
          <Route path='/custom-promotion/:promotionId' element={<ProductsForCustomPromotionPage />} />
          <Route path='/order-detail/:orderId' element={<OrderDetailPage />} />
          <Route path='/bill/:receiver' element={<BillPage />} />
          <Route path='/invoice/:invoiceId' element={<InvoicePage />} />
          <Route path='/delete-account' element={<DeleteAccountPage />} />
          <Route path='/faq' element={<FaqPage />} />
          <Route path='/not-found' element={<NotFoundPage />} />
          <Route path='*' element={<Navigate to='/home' replace />} />
        </Routes>
      </Layout>
    </>
  );
};

export default Router;
