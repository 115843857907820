import { useEffect } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';

const useModal = (modalName: 'store-category-list' | 'product-category-list') => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const isOpen = searchParams.get('modal') === modalName;

  const openModal = () => {
    navigate({
      pathname: '.',
      search: `?modal=${modalName}`,
    });
  };

  useEffect(() => {
    if (isOpen) {
      const htmlElement = document.getElementById('main-div') as HTMLElement;
      const currentScroll = htmlElement.scrollTop;
      htmlElement.classList.add('no-scroll');
      htmlElement.style.top = `-${currentScroll}px`;
    } else {
      const htmlElement = document.getElementById('main-div') as HTMLElement;
      const scrollY = htmlElement.style.top;
      htmlElement.classList.remove('no-scroll');
      htmlElement.style.top = 'auto';
      htmlElement.scrollTo({
        top: parseInt(scrollY || '0') * -1,
        behavior: 'instant' as any,
      });
    }

    return () => {
      const htmlElement = document.getElementById('main-div') as HTMLElement;
      htmlElement.classList.remove('no-scroll');
    };
  }, [isOpen]);

  return {
    isOpen,
    openModal,
  };
};

export default useModal;
