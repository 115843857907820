import { FC } from 'react';

import { useTitle } from '../hooks/useTitle';

import FindEmail from 'src/components/FindEmail/FindEmail';

const FindEmailPage: FC = () => {
  useTitle('GU | 이메일 찾기');
  return <FindEmail />;
};

export default FindEmailPage;
