import { LoadingOutlined, StarFilled } from '@ant-design/icons';
import { CSSProperties, FC } from 'react';

const style: CSSProperties = {
  fontSize: 22,
  color: 'var(--orange600)',
};

const buttonStyle: CSSProperties = {
  display: 'flex',
  zIndex: 10,
  alignItems: 'center',
  justifyContent: 'center',
};

interface FavoriteButtonProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  isFavorite: boolean;
  isLoading: boolean;
  size?: number;
}

const FavoriteButton: FC<FavoriteButtonProps> = ({ isFavorite, isLoading, size = 36, ...props }) => {
  return (
    <button style={{ ...buttonStyle, height: size, width: size }} {...props}>
      {isLoading ? (
        <LoadingOutlined style={style} />
      ) : (
        <StarFilled style={{ ...style, color: isFavorite ? 'var(--orange600)' : 'var(--gray-alpha-40)' }} />
      )}
    </button>
  );
};

export default FavoriteButton;
