import { NativeMessageKeys, WebViewMessageFromNative } from '@gooduncles/gu-app-schema';
import { useCallback, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { LocalStorageKey } from 'src/lib/1/constant';
import { getLocalStorageValue, jsonParseWithCatch, setLocalStorageValue } from 'src/lib/1/util';
import { FirebaseManager } from 'src/lib/2/firebase-manager';
import { updateUser } from 'src/lib/3/firebase-short-cut';
import { Device } from 'src/lib/3/schema-app';
import { CommerceLogger } from 'src/lib/4/logger';
import { WebViewMessageHandler } from 'src/lib/5/webveiw-message-handler';

import { selectUser } from 'src/redux/slices/auth';
import { useAppSelector } from 'src/redux/store';

const webViewMessageHandler = WebViewMessageHandler.getInstance();
const logger = CommerceLogger.getInstance();
const firebaseManager = FirebaseManager.getInstance();
const devicePath = 'device';

/**
 * App(WebView)에서 전달받는 메시지를 처리한다.
 */
const useWebViewMessage = () => {
  const navigate = useNavigate();
  const user = useAppSelector(selectUser, (prev, next) => prev?._id === next?._id);
  const userId = user?._id;
  const [deviceInfo, setDeviceInfo] = useState<Device | null>(null);

  const WebViewMessageListner = useCallback(
    async (event: any) => {
      const message = event.data ? jsonParseWithCatch<WebViewMessageFromNative<NativeMessageKeys>>(event.data) : null;
      if (!message) {
        return;
      }

      // FIXME: 타입과 로직을 변경했으나 아직 앱에는 반영하지 못하여 과도기에만 사용한다.
      if ((message.name as any) === 'pushData') {
        const data = message.body as WebViewMessageFromNative<any>['body'];
        if (data.actionType === 'navigate' && data.actionValue) {
          navigate(data.actionValue);
        }
        // notification.info({
        //   message: data.title,
        //   description: data.body ?? undefined,
        // });
        return;
      }

      switch (message.name) {
        // WebView과의 연결 확인
        case 'ack':
          logger.logCommerce('WebView 통신 성공');
          break;

        // WebView최초 로드 시점에 기기 정보를 받는다.
        case 'device': {
          const device = message.body as WebViewMessageFromNative<'device'>['body'];
          try {
            await firebaseManager.setDoc(devicePath, device.tokenId, device, { bMerge: true });
            setDeviceInfo(device);
            setLocalStorageValue(LocalStorageKey.deviceInfo, device);
          } catch (error: any) {
            logger.logCommerce(JSON.stringify(error), {
              level: 'error',
            });
          }
          break;
        }

        // 기존에 저장된 기기 정보가 있는 경우에만 'permission'을 업데이트한다.
        case 'permission': {
          try {
            const deviceInfo = getLocalStorageValue<Device>(LocalStorageKey.deviceInfo);
            if (deviceInfo) {
              const { permission = 'UNKNOWN' } = (message.body as WebViewMessageFromNative<'permission'>['body']) ?? {};
              await firebaseManager.updateDoc(devicePath, deviceInfo.tokenId, {
                permission,
              });
              const newDeviceInfo = { ...deviceInfo, permission };
              setDeviceInfo(newDeviceInfo);
              setLocalStorageValue(LocalStorageKey.deviceInfo, newDeviceInfo);
            }
          } catch (error: any) {
            logger.logCommerce(JSON.stringify(error), {
              level: 'error',
            });
          }
          break;
        }

        case 'pushPayload': {
          const data = message.body as WebViewMessageFromNative<'pushPayload'>['body'];
          if (data.actionType === 'navigate' && data.actionValue) {
            navigate(data.actionValue);
          }
          break;
        }
      }
    },
    [navigate]
  );

  useEffect(() => {
    if (window.ReactNativeWebView) {
      const userAgent = navigator.userAgent;
      webViewMessageHandler.setWebViewRef(window.ReactNativeWebView);

      /** android */
      if (/android/i.test(userAgent)) {
        document.addEventListener('message', WebViewMessageListner);
      } else if (/iPhone|iPad|iPod/i.test(userAgent)) {
        window.addEventListener('message', WebViewMessageListner);
      } else {
        // 모르면 둘 다 등록
        document.addEventListener('message', WebViewMessageListner);
        window.addEventListener('message', WebViewMessageListner);
      }
      // 연결 확인
      webViewMessageHandler.syncWithWebview();

      return () => {
        document.removeEventListener('message', WebViewMessageListner);
        window.removeEventListener('message', WebViewMessageListner);
      };
    }
  }, [WebViewMessageListner]);

  /**
   * App의 상태를 사용자 정보에 업데이트한다.
   */
  useEffect(() => {
    if (userId && deviceInfo) {
      updateUser(userId, { deviceTokenId: deviceInfo.tokenId, notificationId: deviceInfo.token });
      if (!deviceInfo.userId) {
        firebaseManager.updateDoc(devicePath, deviceInfo.tokenId, { userId });
      }
    }
    // mutateUser의 경우 user의 정보를 따라 갱신되기 때문에 dependency에서 제외시킨다.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [deviceInfo, userId]);
};

export default useWebViewMessage;
