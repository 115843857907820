import { FC } from 'react';

import Signup from '../components/Signup/Signup';
import { useTitle } from '../hooks/useTitle';

const SignupPage: FC = () => {
  useTitle('GU | 회원가입');
  return <Signup />
};

export default SignupPage;
