import { HomeOutlined, MenuOutlined, ProfileOutlined, ShoppingCartOutlined, StarOutlined } from '@ant-design/icons';
import { Badge } from 'antd';
import { FC, useMemo } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import useHomeConf from 'src/redux/hooks/useHomeConf';
import { selectUserId } from 'src/redux/slices/auth';
import { selectIsMenuOpen } from 'src/redux/slices/home';
import { selectSubmiited } from 'src/redux/slices/order';
import { useAppSelector } from 'src/redux/store';

import useNavigateWithGuard from '../../hooks/useNavigateWithGuard';
import useNotificationForUserId from 'src/hooks/useNotificationForUserId';

import classes from './MainNavigation.module.scss';

const MainNavigation: FC = () => {
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const { navigateWithGuard } = useNavigateWithGuard();
  const { onSetIsMenuOpen } = useHomeConf();
  const sumiitedOrder = useAppSelector(selectSubmiited);
  const isMenuOpen = useAppSelector(selectIsMenuOpen);
  const userId = useAppSelector(selectUserId);
  const { notificationList } = useNotificationForUserId(userId);

  const siteMap = useMemo(
    () => ({
      home: {
        title: '홈',
        action: () => navigate('/home'),
        icon: <HomeOutlined />,
      },
      favorite: {
        title: '즐겨찾기',
        action: () => navigateWithGuard('/favorite'),
        icon: <StarOutlined />,
      },
      'shopping-cart': {
        title: sumiitedOrder ? '주문목록' : '장바구니',
        action: () => navigateWithGuard('/shopping-cart'),
        icon: sumiitedOrder ? (
          <Badge count={sumiitedOrder.products?.length ?? 0}>
            <ProfileOutlined />
          </Badge>
        ) : (
          <ShoppingCartOutlined />
        ),
      },
      menu: {
        title: '메뉴',
        action: () => onSetIsMenuOpen(!isMenuOpen),
        icon: (
          <Badge count={notificationList?.unreadCount}>
            <MenuOutlined />
          </Badge>
        ),
      },
    }),
    [sumiitedOrder, notificationList?.unreadCount, navigate, navigateWithGuard, onSetIsMenuOpen, isMenuOpen]
  );

  return (
    <>
      <footer className={classes.footer}>
        {Object.entries(siteMap).map(([key, { title, action, icon }]) => (
          <nav
            key={key}
            className={`${classes.menuItem} ${pathname === `/${key}` ? classes.selected : ''}`}
            onClick={action}>
            {icon}
            <span className={classes.menuItemTitle}>{title}</span>
          </nav>
        ))}
      </footer>
    </>
  );
};

export default MainNavigation;
