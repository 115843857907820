import { FC } from 'react';

import CheckboxChecked from 'src/assets/icons/checkbox-checked.svg';

import classes from './Checkbox.module.scss';

interface CheckboxProps extends React.InputHTMLAttributes<HTMLInputElement> {
  label: string;
}

const Checkbox: FC<CheckboxProps> = ({ label, checked, ...props }) => {
  return (
    <label className={`${classes.checkbox} ${checked ? classes.checked : ''}`}>
      {label}
      <input {...props} type='checkbox' checked={checked} />
      {checked ? <img src={CheckboxChecked} alt='checked' /> : <span className={classes.checkmark} />}
    </label>
  );
};

export default Checkbox;
