import { ProductDoc, ProductStateCode } from '@gooduncles/gu-app-schema';
import { FC } from 'react';

import FavoriteProduct from 'src/components/molecules/FavoriteProduct/FavoriteProduct';
import ProductInfo from 'src/components/molecules/ProductInfo/ProductInfo';
import ProductThumbnail from 'src/components/molecules/ProductThumbnail/ProductThumbnail';

import classes from './ProductSquareItem.module.scss';
import ProductVolumeController from './ProductVolumeController/ProductVolumeController';

interface ProductSquareItemProps {
  product: ProductDoc & { promotion?: string };
  /** 목록에서 스크롤 되는 방향 */
  horizontal?: boolean;
  /** 메인화면처럼 상품군별 순위 정보를 보여주는 경우 기본 뱃지와 중복적인 내용이 노출될 수 있으므로, 선택할 수 있도록 한다. */
  showBadge?: boolean;
}

const ProductSquareItem: FC<ProductSquareItemProps> = ({ product, horizontal, showBadge }) => {
  if (product.state === ProductStateCode.DISCONTINUED) return null;
  return (
    <div className={`${classes.productSquareItem} ${horizontal ? classes.horizontal : ''}`}>
      <ProductThumbnail
        product={product}
        overlayTop={<FavoriteProduct product={product} />}
        overlayBottom={<ProductVolumeController product={product} />}
      />
      <ProductInfo product={product} showBadge={showBadge} />
    </div>
  );
};

export default ProductSquareItem;
